import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'
import { TOrderResponse } from '@/libs/types'
import { TBuyTransaction } from '@/libs/types/buy-transaction'

import { TSimulationBody, TSimulationResponse } from './types'

const BASE_URL = 'api/v1/order'

const makeManualBuy = (payload: TBuyTransaction) => {
  const userId = getCurrentUserId()
  return api.post(
    `${BASE_URL}/base/${userId}/manual/buy?l=en&b=${payload.network.blockchain}`,
    payload,
  )
}

const makeApprove = (body: any) => api.post(`${BASE_URL}/approval`, body)

const makeSell = (payload: any) => {
  const userId = getCurrentUserId()
  return api.post(
    `${BASE_URL}/base/${userId}/manual/sell?l=en&b=${payload.network.blockchain}`,
    payload,
  )
}

const getSimulation = (body: TSimulationBody) =>
  api.post<TSimulationResponse>(`${BASE_URL}/simulation-extra`, body)

const getOrdersHistory = () =>
  api.get<TOrderResponse>(`api/v1/order/history/${getCurrentUserId()}/get?b=1`)

const disperse = (body: any) => {
  const userId = getCurrentUserId()
  return api.post(`${BASE_URL}/base/${userId}/disperse?b=${body.blockchain}`, body)
}

const collect = (body: any) => {
  const userId = getCurrentUserId()
  return api.post(`${BASE_URL}/base/${userId}/collect?b=${body.blockchain}`, body)
}

export { makeManualBuy, makeApprove, makeSell, getSimulation, getOrdersHistory, disperse, collect }
