import { FC, useState } from 'react'

import MUITable, { TableProps as MUITableProps } from '@mui/material/Table'
import MUITableBody from '@mui/material/TableBody'
import MUITableCell from '@mui/material/TableCell'
import MUITableHead from '@mui/material/TableHead'
import MUITableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { Spinner } from '@/components/spinner'
import { SpinnerSize } from '@/libs/enums'
import { TableBodyRow, TableCell } from '@/libs/types/table.type'

import { BodyRow } from './libs/components/body-row/body-row'
import styles from './styles.module.scss'

// each index of [rows] === index of [rowsCollapsible]
// if you want row 0 has linked to it additional collapsed rows
// add them to rowsCollapsible 0 index of array
type TTableProps = MUITableProps & {
  header: TableCell[]
  rows: TableBodyRow[]
  rowsCollapsible?: TableBodyRow[][]
  isCollapsible?: boolean
  closeOthersOnExpand?: boolean
  isLoading?: boolean
}

type TOpenState = { key: string; open: boolean }

const Table: FC<TTableProps> = ({
  className,
  header,
  rows,
  rowsCollapsible = [],
  isCollapsible,
  closeOthersOnExpand = false,
  isLoading,
  ...props
}) => {
  const headerCells = isCollapsible ? ['\u00A0', ...header] : header
  const [openState, setOpenState] = useState<TOpenState>({
    key: '',
    open: false,
  })

  const getRowKey = (index: number) => 'table-body-row' + index

  return (
    <MUITable className={cn(styles.table, className)} {...props}>
      <MUITableHead>
        <MUITableRow className={styles.headerRow}>
          {headerCells.map((cellItem, index) => (
            <MUITableCell className={styles.headerCell} key={'table-header-cell' + index}>
              {cellItem}
            </MUITableCell>
          ))}
        </MUITableRow>
      </MUITableHead>
      <MUITableBody className={styles.body}>
        {isLoading && <Spinner size={SpinnerSize.MEDIUM} absoluteCentered />}

        {!isLoading &&
          rows.map((row, index) => (
            <BodyRow
              key={getRowKey(index)}
              rowKey={getRowKey(index)}
              row={row}
              rowsCollapsed={rowsCollapsible[index]}
              isCollapsible={isCollapsible}
              open={
                closeOthersOnExpand
                  ? openState.key === getRowKey(index) && openState.open
                  : undefined
              }
              setOpen={setOpenState}
            />
          ))}
      </MUITableBody>
    </MUITable>
  )
}

export { Table, type TTableProps, type TOpenState }
