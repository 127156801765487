export const hideWalletAddress = (input: string | null, numVisibleChars = 4) => {
  if (input) {
    const length = input.length

    if (length <= numVisibleChars) {
      return input
    }

    const startVisible = input.slice(0, numVisibleChars)
    const endVisible = input.slice(-numVisibleChars)

    return `${startVisible}....${endVisible}`
  }
  return input
}
