const validateRequiredFields = (fields: any[]) => {
  let newDisabled = false
  for (const field of fields) {
    if ((Array.isArray(field) && !field.length) || !field) {
      newDisabled = true
      break
    }
  }
  return newDisabled
}

export { validateRequiredFields }
