const Sorting = {
  ASC: 'asc',
  DESC: 'desc',
}

// TODO: Refactor to use this enum
enum ESorting {
  ASC = 'asc',
  DESC = 'desc',
}

export { ESorting, Sorting }
