import { ValueOf } from 'viem/_types/types/utils'

import { Sorting } from '@/libs/enums'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'

export const sortHoldings = (holdings: THoldingsToken[], sorting: ValueOf<typeof Sorting>) => {
  return [...holdings].sort((a, b) => {
    const firstAmount = +a.tvu || 0
    const secondAmount = +b.tvu || 0
    return sorting === Sorting.ASC ? firstAmount - secondAmount : secondAmount - firstAmount
  })
}
