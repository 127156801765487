import { FC } from 'react'

import cn from 'classnames'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = {
  title: string
  value: any
  isGreen?: boolean
}

const SettingField: FC<TProps> = ({ title, value }) => {
  const OnOffValue = value ? 'ON' : 'OFF'
  const currentValue = typeof value === 'boolean' ? OnOffValue : value

  return (
    <div className={styles.container}>
      <Typography variant="body2" textColor="color-grey-2">
        {title}:
      </Typography>

      <Typography
        variant="body2"
        className={cn(
          styles.value,
          currentValue === 'ON' && styles.colorGreen,
          currentValue === 'OFF' && styles.colorRed,
        )}
      >
        {!Array.isArray(currentValue)
          ? currentValue
          : currentValue.map((item) => (
              <>
                {item}
                <br />
              </>
            ))}
      </Typography>
    </div>
  )
}

export { SettingField }
