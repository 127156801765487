import { Link, useNavigate } from 'react-router-dom'

import { AuthCard } from '@/components/auth-card'
import { Button } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { AppRoute, VerificationStatus } from '@/libs/enums'

import styles from './styles.module.scss'

const ImportWalletFile = () => {
  const navigate = useNavigate()

  const handleImportFile = () => {
    navigate(AppRoute.MASTER_PASSWORD_ADVANTAGES)
  }
  return (
    <AuthCard
      className={styles.section}
      verificationStatus={VerificationStatus.SETUP_WALLETS}
      isHeaderButtons
      isBackButton
    >
      <section>
        <Typography variant="h1" align="center" className={styles.title}>
          Import wallet file
        </Typography>

        <Typography variant="body1" className={styles.explanationWrapper}>
          If you have wallets installed on your PC, find the keystore file and upload it here to
          import your existing wallet.
        </Typography>

        <div className={styles.dropBlock}>
          <Typography className={styles.dropDescription}>
            Drag and drop file here
            <span className={styles.span}>or</span>
            <Link to="/" className={styles.link}>
              Browser files
            </Link>
          </Typography>
        </div>

        <Button onClick={handleImportFile}>Continue</Button>
      </section>
    </AuthCard>
  )
}

export { ImportWalletFile }
