const drawGradientText = (text: any, x: number, y: number, context: CanvasRenderingContext2D) => {
  const gradient = context.createLinearGradient(0, y - 34, 0, y)

  if (text > 0) {
    gradient.addColorStop(0, '#3DAF41')
    gradient.addColorStop(0.3, '#3DAF41')
    gradient.addColorStop(1, '#85E889')
  } else {
    gradient.addColorStop(0, '#ff5757')
    gradient.addColorStop(0.3, '#ff5757')
    gradient.addColorStop(1, '#ff5757')
  }

  context.fillStyle = gradient
  context.fillText(text + '%', x, y)
}

export { drawGradientText }
