import { FC } from 'react'

import { WalletHoldingsTable } from '@/components/wallet-holdings-table'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { BackButton, Typography } from '@/libs/common'

import styles from './styles.module.scss'

const HiddenHoldings: FC = () => {
  const wallet = useWalletFromParams()

  return (
    <div className={styles.container}>
      <BackButton isAbsolute />
      <div>
        <Typography variant="h1" align="center">
          Hidden Holdings
        </Typography>
        <Typography variant="body2" align="center">
          Wallet112113312
        </Typography>
      </div>
      <Typography variant="body1" className={styles.title}>
        Hidden Holdings
      </Typography>
      {wallet && <WalletHoldingsTable isHidden wallet={wallet} />}
    </div>
  )
}

export { HiddenHoldings }
