import { Bar } from 'public/charting_library/charting_library'

import { getStartTimeInterval } from './getStartTimeInterval'

export function getClosestBarByInterval(
  seriesData: Bar[],
  targetTimestamp: number,
  timeframe: string,
) {
  const targetStart = getStartTimeInterval(targetTimestamp, timeframe)

  // let closestBar = null
  let minDiff = Infinity
  let closestBar = null
  for (const bar of seriesData) {
    const barStart = getStartTimeInterval(bar.time, timeframe)

    if (barStart == targetStart) {
      return bar
    } else if (Math.abs(barStart - targetStart) < minDiff) {
      minDiff = Math.abs(barStart - targetStart)
      closestBar = bar
    }
  }

  return closestBar
}
