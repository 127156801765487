import { ComponentProps, FC } from 'react'

import cn from 'classnames'

import styles from './styles.module.scss'

type TProperty = {
  children?: React.ReactNode
  index: number
  value: number
} & ComponentProps<'div'>

const TabContent: FC<TProperty> = ({ children, value, index, className = '', ...props }) => {
  const isActive = value === index
  return (
    <div
      className={cn(styles.container, {
        [styles.active]: isActive,
        [className]: isActive,
      })}
      {...props}
    >
      {isActive && children}
    </div>
  )
}

export { TabContent }
