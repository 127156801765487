function removeTrailingZeros(value: string): string {
  let result = value
  while (result.endsWith('0') && result !== '0') {
    result = result.slice(0, -1)
  }

  if (result === '') {
    return '0'
  }

  if (result.endsWith('.')) {
    result = result.slice(0, -1)
  }

  return result
}

export { removeTrailingZeros }
