import { FC } from 'react'

import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material'
import cn from 'classnames'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = TextareaAutosizeProps & {
  value: string
  maxWallets?: number
  onChange: (value: string) => void
}

const ImportPrivateKeysComponent: FC<TProps> = ({ className, maxWallets, ...props }) => {
  return (
    <div>
      <Typography variant="h1" align="center" className={styles.header}>
        Import wallets
      </Typography>

      <Typography variant="body1" className={styles.typographyColor}>
        Enter wallets here in the following format{!!maxWallets && ` (max ${maxWallets})`}:
      </Typography>

      <Typography variant="body1" className={styles.walletFormatWrapper}>
        Private_key
        <br />
        Private_key
        <br />
        ...
      </Typography>

      <TextareaAutosize
        minRows={5.8}
        className={cn(styles.textarea, className)}
        {...props}
      ></TextareaAutosize>
    </div>
  )
}

export { ImportPrivateKeysComponent }
