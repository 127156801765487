import { FC } from 'react'

import Grid, { GridProps } from '@mui/material/Grid'
import cn from 'classnames'

import { Icon } from '@/libs/common/icon'
import { Typography } from '@/libs/common/typography'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import styles from './styles.module.scss'

type TRadioButtonContentProps = GridProps & {
  title?: string
  description?: string
  iconName?: ValueOf<typeof IconName>
}

const RadioButtonContent: FC<TRadioButtonContentProps> = ({
  title,
  className,
  description,
  iconName,
  ...props
}) => {
  return (
    <Grid container {...props} gap={1} wrap="nowrap" className={cn(styles.container, className)}>
      {iconName && (
        <Grid container className={styles.icon}>
          <Icon name={iconName} />
        </Grid>
      )}
      <Grid container direction="column" justifyContent="space-between">
        <Typography
          variant="body2"
          textColor="light-grey"
          resetLineHeight
          className={cn({ [styles.textWithIcon]: iconName })}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            variant="body2"
            resetLineHeight
            className={cn({ [styles.textWithIcon]: iconName })}
          >
            {description}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export { RadioButtonContent, type TRadioButtonContentProps }
