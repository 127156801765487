import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Divider } from '@mui/material'
import cls from 'classnames'

import styles from '@/components/dashboard-component-lite/styles.module.scss'
import { ButtonCopy, Icon, Typography } from '@/libs/common'
import { TokenDataItem } from '@/libs/common/token-data-item'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { TTokenInfo } from '@/libs/types/token-info-response.type'
import { useAppSelector } from '@/store'

type TProperty = {
  selectedToken: TTokenInfo | null
}

const TokenCard: FC<TProperty> = ({ selectedToken }) => {
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)

  const isDataAvailable = !!simulation

  return (
    <div className={cls(styles.block, styles.tokenCard)}>
      <div className={styles.header}>
        <div>
          <div className={styles.tokenName}>
            <Typography variant="body1" textColor="light-grey">
              {selectedToken?.token.name}
            </Typography>
            {selectedToken && (
              <ButtonCopy
                className={styles.buttonCopy}
                value={selectedToken.token.address}
                tooltipClassName={styles.copyButtonTooltip}
              />
            )}
          </div>
          <Typography>{simulation?.l.dn}</Typography>
        </div>

        <div className={styles.asideWrapper}>
          <div className={styles.aside}>
            <Link
              to={selectedToken?.socialLinks?.website || ''}
              target="_blank"
              className={cls(styles.socialLink, {
                [styles.disabledLink]: !selectedToken?.socialLinks?.website,
              })}
            >
              <Icon name={IconName.LANGUAGE} />
            </Link>

            <Link
              to={selectedToken?.socialLinks?.telegram || ''}
              target="_blank"
              className={cls(styles.socialLink, {
                [styles.disabledLink]: !selectedToken?.socialLinks?.telegram,
              })}
            >
              <Icon name={IconName.EXCLUDE} />
            </Link>

            <Link
              to={selectedToken?.socialLinks?.x || ''}
              target="_blank"
              className={cls(styles.socialLink, {
                [styles.disabledLink]: !selectedToken?.socialLinks?.x,
              })}
            >
              <Icon name={IconName.TWITTER} />
            </Link>
          </div>

          <Link
            to={`https://x.com/search?q=%24${selectedToken?.token?.name}`}
            className={styles.searchLink}
          >
            <Icon name={IconName.SEARCH} />
          </Link>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.row}>
          <TokenDataItem
            title="Price USD"
            value={
              <div className={styles.column}>
                ${simulation && formatTokenPrice(simulation.p.ou).formatted}
              </div>
            }
            isDataAvailable={isDataAvailable}
          />
          <Divider orientation="vertical" flexItem className={styles.divider} />
          <TokenDataItem
            title="Price WETH"
            value={
              <div className={styles.column}>
                {simulation && formatTokenPrice(simulation.p.op).formatted}
              </div>
            }
            isDataAvailable={isDataAvailable}
          />
          <Divider orientation="vertical" flexItem className={styles.divider} />
          <TokenDataItem
            title="Supply"
            className={styles.column}
            value={
              simulation && (
                <>
                  {formatNumber(simulation?.t.ts).formatted} {simulation.t.s}
                </>
              )
            }
            isDataAvailable={!!Number(simulation?.l.ts)}
          />
        </div>
        <div className={styles.row}>
          <TokenDataItem
            title="Liquidity"
            value={
              simulation && (
                <div className={styles.column}>
                  ${formatTokenPrice(simulation.l.p.vu).formatted}
                </div>
              )
            }
            isDataAvailable={isDataAvailable}
          />
          <Divider orientation="vertical" flexItem className={styles.divider} />
          <TokenDataItem
            title="FDV"
            value={
              simulation && `$${formatTokenPrice(`${+simulation.t.ts * +simulation.p.ou}`).raw}`
            }
            isDataAvailable={isDataAvailable}
          />
          <Divider orientation="vertical" flexItem className={styles.divider} />
          <TokenDataItem
            className={styles.column}
            title="MKT CAP"
            value={simulation && `$${formatTokenPrice(simulation.t.mc).raw}`}
            isGreen
            isDataAvailable={isDataAvailable}
          />
        </div>
      </div>
    </div>
  )
}

export { TokenCard }
