import { Link } from 'react-router-dom'
import { TypeOptions, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { getToastIcon, getToastStyles } from '@/libs/helper'

import styles from './styles.module.scss'

const CustomToast = (type: TypeOptions, message: string, link?: { to: string; text: string }) => {
  const tostStyles = getToastStyles(type)

  toast(
    <div className={styles.customToast}>
      <p className={styles.title}>{tostStyles.title}</p>
      <p className={styles.message}>
        {message}{' '}
        {link && (
          <Link to={`${link.to}`} className={styles.link}>
            {link.text}
          </Link>
        )}
      </p>
    </div>,
    {
      closeOnClick: false,
      draggable: false,
      type,
      progressStyle: { backgroundColor: tostStyles.color },
      icon: getToastIcon(type),
    },
  )
}

export { CustomToast }
