import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { BackButton, Button, Input, Typography } from '@/libs/common'
import { AppMode, AppRoute } from '@/libs/enums'

import styles from './styles.module.scss'

const Telegram = () => {
  const navigate = useNavigate()
  const defaultValues = {
    phoneNumber: '',
    code: '',
  }

  const [searchParams] = useSearchParams()
  const isPhone = searchParams.get('action') === 'phone'

  const handleOpenTelegramCodeModal = () => {
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.TELEGRAM}?action=code`,
    )
  }

  const handleOpenScrapeModal = () => {
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.ADD_NEW_SCRAPE_GROUP}`,
    )
  }

  const { handleSubmit, control } = useForm({ defaultValues })

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    if (isPhone) {
      handleOpenTelegramCodeModal()
    } else {
      handleOpenScrapeModal()
    }
    // Send data to backend
    return data
  }

  return (
    <div className={styles.wrapper}>
      {!isPhone && <BackButton className={styles.backButton} />}

      <Typography variant="h1" align="center" className={styles.title}>
        Link telegram
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.content}>
        {isPhone ? (
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input
                isTransparent
                label="Phone number"
                placeholder="Enter your phone number"
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            name="code"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input
                isTransparent
                label="Enter code received from telegram"
                placeholder="Enter code"
                {...field}
              />
            )}
          />
        )}

        <Button styleVariant="yellow" type="submit">
          Continue
        </Button>
      </form>
    </div>
  )
}

export { Telegram }
