const validatePercentInput = (newValue: string, oldValue: string) => {
  if (!newValue) return ''
  const res = newValue.replace(/[^0-9.]/g, '')

  if (+res >= 0 && +res <= 100) {
    return res
  }
  return oldValue
}

export { validatePercentInput }
