import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { getOrdersHistory } from '@/api/orders'
import { handleOrdersStatusesChanging } from '@/libs/helper'
import { handleError } from '@/libs/helper/handleError'
import { TNewOrdersHistory } from '@/libs/types/order.type'

type TInitialState = {
  ordersHistory: TNewOrdersHistory[] | null
  isLoading: boolean
  errorMessage: string | null
}

const initialState: TInitialState = {
  ordersHistory: null,
  isLoading: false,
  errorMessage: null,
}

const fetchOrdersHistory = createAsyncThunk('chain/fetchOrderHistory', async () => {
  const { data } = await getOrdersHistory()

  const updatedOrdersHistory = data.data
  try {
    const pendingOrders = data.data.filter((el) => !el.status)
    const updatedOrders = updatedOrdersHistory.filter(
      (updatedOrder) =>
        pendingOrders.some((pendingOrder) => pendingOrder._id === updatedOrder._id) &&
        updatedOrder.hash[0].status?.status,
    )

    handleOrdersStatusesChanging(updatedOrders)
  } catch (err) {
    handleError(err)
  }
  return updatedOrdersHistory
})

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrdersHistory.fulfilled, (state, { payload }) => {
      state.ordersHistory = payload
      state.isLoading = false
    })

    builder.addMatcher(isAnyOf(fetchOrdersHistory.rejected), (state) => {
      state.isLoading = false
    })

    builder.addMatcher(isAnyOf(fetchOrdersHistory.pending), (state) => {
      state.isLoading = true
      state.errorMessage = null
    })
  },
})

export {
  orderSlice,
  // Thunks
  fetchOrdersHistory,
}
