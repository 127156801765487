import { useEffect, useState } from 'react'

import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { EBackendResponseStatus } from '@/libs/enums'
import { EWebsocketStatus } from '@/libs/enums/websocket-status.enum'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TSocketResponse } from '@/libs/types/backend-response.type'
import { TPanelsDataSocketResponse } from '@/libs/types/panels-data-socket-response.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import { clearUserSellPanels, setUserProfitPanels } from '@/store/slices/profit-sell.slice'

const panelsSniperSocket = new SniperSockerService()

type TSubscribedToInfo = {
  token: string
  chain: string
}

const useWatchPanels = () => {
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)

  const [subscribedTo, setSubscribedTo] = useState<TSubscribedToInfo | null>(null)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      panelsSniperSocket.getStatus() !== EWebsocketStatus.OPEN ||
      !selectedTokenAddress ||
      !subscribedTo ||
      selectedTokenAddress.toLowerCase() === subscribedTo.token ||
      isAppIdle
    ) {
      return
    }

    panelsSniperSocket.emit(JSON.stringify({ u: subscribedTo.token, b: subscribedTo.chain }))
    dispatch(clearUserSellPanels())
    panelsSniperSocket.emit(
      JSON.stringify({ s: selectedTokenAddress, b: currentChain.indexerChainId.toString() }),
    )
    setSubscribedTo({
      token: selectedTokenAddress.toLowerCase(),
      chain: currentChain.indexerChainId.toString(),
    })
  }, [selectedTokenAddress, currentChain, isAppIdle])

  useSniperWebsocket({
    socket: panelsSniperSocket,
    connectionProps: {
      endpoint: 'order/stream/panels/active',
      query: createQueryString({ b: currentChain.indexerChainId, l: 'en' }),
      reconnect: false,
      onOpen: () => {
        panelsSniperSocket.emit(
          JSON.stringify({
            s: selectedTokenAddress,
            b: currentChain.indexerChainId.toString(),
          }),
        )
        setSubscribedTo(
          selectedTokenAddress
            ? {
                token: selectedTokenAddress.toLowerCase(),
                chain: currentChain.indexerChainId.toString(),
              }
            : null,
        )
      },
    },
    verifyExistanceBeforeConnect: [selectedTokenAddress],
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSocketResponse<TPanelsDataSocketResponse | string>
      if (typeof data.data === 'string' || data.status !== EBackendResponseStatus.SUCCESS) {
        return
      }
      dispatch(setUserProfitPanels(data.data))
    },
  })
}

export { useWatchPanels }
