import { useState } from 'react'

import cls from 'classnames'

import { ButtonIcon, Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

import { NewTokens } from './libs/new-tokens'
import { Trending } from './libs/trending/trending'
import styles from './styles.module.scss'

const navTabs = [
  {
    value: 'trending',
    label: 'Trending',
    icon: IconName.FIRE,
  },
  {
    value: 'new',
    label: 'New',
    icon: IconName.BOLT,
  },
]

const tabs = {
  trending: <Trending />,
  new: <NewTokens />,
}

const TokenList = () => {
  const [currentTab, setCurrentTab] = useState(navTabs[0].value)

  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        {navTabs.map(({ value, label, icon }) => (
          <ButtonIcon
            className={cls(styles.navElement, value === currentTab && styles.active)}
            key={value}
            onClick={() => setCurrentTab(value)}
          >
            <Icon name={icon} />
            <Typography variant="body1" textColor="light-grey">
              {label}
            </Typography>
          </ButtonIcon>
        ))}
      </div>
      {tabs[currentTab as keyof typeof tabs]}
    </div>
  )
}

export { TokenList }
