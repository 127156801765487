import { FC } from 'react'

import { Stack } from '@mui/material'

import { BackButton, Typography } from '@/libs/common'

import { PrelaunchSnipesItem } from './libs/components/prelaunch-snipes-item'
import styles from './styles.module.scss'

// @TODO delete mock data

const data = [
  {
    token: 'UNIBOT',
    users: 4343,
    tokenAddress: '0x6982508145454Ce325dDbE47a25d4ec3d2311933',
  },
  {
    token: 'UNIBOT',
    users: 3123,
    tokenAddress: '0x6982508145454Ce325dDbE47a25fdec3d2311933',
  },
  {
    token: 'UNIBOT',
    users: 3131,
    tokenAddress: '0x6982508145454Ce32v5dDbE47a25d4ec3d211933',
  },
  {
    token: 'UNIBOT',
    users: 4578,
    tokenAddress: '0x6982508145454Ce325vdDbE47a25d4ecv3d21933',
  },
  {
    token: 'UNIBOT',
    users: 4878,
    tokenAddress: '0x6982508145454Ce325dDbE47a25d4ec3dzz11933',
  },
]

const PrelaunchSnipes: FC = () => {
  return (
    <div className={styles.content}>
      <BackButton className={styles.back} />

      <div className={styles.titleWrapper}>
        <Typography variant="h1" className={styles.title}>
          Group bundles gathering for prelaunch snipes
        </Typography>
      </div>

      <Stack gap={2}>
        {data.map((tokenInfo) => (
          <PrelaunchSnipesItem tokenInfo={tokenInfo} key={tokenInfo.tokenAddress} />
        ))}
      </Stack>
    </div>
  )
}

export { PrelaunchSnipes }
