import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useAppSelector } from '@/store'

const useWalletFromParams = () => {
  const userWallets = useAppSelector((state) => state.user.userWallets)

  const { publicKey } = useParams()

  const wallet = useMemo(
    () => userWallets?.find((item) => item.address === publicKey),
    [userWallets, publicKey],
  )
  return wallet
}

export { useWalletFromParams }
