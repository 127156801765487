import { useEffect } from 'react'

import { BuyTab } from '@/components/dashboard-component-lite/libs/buy-sell-block/libs/buy-tab'
import { useModal } from '@/pages/modal-page/modal-page'

const LiteBuy = () => {
  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Buy' })
  }, [])

  return (
    <>
      <BuyTab />
    </>
  )
}

export { LiteBuy }
