import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { EBackendResponseStatus } from '@/libs/enums'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TSocketResponse } from '@/libs/types/backend-response.type'
import { TSniperBasicInfo } from '@/libs/types/sniper-socket-responses.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentChainBasicInfo } from '@/store/slices/chain.slice'

const basicSniperSocket = new SniperSockerService()

const useWatchChainInfo = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const dispatch = useAppDispatch()

  useSniperWebsocket({
    socket: basicSniperSocket,
    connectionProps: {
      endpoint: 'indexer/stream/basic',
      query: createQueryString({ b: currentChain.indexerChainId, l: 'en' }),
      isPublic: true,
    },
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSocketResponse<TSniperBasicInfo | string>
      if (typeof data.data === 'string' || data.status !== EBackendResponseStatus.SUCCESS) {
        return
      }
      dispatch(setCurrentChainBasicInfo(data.data))
    },
  })
}

export { useWatchChainInfo }
