import { getBars } from '@/api/chart'
import {
  Bar,
  HistoryCallback,
  OnReadyCallback,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SubscribeBarsCallback,
} from '@/charting_library/charting_library'
import { createQueryString } from '@/libs/helper/createQueryString'
import { SniperSockerService } from '@/socket'
import { store } from '@/store'

import { RESOLUTION_MAP, supportedResolutions } from '../../configs'
import { TCustomSymbolInfo } from '../../types/custom-symbol-info.type'
import { TDatafeedSubscriber } from './types'

type TSetBarsFunc = (bars: Bar[]) => void

type TProps = {
  onBarsUpdate?: TSetBarsFunc
}

class Datafeed {
  barsSniperSocket: SniperSockerService
  isWebsocletConnected = false
  subscriber: TDatafeedSubscriber | null = null
  onBarsUpdate: TSetBarsFunc | null = null
  currentBars: Bar[] = []

  constructor({ onBarsUpdate }: TProps) {
    this.onBarsUpdate = onBarsUpdate || null
    this.barsSniperSocket = new SniperSockerService()
  }

  // This is a custom method that should be called when a TradingView is mounted
  connectToWebsocket = async () => {
    if (this.isWebsocletConnected) {
      return Promise.resolve(null)
    }

    return new Promise((resolve, reject) => {
      this.barsSniperSocket.connect({
        endpoint: 'token/stream/bars',
        query: createQueryString({
          b: store.getState().chain.currentChain.indexerChainId,
        }),
        isPublic: true,
        onOpen: () => {
          this.isWebsocletConnected = true
          resolve(null)
        },
      })

      this.barsSniperSocket.onError(() => {
        this.isWebsocletConnected = false
        reject(null)
      })
    })
  }

  // This is a custom method that should be called when a TradingView is unmounted
  removeDatafeed = () => {
    this.barsSniperSocket.disconnect()
    this.isWebsocletConnected = false
  }

  // This method is called once the TradingView is mounted
  onReady = (cb: OnReadyCallback) => {
    setTimeout(
      () =>
        cb({
          supported_resolutions: supportedResolutions,
        }),
      0,
    )
  }

  // This method is called when user changes token or resolution
  resolveSymbol = async (symbol: string, onSymbolResolvedCallback: ResolveCallback) => {
    const currentToken = store.getState().chain.currentToken

    const symbolInfo: TCustomSymbolInfo = {
      ticker: symbol,
      name: symbol.split('/')[0],
      description: '',
      type: 'crypto',
      session: '24x7',
      timezone: 'Etc/UTC',
      exchange: '',
      listed_exchange: '',
      format: 'price',
      minmov: 1,
      pricescale: 10 ** 16,
      has_intraday: true,
      intraday_multipliers: ['1', '5', '15', '60', '240', '720'] as ResolutionString[],
      // TODO: Check if these fields are needed
      // has_weekly_and_monthly: true,
      // has_daily: true,
      // daily_multipliers: ['1', '7'],
      // has_seconds: true,
      // seconds_multipliers: ['1', '15'] as ResolutionString[],
      supported_resolutions: supportedResolutions,
      volume_precision: 8, // 2
      data_status: 'streaming',
      tokenMeta: {
        tokenAddress: currentToken?.token.address || '',
        quoteToken: currentToken?.info?.quote_token || '',
      },
    }

    setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0)
  }

  // This method is called after the resolveSymbol to get historical data for a token
  getBars = async (
    symbolInfo: TCustomSymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: ErrorCallback,
  ) => {
    try {
      const {
        tokenMeta: { tokenAddress, quoteToken },
      } = symbolInfo
      const { from, to } = periodParams

      const bars = await getBars({
        from,
        to,
        resolution,
        tokenAddress,
        quoteToken: quoteToken,
        blockchain: store.getState().chain.currentChain.indexerChainId,
      })

      if (bars.length) {
        this.currentBars = [...bars, ...this.currentBars]
        this.onBarsUpdate?.(this.currentBars)
      }
      onHistoryCallback(bars, { noData: !bars.length })
    } catch (err) {
      onErrorCallback(err as any)
    }
  }

  // This method is called after the resolveSymbol to subscribe to a new token/resolution
  subscribeBars = (
    symbolInfo: TCustomSymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
    listenerGuid: string,
  ) => {
    const currentToken = store.getState().chain.currentToken
    if (!currentToken) return

    this.barsSniperSocket.onMessage((jsonData) => {
      const { data } = JSON.parse(jsonData)
      const resolutionKey = RESOLUTION_MAP[resolution as keyof typeof RESOLUTION_MAP]
      if (data[resolutionKey]) {
        const ohlcvt = data[resolutionKey].u
        if (ohlcvt) {
          const currentLastBar = {
            time: ohlcvt.t * 1000,
            high: ohlcvt.h,
            low: ohlcvt.l,
            open: ohlcvt.o,
            close: ohlcvt.c,
            volume: ohlcvt.v,
          }

          this.currentBars = [...this.currentBars, currentLastBar]
          this.onBarsUpdate?.(this.currentBars)
          onRealtimeCallback(currentLastBar)
        }
      }
    })

    // Don't do anything if the component requested info for the same token
    if (this.subscriber?.symbolInfo.tokenMeta.tokenAddress === symbolInfo.tokenMeta.tokenAddress) {
      return
    }

    // Unsubscribe from the previous token if the component requested a new one
    if (this.subscriber) {
      this.barsSniperSocket.emit(
        JSON.stringify({
          u: this.subscriber.payload.s,
          q: this.subscriber.payload.q,
        }),
      )
    }

    const payload = {
      s: currentToken.pair.address,
      q: currentToken.info?.quote_token || '',
    }
    this.barsSniperSocket.emit(JSON.stringify(payload))

    const newSub = {
      payload,
      listenerGuid,
      resolution,
      symbolInfo,
    }
    this.subscriber = newSub
  }

  // The TradingView library requires this method, but we don't use it
  unsubscribeBars = () => {}
}

export { Datafeed }
