import { useCallback, useEffect } from 'react'

function useDebouncedEffect(effect: any, deps: any, delay: any) {
  const callback = useCallback(effect, deps)

  useEffect(() => {
    const handler = setTimeout(() => callback(), delay)

    return () => clearTimeout(handler)
  }, [callback, delay])
}

export { useDebouncedEffect }
