import { FC } from 'react'

import cls from 'classnames'

import { ButtonIcon } from '@/libs/common/button-icon'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProperty = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
}

const RefreshButton: FC<TProperty> = ({ onClick, isLoading }) => {
  return (
    <ButtonIcon
      icon={IconName.REFRESH}
      onClick={onClick}
      className={cls(styles.refresh, {
        [styles.loading]: isLoading,
      })}
      disabled={isLoading}
    />
  )
}

export { RefreshButton }
