import { FC, useEffect, useState } from 'react'

import { Box, LinearProgress } from '@mui/material'

import styles from './styles.module.scss'

type TProps = {
  isSellInitial: boolean
  setIsSellInitial: (value: boolean) => void
}

const ProgressBar: FC<TProps> = ({ isSellInitial, setIsSellInitial }) => {
  const [progress, setProgress] = useState(100)

  useEffect(() => {
    let timer: undefined | ReturnType<typeof setInterval> = undefined

    if (isSellInitial) {
      setProgress(100)
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress > 0) {
            return prevProgress - 1
          } else {
            clearInterval(timer)
            setIsSellInitial(false)
            return 0
          }
        })
      }, 100)
    } else {
      setProgress(100)
      if (timer !== undefined) clearInterval(timer)
    }

    return () => {
      if (timer !== undefined) clearInterval(timer)
    }
  }, [isSellInitial, setIsSellInitial])

  return (
    <>
      {isSellInitial && (
        <Box
          sx={{
            height: 4,
            width: '100%',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            '& .MuiLinearProgress-colorInfo': {
              backgroundColor: '#303030',
            },
            '& .MuiLinearProgress-barColorInfo': {
              backgroundColor: '#ffc800',
            },
          }}
        >
          <LinearProgress
            variant="determinate"
            value={progress}
            color="info"
            className={styles.progressBar}
          />
        </Box>
      )}
    </>
  )
}
export { ProgressBar }
