import { FC, useEffect, useState } from 'react'

import cls from 'classnames'

import { TokenDataItem } from '@/libs/common/token-data-item'
import { formatNumber } from '@/libs/helper'

import styles from './styles.module.scss'

type TElement = {
  title: string
  value?: number | string
  currencySymbol?: string
}

type TProperty = {
  className?: string
  firstElement: TElement
  secondElement: TElement
}

const ValuesCollation: FC<TProperty> = ({ className, firstElement, secondElement }) => {
  const [status, setStatus] = useState({ first: 100, second: 0 })

  useEffect(() => {
    if (!firstElement.value && !secondElement.value) return

    const first =
      (+(firstElement.value || 0) / (+(firstElement.value || 0) + +(secondElement.value || 0))) *
      100
    setStatus({ first, second: 100 - first })
  }, [firstElement.value, secondElement.value])

  return (
    <div className={cls(styles.values, className)}>
      <div className={styles.header}>
        <TokenDataItem
          title={firstElement.title}
          value={formatNumber(firstElement.value || 0).formatted}
          currencySymbol={firstElement.currencySymbol}
        />
        <TokenDataItem
          title={secondElement.title}
          value={formatNumber(secondElement.value || 0).formatted}
          currencySymbol={secondElement.currencySymbol}
          className={styles.textEnd}
          titleRightAlign
        />
      </div>
      <div className={styles.progressBar}>
        <div
          className={cls(styles.value1, status.first === 100 && styles.fullLine)}
          style={{ width: `${status.first}%` }}
        ></div>
        <div
          className={cls(styles.value2, status.second === 100 && styles.fullLine)}
          style={{ width: `${status.second}%` }}
        ></div>
      </div>
    </div>
  )
}

export { ValuesCollation }
