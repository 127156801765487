import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import Stack from '@mui/material/Stack'

import { AuthCard } from '@/components/auth-card'
import { Button } from '@/libs/common'
import { Icon } from '@/libs/common/icon'
import { Typography } from '@/libs/common/typography'
import { AppRoute, IconName, VerificationStatus } from '@/libs/enums'

import styles from './styles.module.scss'

const MasterPasswordAdvantages: FC = () => {
  const navigate = useNavigate()

  const handleSkipSetup = () => {
    navigate(AppRoute.SETUP_SUCCESS)
  }

  const handleCreatePassword = () => {
    navigate(AppRoute.CREATE_MASTER_PASSWORD)
  }
  return (
    <AuthCard
      verificationStatus={VerificationStatus.SETUP_WALLETS}
      className={styles.card}
      isHeaderButtons
      isBackButton
    >
      <div className={styles.content}>
        <Stack className={styles.desc} spacing={1} useFlexGap>
          <Typography variant="h1" align="center">
            Create Master password
          </Typography>
          <Typography variant="body1" align="center">
            This master password ensures that your wallets are encrypted with your password,
            dramatically increasing the security of the system.
          </Typography>
        </Stack>
        <div className={styles.advantage}>
          <Icon name={IconName.DOWNLOAD} />
          <div className={styles.advantageDesc}>
            <Typography variant="h6">Enhanced security</Typography>
            <Typography variant="body2">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry&apos;s standard dummy.
            </Typography>
          </div>
        </div>
        <div className={styles.advantage}>
          <Icon name={IconName.DOWNLOAD} />
          <div className={styles.advantageDesc}>
            <Typography variant="h6">Enhanced security</Typography>
            <Typography variant="body2">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry&apos;s standard dummy.
            </Typography>
          </div>
        </div>
        <div className={styles.advantage}>
          <Icon name={IconName.DOWNLOAD} />
          <div className={styles.advantageDesc}>
            <Typography variant="h6">Enhanced security</Typography>
            <Typography variant="body2">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry&apos;s standard dummy.
            </Typography>
          </div>
        </div>
        <div className={styles.footer}>
          <Button onClick={handleCreatePassword}>Create master password</Button>
          <Button styleVariant="black" onClick={handleSkipSetup}>
            Skip master wallet setup
          </Button>
        </div>
      </div>
    </AuthCard>
  )
}

export { MasterPasswordAdvantages }
