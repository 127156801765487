import { FC } from 'react'

import { Grid } from '@mui/material'

import { Accordion, Typography } from '@/libs/common'

import { TemplateSettingsElement } from './libs/components/template-settings-element'
import styles from './styles.module.scss'

// TODO: Get all the values from the props
type TProps = {
  onChange?: (value: boolean) => void
}

const TemplateSettingsList: FC<TProps> = ({ onChange }) => {
  return (
    <Accordion
      className={styles.accordion}
      titleClassName={styles.accordionTitle}
      title="Template Settings"
      withDivider
      mainColorArrow
      onChange={onChange}
    >
      <Grid container rowGap={2} flexDirection="column">
        <Grid container rowGap={1.5} flexDirection="column">
          <Typography variant="body2" textColor="white">
            Buy
          </Typography>
          <div className={styles.settingsBlock}>
            <TemplateSettingsElement label="Mempool Backup" value={true} />
            <TemplateSettingsElement label="Max Tx or fail" value={true} />
            <TemplateSettingsElement label="First Bundle Buy" value="20 ETH" />
            <TemplateSettingsElement label="Backup Bundle Buy" value="20.00 GWEI" />
            <TemplateSettingsElement label="Mempool Backup Buy" value="20.00 GWEI" />
            <TemplateSettingsElement label="First Bundle or fail" value="0.03 ETH TIP" />
            <TemplateSettingsElement label="Backup Bundle" value="0.03 ETH TIP" />
            <TemplateSettingsElement label="Min % / Tokens or fail" value="20%" />
            <TemplateSettingsElement label="Slippage" value="50%" />
            <TemplateSettingsElement label="Wallets selected" value="3/4" />
            <TemplateSettingsElement label="Sell Rug Extra Tip" value="20 GWEI" />
            <TemplateSettingsElement label="Max Marketcap" value="$30,000" />
            <TemplateSettingsElement label="Max Buy Tax" value="20%" />
            <TemplateSettingsElement label="Max Sell Tax" value="25%" />
            <TemplateSettingsElement label="Min Liquidity" value="$2,400" />
            <TemplateSettingsElement label="Max Liquidity" value="$3,400" />
          </div>
        </Grid>
        <Grid container rowGap={1.5} flexDirection="column">
          <Typography variant="body2" textColor="white">
            Safety
          </Typography>
          <div className={styles.settingsBlock}>
            <TemplateSettingsElement label="Anti-Rug" value={true} />
            <TemplateSettingsElement label="Anti-Blacklist" value={true} />
            <TemplateSettingsElement label="Anti-Rug Threshold" value="50%" />
            <TemplateSettingsElement label="Transfer On Blacklist" value={true} />
            <TemplateSettingsElement label="Sell Rug Extra Tip" value="20 GWEI" />
            <TemplateSettingsElement label="Max Marketcap" value="$30,000" />
            <TemplateSettingsElement label="Max Buy Tax" value="20%" />
            <TemplateSettingsElement label="Max Sell Tax" value="20%" />
            <TemplateSettingsElement label="Min Liquidity" value="$2,400" />
            <TemplateSettingsElement label="Max Liquidity" value="$3,400" />
          </div>
        </Grid>
        <Grid container rowGap={1.5} flexDirection="column">
          <Typography variant="body2" textColor="white">
            Sell
          </Typography>
          <div className={styles.settingsBlock}>
            <TemplateSettingsElement label="Sell Priority" value="20 GWEI" />
            <TemplateSettingsElement label="Auto Sell" value={true} />
            <TemplateSettingsElement label="Sell on Profit" value="20%" />
            <TemplateSettingsElement label="Sell Profit Amount" value="400%" />
            <TemplateSettingsElement label="Wallets to sell on Profit" value="1, 3, 4" />
            <TemplateSettingsElement label="Trailing Sell" value="20%" />
            <TemplateSettingsElement label="Sell in steps">
              <Typography variant="body2" textColor="light-grey">
                W1 - x20
              </Typography>
              <Typography variant="body2" textColor="light-grey">
                W2 - x40
              </Typography>
            </TemplateSettingsElement>
            <TemplateSettingsElement label="Sell in steps" />
          </div>
        </Grid>
      </Grid>
    </Accordion>
  )
}

export { TemplateSettingsList }
