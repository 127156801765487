import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

// import { useSearchParams } from 'react-router-dom'
import { Grid, Stack } from '@mui/material'
import cn from 'classnames'

import { SelectWallet } from '@/components/select-wallet'
import { SettingField } from '@/components/setting-field'
import {
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  Input,
  Tabs,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { AppMode, AppRoute, IconName, ManualSettingsSection } from '@/libs/enums'
import { getTemplateActionTitle } from '@/pages/modal-page/libs/helpers'

import styles from './styles.module.scss'

type mockState = {
  templateType: string | null
  transferOnBlacklist: boolean
  antiBlacklist: boolean
  autoSell: boolean
}

const defaultValues = {
  privateTransaction: false,
  degenChadMode: true,
  frontrunBuys: false,
  backrunBuys: true,
  frontrunBuyPriority: '',
  frontrunSells: false,
  frontrunSellPriority: '',
  copySells: false,
  slippage: 0,
  selectedWallets: [],
  buyPriority: '',
  approvePriority: '',
  maxTxOrFail: true,
  transferOnBlacklist: false,
  antiBlacklist: true,
  antiRug: false,
  antiRugThreshold: 0,
  sellRugExtraTip: '12',
  maxBuyTax: '',
  maxSellTax: '',
  minLiquidity: '',
  maxLiquidity: '',
  maxMarketcap: '',
  autoSell: false,
  sellPriority: '',
  walletsToSellOnProfit: [],
  walletsToSellOnLoss: [],
  stopLoss: '',
  stopLossAmount: '',
  firstStep: '25',
  secondStep: '32',
  thirdStep: '40',
  amount: '',
  firstBundleOrFail: '',
  firstBundleBuy: '',
  backupBundle: '',
  backupBundleBuy: '',
  mempoolBackupBuy: '',
  minPercentForFail: '',
  selectedWalletsForProfit: [],
  selectedWalletsForLoss: [],
  sellOnProfit: '',
  sellOnProfitAmount: '',
  walletsToTrailingSell: [],
  trailingSell: '',
}

const mockData = {
  buy: {
    amount: '0.1 ETH',
    frontrunPriority: '20 GWEI',
    privateTransaction: true,
    walletsSelected: '2/6',
    approvePriority: '20 GWEI',
    frontrunBuys: true,
    backrunBuys: true,
    slippage: 'Unlimited',
    buyPriority: '20 GWEI',
    degenChadMode: true,
  },
  safety: {
    antiRug: true,
    sellRugExtraTip: '20 GWEI',
    maxBuyTax: '30%',
    minLiquidity: '1000 USD',
    maxMcap: '30000 USD',
    antiRugThreshold: '30%',
    antiBlacklist: false,
    maxSellTax: '30%',
    maxLiquidity: '3000 USD',
    transferOnBlacklist: true,
  },
  sell: {
    copySells: true,
    frontrunSellPriority: '20 GWEI',
    autoSell: true,
    sellProfitAmount: '100%',
    walletsToSellOnLoss: '3/10',
    trailingSell: '20%',
    frontrunSells: true,
    sellPriority: '20 GWEI',
    sellOnProfit: '100%',
    walletsToSellOnProfit: '3/10',
    sellInSteps: ['10x-2', '20x-2'],
    sellOnLoss: '-50%',
  },
}

// const ProfitSettingsTabs: TSelectOption<string>[] = [
//   { value: 'sellInSteps', label: 'Sell in steps' },
//   { value: 'sellOnProfit', label: 'Sell on profit' },
//   { value: 'trailingSell', label: 'Trailing sell' },
// ]

const CopyTradeSettings = () => {
  const [formValues, setFormValues] = useState<mockState>({
    templateType: 'manual',
    transferOnBlacklist: false,
    antiBlacklist: false,
    autoSell: false,
  })

  const [mainTab, setMainTab] = useState(0)
  // const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)
  // const [isAutoSellSettings, setIsAutoSellSetting] = useState(false)
  // const [selectedWallets, setSelectedWallets] = useState<number[]>([])
  const [frontrunBuys, setFrontrunBuys] = useState(defaultValues.frontrunBuys)
  // const [frontrunSells, setFrontrunSells] = useState(defaultValues.frontrunSells)
  const [backrunBuys, setBackrunBuys] = useState(defaultValues.backrunBuys)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const action = searchParams.get('action')
  const title = getTemplateActionTitle(action)
  const { handleSubmit, control, setValue } = useForm({ defaultValues })

  const isLastMainTab = mainTab === Object.values(ManualSettingsSection).length - 1

  // const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
  //   setProfitTab(newValue)
  // }

  const handleChangeMainTab = () => {
    setMainTab((value) => (value < Object.values(ManualSettingsSection).length ? value + 1 : value))
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }

  const handleChange = (e: React.BaseSyntheticEvent, newValue: string | boolean | null) => {
    setFormValues({
      ...formValues,
      [e.currentTarget.name]: newValue,
    })
  }

  const handleOpenModalTemplates = () => {
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.COPY_TRADE_TEMPLATE}`,
    )
  }

  return (
    <div>
      <Typography variant="h1" align="center">
        {title}
      </Typography>

      <Grid className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowGap={3}>
            <Grid container rowGap={3}>
              <Grid width="100%">
                <Input
                  label="Template Name"
                  placeholder="Enter template name"
                  className={styles.input}
                  maxLength={15}
                />

                <Input
                  label="Wallet address"
                  placeholder="Enter wallet address"
                  className={styles.input}
                />
              </Grid>

              <Stack spacing={2} useFlexGap width="100%">
                <Grid container className={styles.row} justifyContent="space-between" wrap="nowrap">
                  <Grid container gap={2} alignItems="center">
                    <Typography variant="body2" component="h2" textColor="white">
                      Templates
                    </Typography>

                    <Button
                      className={styles.button}
                      styleVariant="borderless-gold"
                      iconStartName={IconName.ADD_CIRCLE}
                      transparent
                      onClick={handleOpenModalTemplates}
                    >
                      Add new
                    </Button>
                  </Grid>

                  <Grid container gap={2} alignItems="center" width="fit-content" wrap="nowrap">
                    <Button
                      className={styles.button}
                      styleVariant="borderless-gold"
                      iconStartName={IconName.SETTING}
                      transparent
                      disabled
                    >
                      Customize
                    </Button>

                    <Button
                      className={styles.button}
                      styleVariant="borderless-gold"
                      iconStartName={IconName.EDIT}
                      transparent
                      disabled
                    >
                      Edit
                    </Button>

                    <Button
                      className={styles.button}
                      styleVariant="borderless-gold"
                      iconStartName={IconName.DELETE}
                      transparent
                      disabled
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>

                <Grid container className={styles.row} wrap="nowrap">
                  <ButtonGroupRadio
                    exclusive
                    separated
                    value={formValues.templateType}
                    onChange={handleChange}
                    className={styles.buttonGroupRadio}
                  >
                    <ButtonGroupRadioButton value="manual" name="templateType">
                      Manual
                    </ButtonGroupRadioButton>

                    <ButtonGroupRadioButton value="fast-buy" name="templateType">
                      Fast buy
                    </ButtonGroupRadioButton>

                    <ButtonGroupRadioButton value="max-profit" name="templateType">
                      Max Profit
                    </ButtonGroupRadioButton>

                    <ButtonGroupRadioButton value="quick-trade" name="templateType">
                      Quick trade
                    </ButtonGroupRadioButton>
                  </ButtonGroupRadio>
                </Grid>
              </Stack>

              {formValues.templateType === 'manual' && (
                <Tabs
                  tabControl
                  value={mainTab}
                  tabs={[
                    {
                      label: ManualSettingsSection.BUY,
                      content: (
                        <Grid container rowGap={2}>
                          <Controller
                            name="amount"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                isNumeric
                                className={cn(styles.input, styles.inputSize)}
                                label="Amount"
                                placeholder="Enter gwei amount"
                                endAdornment={<EndAdornment label="ETH" icon={IconName.ETHEREUM} />}
                                {...field}
                              />
                            )}
                          />

                          <Grid container columnGap={3} flexWrap="nowrap">
                            <Controller
                              name="privateTransaction"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <SwitchInline
                                  label="Anti-Mev"
                                  tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="degenChadMode"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <SwitchInline
                                  label="Degen Chad Mode"
                                  tooltipInfo="For advanced traders. If enabled, the safety rules from the simulation will be ignored."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>

                          <Grid container columnGap={3} flexWrap="nowrap">
                            <Controller
                              name="frontrunBuys"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Frontrun Buys"
                                  tooltipInfo="Will try to buy faster than other wallet. If it detects transaction initiated by other wallet, it will spend more gas fee (gwei) to prioritize your transaction in network."
                                  onChange={(
                                    _: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean,
                                  ) => {
                                    if (checked && backrunBuys) {
                                      setValue('backrunBuys', false)
                                      setBackrunBuys(false)
                                    }
                                    setFrontrunBuys(checked)
                                    onChange(checked)
                                  }}
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="backrunBuys"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Backrun Buys"
                                  tooltipInfo="This will make sure that the tracked transaction is prioritized over the your backrun transaction."
                                  onChange={(
                                    _: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean,
                                  ) => {
                                    if (checked && frontrunBuys) {
                                      setValue('frontrunBuys', false)
                                      setFrontrunBuys(false)
                                    }
                                    setBackrunBuys(checked)
                                    onChange(checked)
                                  }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>

                          {frontrunBuys && (
                            <Controller
                              name="frontrunBuyPriority"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  isNumeric
                                  className={styles.input}
                                  label="Frontrun Priority"
                                  placeholder="Enter gwei amount"
                                  tooltipInfo="It will add this much priority gwei to the dev transaction gwei, if developer does 80 and you enter 5 here it will do 85."
                                  endAdornment={
                                    <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                                  }
                                  {...field}
                                />
                              )}
                            />
                          )}

                          <Controller
                            name="slippage"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <PercentsInput
                                label="Slippage"
                                onOptionSelect={(value) => field.onChange(value)}
                                tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                                placeholder="X"
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            name="selectedWallets"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <SelectWallet
                                isMulti
                                wallet={value}
                                {...field}
                                tooltipInfo="tooltipInfo"
                              />
                            )}
                          />

                          <Grid container display="flex" flexWrap="nowrap" gap={3}>
                            <Controller
                              name="buyPriority"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  className={styles.input}
                                  label="Buy Priority"
                                  placeholder="Enter gwei amount"
                                  isNumeric
                                  tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                                  onAutoChange={() => {
                                    //TO DO: added auto switch
                                  }}
                                  endAdornment={
                                    <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                                  }
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="approvePriority"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  className={cn(styles.input, styles.inputSize)}
                                  label="Approve priority"
                                  placeholder="Enter gwei amount"
                                  isNumeric
                                  tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
                                  endAdornment={
                                    <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                                  }
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      ),
                    },
                    {
                      label: ManualSettingsSection.SAFETY,
                      content: (
                        <Grid container rowGap={2} flexDirection="column">
                          <Grid container columnGap={3} flexWrap="nowrap">
                            <Controller
                              name="transferOnBlacklist"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <SwitchInline
                                  label="Transfer On Blacklist"
                                  tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="antiBlacklist"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <SwitchInline
                                  label="Anti-Blacklist"
                                  tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>

                          <HiddenInputs
                            expanded={isAntiRugSettings}
                            trigger={
                              <Controller
                                name="antiRug"
                                control={control}
                                render={({ field: { ref, onChange, ...field } }) => (
                                  <SwitchInline
                                    label="Anti-Rug"
                                    tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                                    onChange={(
                                      _: React.ChangeEvent<HTMLInputElement>,
                                      checked: boolean,
                                    ) => {
                                      setIsAntiRugSettings(checked)
                                      onChange(checked)
                                    }}
                                    {...field}
                                  />
                                )}
                              />
                            }
                          >
                            <Grid container rowGap={2}>
                              <Controller
                                name="antiRugThreshold"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <PercentsInput
                                    label="Anti-Rug Threshold"
                                    onOptionSelect={(value) => field.onChange(value)}
                                    tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                                    {...field}
                                  />
                                )}
                              />

                              <Controller
                                name="sellRugExtraTip"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder="Amount"
                                    isNumeric
                                    label="Sell Rug Extra Tip"
                                    tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          </HiddenInputs>

                          <Grid container flexWrap="nowrap" columnGap={3}>
                            <Controller
                              name="maxBuyTax"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter percentage"
                                  isNumeric
                                  label="Max Buy Tax"
                                  adornmentText="%"
                                  tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="maxSellTax"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter percentage"
                                  isNumeric
                                  label="Max Sell Tax"
                                  adornmentText="%"
                                  tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid container flexWrap="nowrap" columnGap={3}>
                            <Controller
                              name="minLiquidity"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter min liquidity amount"
                                  isNumeric
                                  label="Min Liquidity"
                                  adornmentText="$"
                                  tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="maxLiquidity"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter max liquidity amount"
                                  isNumeric
                                  label="Max Liquidity"
                                  adornmentText="$"
                                  tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Controller
                            name="maxMarketcap"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter max marketcap"
                                isNumeric
                                label="Max Marketcap"
                                adornmentText="$"
                                tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      ),
                    },
                    // {
                    //   label: ManualSettingsSection.SELL,
                    //   content: (
                    //     <Grid gap={3} container flexDirection="column">
                    //       <Grid gap={2} container flexDirection="column">
                    //         <Controller
                    //           name="copySells"
                    //           control={control}
                    //           render={({ field: { ref, onChange, ...field } }) => (
                    //             <SwitchInline
                    //               label="Copy sells"
                    //               tooltipInfo="Enable to copy sales transactions of a tracked wallet."
                    //               {...field}
                    //             />
                    //           )}
                    //         />

                    //         <Controller
                    //           name="frontrunSells"
                    //           control={control}
                    //           render={({ field: { ref, onChange, ...field } }) => (
                    //             <SwitchInline
                    //               label="Frontrun Sells"
                    //               tooltipInfo="Used to sell faster than the tracked wallet. When the bot notices that the tracked wallet is trying to sell a token, it will launch a transaction with a higher priority to beat it."
                    //               onChange={(
                    //                 _: React.ChangeEvent<HTMLInputElement>,
                    //                 checked: boolean,
                    //               ) => {
                    //                 setFrontrunSells(checked)
                    //                 onChange(checked)
                    //               }}
                    //               {...field}
                    //             />
                    //           )}
                    //         />
                    //         {frontrunSells && (
                    //           <Controller
                    //             name="frontrunSellPriority"
                    //             control={control}
                    //             render={({ field: { ref, ...field } }) => (
                    //               <OptionalInput
                    //                 className={styles.input}
                    //                 label="Frontrun Priority"
                    //                 placeholder="Enter gwei amount"
                    //                 isNumeric
                    //                 tooltipInfo="It will add this much priority gwei to the dev transaction gwei, if developer does 80 and you enter 5 here it will do 85."
                    //                 endAdornment={
                    //                   <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                    //                 }
                    //                 {...field}
                    //               />
                    //             )}
                    //           />
                    //         )}
                    //       </Grid>

                    //       <HiddenInputs
                    //         expanded={isAutoSellSettings}
                    //         trigger={
                    //           <Controller
                    //             name="autoSell"
                    //             control={control}
                    //             render={({ field: { ref, onChange, ...field } }) => (
                    //               <SwitchInline
                    //                 label="Auto Sell"
                    //                 tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                    //                 {...field}
                    //                 onChange={(
                    //                   _: React.ChangeEvent<HTMLInputElement>,
                    //                   checked: boolean,
                    //                 ) => {
                    //                   setIsAutoSellSetting(checked)
                    //                   onChange(checked)
                    //                 }}
                    //               />
                    //             )}
                    //           />
                    //         }
                    //       >
                    //         <Grid container rowGap={2} flexDirection="column">
                    //           <div className={styles.dividerContainer}>
                    //             <Typography variant="body2" textColor="grey">
                    //               Profit
                    //             </Typography>

                    //             <div className={styles.divider}></div>
                    //           </div>
                    //           <Controller
                    //             name="walletsToSellOnProfit"
                    //             control={control}
                    //             render={({ field: { ref, value, ...field } }) => {
                    //               setSelectedWallets(value)

                    //               return (
                    //                 <SelectWallet
                    //                   isMulti
                    //                   tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                    //                   wallet={value}
                    //                   {...field}
                    //                   label="Wallets to sell on Profit"
                    //                 />
                    //               )
                    //             }}
                    //           />

                    //           <ButtonGroupRadio
                    //             onChange={handleProfitTabChange}
                    //             value={profitTab}
                    //             exclusive
                    //             className={styles.group}
                    //           >
                    //             {ProfitSettingsTabs.map((tab) => (
                    //               <ButtonGroupRadioButton
                    //                 value={tab.value}
                    //                 key={tab.value}
                    //                 className={cn({ [styles.bgGrey]: tab.value === profitTab })}
                    //               >
                    //                 {tab.label}
                    //               </ButtonGroupRadioButton>
                    //             ))}
                    //           </ButtonGroupRadio>

                    //           {profitTab === ProfitSettingsTabs[0].value &&
                    //             selectedWallets.length > 0 && (
                    //               <div>
                    //                 <div className={styles.labelContent}>
                    //                   <InputLabel className={styles.label}>
                    //                     <Typography variant="body2" textColor="light-grey">
                    //                       Sell in steps
                    //                     </Typography>
                    //                   </InputLabel>
                    //                   <TooltipIcon info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%." marginLeft={5} />
                    //                 </div>
                    //                 <div className={styles.stepWrapper}>
                    //                   <Controller
                    //                     name="firstStep"
                    //                     control={control}
                    //                     render={({ field: { ref, ...field } }) => (
                    //                       <StepInput
                    //                         wallet="#1"
                    //                         balance="12.58 ETH"
                    //                         className={styles.firstInput}
                    //                         {...field}
                    //                       />
                    //                     )}
                    //                   />
                    //                   <Controller
                    //                     name="secondStep"
                    //                     control={control}
                    //                     render={({ field: { ref, ...field } }) => (
                    //                       <StepInput wallet="#2" balance="12.58 ETH" {...field} />
                    //                     )}
                    //                   />
                    //                   <Controller
                    //                     name="thirdStep"
                    //                     control={control}
                    //                     render={({ field: { ref, ...field } }) => (
                    //                       <StepInput
                    //                         wallet="#3"
                    //                         balance="12.58 ETH"
                    //                         className={styles.lastInput}
                    //                         {...field}
                    //                       />
                    //                     )}
                    //                   />
                    //                 </div>
                    //               </div>
                    //             )}

                    //           {profitTab === ProfitSettingsTabs[0].value &&
                    //             !selectedWallets.length && (
                    //               <Typography variant="body1" className={styles.walletsDescr}>
                    //                 No wallets selected
                    //               </Typography>
                    //             )}

                    //           {profitTab === ProfitSettingsTabs[1].value && (
                    //             <Grid container flexWrap="nowrap" columnGap={3}>
                    //               <Controller
                    //                 name="sellOnProfit"
                    //                 control={control}
                    //                 render={({ field: { ref, ...field } }) => (
                    //                   <OptionalInput
                    //                     placeholder="Enter sell on profit"
                    //                     isNumeric
                    //                     label="Sell on Profit"
                    //                     adornmentText="%"
                    //                     tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                    //                     {...field}
                    //                   />
                    //                 )}
                    //               />

                    //               <Controller
                    //                 name="sellOnProfitAmount"
                    //                 control={control}
                    //                 render={({ field: { ref, ...field } }) => (
                    //                   <OptionalInput
                    //                     placeholder="Enter sell profit amount"
                    //                     isNumeric
                    //                     label="Sell Profit Amount"
                    //                     adornmentText="%"
                    //                     tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                    //                     {...field}
                    //                   />
                    //                 )}
                    //               />
                    //             </Grid>
                    //           )}

                    //           {profitTab === ProfitSettingsTabs[2].value && (
                    //             <>
                    //               <Controller
                    //                 name="walletsToTrailingSell"
                    //                 control={control}
                    //                 render={({ field: { ref, value, ...field } }) => (
                    //                   <SelectWallet
                    //                     isMulti
                    //                     wallet={value}
                    //                     label="Wallets to trailing sell"
                    //                     tooltipInfo="tooltipInfo"
                    //                     {...field}
                    //                   />
                    //                 )}
                    //               />
                    //               <Controller
                    //                 name="trailingSell"
                    //                 control={control}
                    //                 render={({ field: { ref, ...field } }) => (
                    //                   <OptionalInput
                    //                     placeholder="Enter trailing sell"
                    //                     isNumeric
                    //                     label="Trailing Sell"
                    //                     adornmentText="%"
                    //                     tooltipInfo="tooltipInfo"
                    //                     {...field}
                    //                   />
                    //                 )}
                    //               />
                    //             </>
                    //           )}

                    //           {profitTab !== ProfitSettingsTabs[2].value && (
                    //             <>
                    //               <div className={styles.dividerContainer}>
                    //                 <Typography variant="body2" textColor="grey">
                    //                   Loss
                    //                 </Typography>

                    //                 <div className={styles.divider}></div>
                    //               </div>
                    //               <Controller
                    //                 name="walletsToSellOnLoss"
                    //                 control={control}
                    //                 render={({ field: { ref, value, ...field } }) => (
                    //                   <SelectWallet
                    //                     label="Wallets to sell on Loss"
                    //                     isMulti
                    //                     tooltipInfo="tooltip"
                    //                     wallet={value}
                    //                     {...field}
                    //                   />
                    //                 )}
                    //               />

                    //               <Grid container flexWrap="nowrap" columnGap={3}>
                    //                 <Controller
                    //                   name="stopLoss"
                    //                   control={control}
                    //                   render={({ field: { ref, ...field } }) => (
                    //                     <OptionalInput
                    //                       placeholder="Enter amount"
                    //                       label="Stop Loss"
                    //                       tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                    //                       adornmentText="%"
                    //                       {...field}
                    //                     />
                    //                   )}
                    //                 />
                    //                 <Controller
                    //                   name="stopLossAmount"
                    //                   control={control}
                    //                   render={({ field: { ref, ...field } }) => (
                    //                     <OptionalInput
                    //                       placeholder="Enter amount"
                    //                       label="Stop Loss Amount"
                    //                       tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                    //                       adornmentText="%"
                    //                       {...field}
                    //                     />
                    //                   )}
                    //                 />
                    //               </Grid>
                    //             </>
                    //           )}
                    //         </Grid>
                    //       </HiddenInputs>
                    //     </Grid>
                    //   ),
                    // },
                  ]}
                />
              )}

              {formValues.templateType === 'fast-buy' && (
                <Grid width="100%" gap={2} container>
                  <Typography variant="body2" textColor="white">
                    Template Settings
                  </Typography>

                  <Grid container flexDirection="column" gap={1.5}>
                    <Typography variant="body2" textColor="white">
                      Buy
                    </Typography>

                    <Grid container gap={3}>
                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField title="Anti-Mev" value={defaultValues.privateTransaction} />

                        <SettingField
                          title="Wallets Selected"
                          value={mockData.buy.walletsSelected}
                        />

                        <SettingField
                          title="Approve Priority"
                          value={defaultValues.approvePriority || mockData.buy.approvePriority}
                        />
                      </Grid>

                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField title="Degen Chad Mode" value={defaultValues.degenChadMode} />

                        <SettingField
                          title="Buy Priority"
                          value={defaultValues.buyPriority || mockData.buy.buyPriority}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container flexDirection="column" gap={1.5}>
                    <Typography variant="body2" textColor="white">
                      Safety
                    </Typography>

                    <Grid container gap={3}>
                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Anti-Rug"
                          value={defaultValues.antiRug || mockData.safety.antiRug}
                        />

                        <SettingField
                          title="Anti-Rug Threshold"
                          value={defaultValues.antiRugThreshold || mockData.safety.antiRugThreshold}
                        />

                        <SettingField
                          title="Sell Rug Extra Tip"
                          value={defaultValues.sellRugExtraTip || mockData.safety.sellRugExtraTip}
                        />

                        <SettingField
                          title="Max Buy Tax"
                          value={defaultValues.maxBuyTax || mockData.safety.maxBuyTax}
                        />

                        <SettingField
                          title="Min Liquidity"
                          value={defaultValues.minLiquidity || mockData.safety.minLiquidity}
                        />
                      </Grid>

                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField title="Anti-Blacklist" value={defaultValues.antiBlacklist} />

                        <SettingField
                          title="Transfer On Blacklist"
                          value={defaultValues.transferOnBlacklist}
                        />

                        <SettingField
                          title="Max Marketcap"
                          value={defaultValues.maxMarketcap || mockData.safety.maxMcap}
                        />

                        <SettingField
                          title="Max Sell Tax"
                          value={defaultValues.maxSellTax || mockData.safety.maxSellTax}
                        />

                        <SettingField
                          title="Max Liquidity"
                          value={defaultValues.maxLiquidity || mockData.safety.maxLiquidity}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container flexDirection="column" gap={1.5}>
                    <Typography variant="body2" textColor="white">
                      Sell
                    </Typography>

                    <Grid container gap={3}>
                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Sell Priority"
                          value={defaultValues.sellPriority || mockData.sell.sellPriority}
                        />

                        <SettingField title="Sell on Profit" value={mockData.sell.sellOnProfit} />

                        <SettingField
                          title="Wallets to sell on Profit"
                          value={
                            defaultValues.walletsToSellOnProfit ||
                            mockData.sell.walletsToSellOnProfit
                          }
                        />

                        <SettingField title="Sell in steps" value={mockData.sell.sellInSteps} />
                      </Grid>

                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Auto Sell"
                          value={defaultValues.autoSell || mockData.sell.autoSell}
                        />

                        <SettingField
                          title="Sell Profit Amount"
                          value={mockData.sell.sellProfitAmount}
                        />

                        <SettingField title="Trailing Sell" value={mockData.sell.trailingSell} />

                        <SettingField title="Sell in steps:" value={''} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container columnGap={3} flexWrap="nowrap">
              <Button styleVariant="black" onClick={handleChangeMainTab} disabled={isLastMainTab}>
                Continue
              </Button>

              <Button disabled={!isLastMainTab}>
                {action === 'add-copytrade' ? 'Add' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  )
}

export { CopyTradeSettings }
