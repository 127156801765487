import { FC } from 'react'

import { Typography } from '@/libs/common'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'

import styles from './styles.module.scss'

type TProperty = {
  userName?: string
}

const UserBlock: FC<TProperty> = ({ userName }) => {
  const addressLength = 42

  return (
    <div className={styles.user}>
      <Typography variant="body2" className={styles.userSymbol} title={userName}>
        {userName && userName.length === addressLength ? hideWalletAddress(userName) : userName}
      </Typography>
    </div>
  )
}

export { UserBlock }
