import { FC } from 'react'

import styles from './styles.module.scss'

type TProperty = {
  href: string
  label: string
}

const Link: FC<TProperty> = ({ href, label }) => {
  return (
    <a href={href} className={styles.link}>
      {label}
    </a>
  )
}

export { Link }
