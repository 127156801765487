const convertScientificNotationNumber = (
  value: number | string,
  trim = 32,
  isOnChange?: boolean,
) => {
  if (isOnChange && typeof value === 'string' && value.endsWith('.')) return value

  const decimalsPart = value?.toString()?.split('.')?.[1] || ''
  const eDecimals = Number(decimalsPart?.split('e-')?.[1]) || 0
  const countOfDecimals = decimalsPart.length + eDecimals
  const defaultFix = +value !== 0 && +value < 1 ? trim : 0

  if (!countOfDecimals) {
    return value.toString()
  }

  return Number(value).toFixed(countOfDecimals ? Math.min(countOfDecimals, trim) : defaultFix)
}

export { convertScientificNotationNumber }
