import { FC } from 'react'

import cls from 'classnames'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProperty = {
  label: string
  value?: string | number | boolean
  children?: React.ReactNode
}

const TemplateSettingsElement: FC<TProperty> = ({ label, value, children }) => {
  let currentValue = value
  const isBoolean = typeof value === 'boolean'

  if (isBoolean) {
    currentValue = value ? 'ON' : 'OFF'
  }
  return (
    <div className={styles.container}>
      <Typography variant="body2">{label}</Typography>
      {(value || (!value && isBoolean)) && (
        <Typography
          variant="body2"
          textColor="light-grey"
          className={cls({ [styles.green]: isBoolean && value, [styles.red]: isBoolean && !value })}
        >
          {currentValue}
        </Typography>
      )}
      {children && <div>{children}</div>}
    </div>
  )
}

export { TemplateSettingsElement }
