const stringsToNumbersInObject = <T extends Record<string, any>>(
  obj: T,
  defaultNanValue?: string | number,
) => {
  const res: T = {} as T
  Object.keys(obj).forEach((key) => {
    let value = (obj as any)[key]
    if (typeof value === 'string') {
      const newValue = +value
      if (isNaN(newValue) && defaultNanValue !== undefined) {
        value = defaultNanValue
      } else {
        value = newValue
      }
    }
    ;(res as any)[key] = value
  })
  return res
}

export { stringsToNumbersInObject }
