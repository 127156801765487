import { useEffect, useState } from 'react'

import { TTokenTransactionFiltered, TTokenTransactionFilteredFromServer } from '@/api/tokens/types'
import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { EBackendResponseStatus } from '@/libs/enums'
import { EWebsocketStatus } from '@/libs/enums/websocket-status.enum'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TSocketResponse } from '@/libs/types/backend-response.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import {
  setMyTransactionsFromWebSocket,
  setTransactions,
  setTransactionsFromWebSocket,
} from '@/store/slices/chain.slice'

const txSniperSocket = new SniperSockerService()

type TSubscribedToInfo = {
  s: string
  q: string
}

const useWatchTransactions = () => {
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const transactions = useAppSelector((state) => state.chain.transactions)
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)

  const [subscribedTo, setSubscribedTo] = useState<TSubscribedToInfo | null>(null)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      txSniperSocket.getStatus() !== EWebsocketStatus.OPEN ||
      !currentToken ||
      !subscribedTo ||
      currentToken.pair.address === subscribedTo.s ||
      isAppIdle
    ) {
      return
    }

    txSniperSocket.emit(JSON.stringify({ u: subscribedTo.s, q: subscribedTo.q }))
    if (transactions && selectedTokenAddress && transactions.token !== selectedTokenAddress) {
      dispatch(setTransactions(null))
    }
    const newPayload = { s: currentToken.pair.address, q: currentToken.info?.quote_token }
    txSniperSocket.emit(JSON.stringify(newPayload))
    setSubscribedTo(newPayload)
  }, [currentToken, currentChain, isAppIdle])

  useSniperWebsocket({
    socket: txSniperSocket,
    connectionProps: {
      endpoint: 'token/stream/transactions',
      query: createQueryString({ b: currentChain.indexerChainId, l: 'en' }),
      isPublic: true,
      onOpen: () => {
        const newPayload = {
          s: currentToken?.pair.address || '',
          q: currentToken?.info?.quote_token || '',
        }
        txSniperSocket.emit(JSON.stringify(newPayload))
        setSubscribedTo(newPayload)
      },
    },
    verifyExistanceBeforeConnect: [currentToken],
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSocketResponse<
        TTokenTransactionFilteredFromServer[] | string
      >
      if (typeof data.data === 'string' || data.status !== EBackendResponseStatus.SUCCESS) {
        return
      }
      const myMainWallet = mainWallet?.address.toLowerCase()
      const newData = data.data?.reduce(
        (acc, item) => {
          const currentTx = {
            block_number: item.bn,
            event_type: item.et,
            maker: item.m,
            timestamp: item.t,
            token_swap_value_usd: item.tswu,
            price_base_token_total: item.d.pb,
            price_usd_total: item.d.pu,
            amount_non_liquidity_token: item.d.an,
            transaction_hash: item.th,
            data: {
              token0: item.d.t0,
              token1: item.d.t1,
            },
          }
          acc.allTx.push(currentTx)
          if (currentTx.maker.toLowerCase() === myMainWallet) {
            acc.myTx.push(currentTx)
          }
          return acc
        },
        { allTx: [], myTx: [] } as {
          allTx: TTokenTransactionFiltered[]
          myTx: TTokenTransactionFiltered[]
        },
      )
      dispatch(setTransactionsFromWebSocket(newData.allTx))
      if (newData.myTx.length) dispatch(setMyTransactionsFromWebSocket(newData.myTx))
    },
  })
}

export { useWatchTransactions }
