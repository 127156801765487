const createQueryString = (obj: Record<string, string | number | boolean | undefined | null>) => {
  const keyValuePaires: string[] = []

  Object.keys(obj).forEach((key: string) => {
    if (obj[key] || obj[key] === 0) {
      keyValuePaires.push(`${key}=${obj[key]}`)
    }
  })

  if (!keyValuePaires.length) return ''

  return `?${keyValuePaires.join('&')}`
}

export { createQueryString }
