import {
  ActionReducerMapBuilder,
  AsyncThunkPayloadCreator,
  CaseReducer,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'

// TODO: Add proper types
type TApplyReducersProps<TState> = {
  onPending?: CaseReducer<TState, any>
  onFulfilled?: CaseReducer<TState, any>
  onRejected?: CaseReducer<TState, any>
}

const MAX_FAILED_REQUESTS = 5

const pendingRequests = new Set<string>()
const failedRequests = new Map<string, number>()

const createAsyncThunkWithControll = <TData, TParams>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<TData, TParams, AsyncThunkConfig>,
) => {
  // Thunk with the controller
  const thunk = createAsyncThunk(typePrefix, payloadCreator, {
    condition: () => {
      if (
        // If failed requests exceed limit
        (failedRequests.has(typePrefix) &&
          failedRequests.get(typePrefix)! >= MAX_FAILED_REQUESTS) ||
        // If the same request is pending
        pendingRequests.has(typePrefix)
      ) {
        return false
      }
      return true
    },
  })

  // Thunk without the controller
  const thunkForce = createAsyncThunk(`${typePrefix}/force`, payloadCreator)

  const applyExtraReducers = <TState>(
    builder: ActionReducerMapBuilder<TState>,
    options?: TApplyReducersProps<TState>,
  ) => {
    const { onPending, onFulfilled, onRejected } = options || {}

    builder.addCase(thunk.pending, (state, action) => {
      pendingRequests.add(typePrefix)
      onPending?.(state, action)
    })
    builder.addCase(thunk.fulfilled, (state, action) => {
      pendingRequests.delete(typePrefix)
      failedRequests.delete(typePrefix)
      onFulfilled?.(state, action)
    })
    builder.addCase(thunk.rejected, (state, action) => {
      pendingRequests.delete(typePrefix)
      failedRequests.set(typePrefix, (failedRequests.get(typePrefix) || 0) + 1)
      onRejected?.(state, action)
    })

    builder.addCase(thunkForce.pending, (state, action) => {
      onPending?.(state, action)
    })
    builder.addCase(thunkForce.fulfilled, (state, action) => {
      onFulfilled?.(state, action)
    })
    builder.addCase(thunkForce.rejected, (state, action) => {
      onRejected?.(state, action)
    })
  }

  return {
    thunk,
    thunkForce,
    applyExtraReducers,
  }
}

export { createAsyncThunkWithControll }
