import { LocalStorageItem } from '@/libs/enums'
import { TPrioritiesDefaultValues } from '@/libs/types'

export const getPrioritiesDefault = () => {
  const priorities = localStorage.getItem(LocalStorageItem.PRIORITIES)
  return priorities
    ? (JSON.parse(priorities) as TPrioritiesDefaultValues)
    : {
        sellPriority: '7',
        buyPriority: '7',
        approvePriority: '7',
      }
}
