import { FC } from 'react'

import defaultToken from '@/assets/icons/default-token.svg'

type TProps = {
  size?: string
}

const TokenPlaceholder: FC<TProps> = ({ size }) => {
  return (
    <div>
      <img
        src={defaultToken}
        style={{
          width: !size ? '24px' : size,
          height: !size ? '24px' : size,
          borderRadius: '50%',
          background: 'white',
        }}
        alt={'token logo'}
      />
    </div>
  )
}

export { TokenPlaceholder }
