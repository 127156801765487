import { FC, startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ChartPropertiesOverrides,
  ChartingLibraryFeatureset,
  EntityId,
  IChartingLibraryWidget,
  ResolutionString,
  widget,
} from '@/charting_library'
import { SpinnerSize } from '@/libs/enums'
import { TokesSearchEvent } from '@/libs/enums/tokes-search-event.enum'
import { debounce } from '@/libs/helper'
import { getClosestBarByInterval } from '@/libs/helper/getClosestBarByInterval'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchOrdersHistory } from '@/store/slices/order.slice'

import { Spinner } from '../spinner'
import { CUSTOM_INTERVALS, SHORT_INTERVALS } from './libs/configs'
import { convertToCustomInterval, formatMCap, formatPrice } from './libs/helpers'
import { Datafeed } from './libs/utils/datafeed'
import styles from './styles.module.scss'

type TProps = {
  componentWidth: number
}

type TInitChartProps = {
  isPriceSelected?: boolean
  isCurrencyPriceSelected?: boolean
  isShowUserTrades?: boolean
}

const TradingViewNew: FC<TProps> = ({ componentWidth }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)
  const ordersHistory = useAppSelector((state) => state.orders.ordersHistory)
  const userData = useAppSelector((state) => state.user.userData)
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)

  const dispatch = useAppDispatch()

  const setNewResolution = (newRes: string) => {
    localStorage.lastChartResolution = newRes
  }

  const getResolution = () => {
    let res = localStorage.lastChartResolution
    if (!res) {
      res = '1' // Default resolution
      setNewResolution(res)
    }
    return res
  }

  const [subscribedToToken, setSubscribedToToken] = useState<string | null>(null)
  const [isChartIdle, setIsChartIdle] = useState(false)
  const [isChartReady, setIsChartReady] = useState(false)
  const [, setChartShapesIds] = useState<EntityId[]>([])
  const [lastChartProps, setLastChartProps] = useState<TInitChartProps>({
    isPriceSelected: true,
    isCurrencyPriceSelected: false,
    isShowUserTrades: false,
  })

  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const tvWidgetRef = useRef<IChartingLibraryWidget | null>(null)

  const onBarsUpdate = () => {
    if (lastChartProps.isShowUserTrades && tvWidgetRef.current) {
      showMyTradesDebounced(tvWidgetRef.current)
    }
  }

  const datafeed = useMemo(() => new Datafeed({ onBarsUpdate }), [])

  const tokenOrdersHistory = useMemo(() => {
    if (lastChartProps.isShowUserTrades && tvWidgetRef.current) {
      showMyTradesDebounced(tvWidgetRef.current)
    }
    return ordersHistory?.filter((order) => order.token_address == currentToken?.info?.address)
  }, [ordersHistory?.length, currentToken?.info?.address])

  const getTokenSymbol = useCallback(() => {
    const secondaryToken =
      currentToken?.token?.address !== currentToken?.pair?.address
        ? currentToken?.pair
        : currentToken?.token

    return `${currentToken?.token?.symbol}/${secondaryToken?.symbol}`
  }, [currentToken])

  const clearChartShapes = () => {
    if (!tvWidgetRef.current) return
    try {
      const chart = tvWidgetRef.current.chart()
      setChartShapesIds((prev) => {
        for (const shapeId of prev) {
          chart.removeEntity(shapeId)
        }

        return []
      })
    } catch (err: unknown) {
      // console.log('err', err)
    }
  }

  const hideUserTrades = () => {
    clearChartShapes()
    setLastChartProps((prev) => ({ ...prev, isShowUserTrades: false }))
  }

  const showMyTradesDebounced = useCallback(
    debounce((tvWidget = tvWidgetRef.current) => {
      if (tvWidget) showUserTrades(tvWidget)
    }, 200),
    [tvWidgetRef, tokenOrdersHistory?.length],
  )

  const showUserTrades = (tvWidget: IChartingLibraryWidget) => {
    const bars = datafeed.currentBars
    const currentInterval = getResolution()
    const isSmallResolution = SHORT_INTERVALS.includes(currentInterval)
    if (!lastChartProps.isShowUserTrades) {
      setLastChartProps((prev) => ({ ...prev, isShowUserTrades: true }))
    }
    clearChartShapes()
    if (!tokenOrdersHistory?.length || !bars.length || isSmallResolution) {
      return
    }

    const shapeIds = []
    for (const order of tokenOrdersHistory) {
      const orderTimeStamp = new Date(order.date).getTime()
      const actualBars = CUSTOM_INTERVALS.includes(currentInterval)
        ? convertToCustomInterval(bars, currentInterval)
        : bars

      const orderBar = getClosestBarByInterval(actualBars, orderTimeStamp, currentInterval)
      if (!orderBar) {
        continue
      }

      const isSellOrderType = order.type.toLowerCase() == TokesSearchEvent.SELL
      const shapeSpacingGap = (orderBar.high - orderBar.low) / 10
      const price = isSellOrderType ? orderBar.high : orderBar.low
      const timeInSeconds = orderTimeStamp / 1000

      const priceWithGap = isSellOrderType ? price + shapeSpacingGap : price - shapeSpacingGap
      try {
        const shapeId = tvWidget.activeChart().createShape(
          {
            time: timeInSeconds,
            price: priceWithGap,
          },
          {
            shape: isSellOrderType ? 'arrow_down' : 'arrow_up',
            lock: true,
            disableSelection: false,
            overrides: {
              text: isSellOrderType ? 'S' : 'B',
              size: 1,
              textPosition: 'center',
              markerColor: '#1976d2',
            },
          },
        )

        shapeId && shapeIds.push(shapeId)
      } catch (err) {
        break
      }
    }
    setChartShapesIds(shapeIds)
  }

  const initializeChart = async (props = lastChartProps) => {
    hideUserTrades()
    setIsChartReady(false)
    setLastChartProps(props)

    try {
      datafeed.connectToWebsocket()
    } catch (err) {
      setTimeout(() => initializeChart(props), 2000)
      return
    }

    tvWidgetRef.current?.remove()

    const { isPriceSelected = true, isCurrencyPriceSelected = false, isShowUserTrades } = props

    const disabledFeatures = [
      'header_symbol_search',
      'header_compare',
      'header_saveload',
      'popup_hints',
    ]

    if (componentWidth <= 600) {
      disabledFeatures.push('legend_widget')
    }

    const overrides = {
      'paneProperties.backgroundType': 'solid',
      'paneProperties.background': '#101010',
      'paneProperties.backgroundGradientStartColor': '#101010',
      'paneProperties.backgroundGradientEndColor': '#101010',
    } as ChartPropertiesOverrides

    const tvWidget = new widget({
      symbol: getTokenSymbol(),
      interval: getResolution() as ResolutionString,
      fullscreen: false,
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      theme: 'dark',
      locale: 'en',
      disabled_features: disabledFeatures as ChartingLibraryFeatureset[],
      enabled_features: [
        'adaptive_logo',
        'side_toolbar_in_fullscreen_mode',
        'seconds_resolution',
        'show_spread_operators',
        'context_menus',
      ],
      drawings_access: {
        type: 'black',
        tools: [{ name: 'Regression Trend' }],
      },
      studies_access: {
        type: 'black',
        tools: [{ name: 'HighLow' }],
      },
      charts_storage_url: 'https://saveload.tradingview.com',
      charts_storage_api_version: '1.1',
      client_id: 'tradingview.com',
      user_id: userData?.user_id || 'unknown',
      autosize: true,
      studies_overrides: {},
      auto_save_delay: 2,
      custom_formatters: {
        priceFormatterFactory: () => {
          return {
            format: (price: number) => {
              const currentPrice = isCurrencyPriceSelected
                ? price / currentChain.nativeTokenPriceInUsd!
                : price
              return isPriceSelected
                ? formatPrice(currentPrice)
                : formatMCap(currentPrice, simulation ? +simulation.t.ts : 0)
            },
          }
        },
      },
      overrides,
      loading_screen: {
        backgroundColor: '#101010',
      },
      toolbar_bg: '#101010',
      custom_css_url: '/styles.css',
      datafeed,
    })

    tvWidget.onChartReady(() => {
      tvWidget.applyOverrides(overrides)
      setIsChartReady(true)
      const toChainCurrencyButton = tvWidget.createButton()
      const MCapButton = tvWidget.createButton()
      const showTradesButton = tvWidget.createButton()

      const blue = '#2962ff'

      let isShowTrades = isShowUserTrades
      tvWidget.headerReady().then(() => {
        // USD/ETH button
        toChainCurrencyButton.setAttribute('title', `Switch to ${currentChain.description}`)
        toChainCurrencyButton.addEventListener('click', () => {
          initializeChart({
            ...props,
            isShowUserTrades: isShowTrades,
            isCurrencyPriceSelected: !isCurrencyPriceSelected,
          })
        })
        toChainCurrencyButton.innerHTML = `<span style="cursor: pointer;">Switch to ${!isCurrencyPriceSelected ? currentChain.description : 'USD'}</span>`

        // Price/Mcap button
        MCapButton.setAttribute('title', `Switch to ${isPriceSelected ? 'MCap' : 'Price'} chart`)
        MCapButton.addEventListener('click', () => {
          initializeChart({
            ...props,
            isShowUserTrades: isShowTrades,
            isPriceSelected: !isPriceSelected,
          })
        })
        MCapButton.innerHTML = `<div style="cursor: pointer;"><span style="color: ${isPriceSelected && blue};">Price</span> / <span style="color: ${!isPriceSelected && blue};">MCap</span></div>`

        // My Trades button
        showTradesButton.setAttribute('title', `Show my Trades`)
        showTradesButton.addEventListener('click', (e) => {
          const eventTarget = e.target as HTMLButtonElement
          if (!eventTarget) return

          isShowTrades = !isShowTrades
          tvWidgetRef.current = tvWidget
          eventTarget.style.color = isShowTrades ? blue : 'initial'
          if (isShowTrades) {
            showUserTrades(tvWidget)
          } else {
            hideUserTrades()
          }
        })
        showTradesButton.innerHTML = `<div style="cursor: pointer;"><span style="color: ${isShowUserTrades && blue}">Show my Trades</span></div>`
      })

      tvWidget
        .activeChart()
        .onIntervalChanged()
        .subscribe(null, (interval) => {
          setNewResolution(interval)
          startTransition(() => {
            showTradesButton.innerHTML = `<div style="cursor: pointer;"><span style="color: ${isShowTrades && blue}">Show my Trades</span></div>`
          })
        })

      tvWidget
        .activeChart()
        .onVisibleRangeChanged()
        .subscribe(null, () => {
          if (isShowTrades) {
            showMyTradesDebounced(tvWidget)
          }
        })

      if (isShowUserTrades) {
        showUserTrades(tvWidget)
      }
    })

    tvWidgetRef.current = tvWidget
  }

  // Chart initialisation
  useEffect(() => {
    if (!currentToken || tvWidgetRef.current) return

    setSubscribedToToken(currentToken.token.address)

    initializeChart()
  }, [currentToken])

  // Handle app idle state
  useEffect(() => {
    if (isAppIdle === isChartIdle) {
      return
    }

    if (isAppIdle) {
      datafeed.removeDatafeed()
    } else {
      initializeChart()
    }

    setIsChartIdle(isAppIdle)
  }, [isAppIdle])

  // Handle token change
  useEffect(() => {
    if (
      !tvWidgetRef.current ||
      !subscribedToToken ||
      !currentToken ||
      currentToken.token.address === subscribedToToken
    ) {
      return
    }

    setSubscribedToToken(currentToken.token.address)
    tvWidgetRef.current.setSymbol(
      getTokenSymbol(),
      tvWidgetRef.current.symbolInterval().interval,
      () => {
        console.log('Empty callback')
      },
    )
  }, [currentToken])

  // Handle token data loading
  useEffect(() => {
    if (selectedTokenAddress === currentToken?.token.address || !tvWidgetRef.current) {
      return
    }

    setChartShapesIds([])
    setSubscribedToToken(null)
    tvWidgetRef.current.remove()
    tvWidgetRef.current = null
  }, [selectedTokenAddress])

  // Remove chart when the component unmounts
  useEffect(() => {
    // TODO: Use websocket data for the history
    dispatch(fetchOrdersHistory()).unwrap()

    return () => {
      datafeed.removeDatafeed()
      tvWidgetRef.current?.remove()
    }
  }, [])

  if (!currentToken) {
    return <div className={styles.container}>No token</div>
  }

  return (
    <div className={styles.container}>
      <div ref={chartContainerRef} className={styles.chartContainer} />
      {((selectedTokenAddress && selectedTokenAddress !== currentToken.token.address) ||
        !isChartReady) && (
        <div className={styles.loaderContainer}>
          <Spinner size={SpinnerSize.MEDIUM} centered />
        </div>
      )}
    </div>
  )
}

export { TradingViewNew }
