const WalletsQuantityButton = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  CUSTOM: 'Custom',
}

export { WalletsQuantityButton }
