import { api } from '@/api'
import { ETemplateType } from '@/libs/enums'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'
import { TBackendResponse } from '@/libs/types/backend-response.type'
import { TBuyTemplate, TBuyTemplatePayload } from '@/libs/types/template'
import { store } from '@/store'

const BASE_URL = 'api/v1/user/template'

const createBuyTemplate = (body: TBuyTemplatePayload) => {
  const userId = getCurrentUserId()
  return api.post<TBackendResponse<TBuyTemplate[]>>(`${BASE_URL}/${userId}/create?l=en`, body)
}

const updateBuyTemplate = (id: string, templatePayload: Partial<TBuyTemplatePayload>) => {
  const userId = getCurrentUserId()
  const { template, ...rest } = templatePayload
  let { blockchain } = templatePayload
  if (!blockchain) {
    blockchain = store.getState().chain.currentChain.indexerChainId
  }
  const body = { type: ETemplateType.MANUAL_BUY, ...rest, ...template, id, blockchain }
  return api.post<TBackendResponse<TBuyTemplate[]>>(`${BASE_URL}/${userId}/update?l=en`, body)
}

const deleteBuyTemplate = (id: string, blockchain?: number) => {
  const userId = getCurrentUserId()
  if (!blockchain) {
    blockchain = store.getState().chain.currentChain.indexerChainId
  }
  return api.post<TBackendResponse<TBuyTemplate[]>>(`${BASE_URL}/${userId}/delete?l=en`, {
    id,
    blockchain,
    type: ETemplateType.MANUAL_BUY,
  })
}

const getUserBuyTemplates = () => {
  const userId = getCurrentUserId()
  return api.get<TBackendResponse<TBuyTemplate[]>>(
    `${BASE_URL}/${userId}/get?l=en&t=${ETemplateType.MANUAL_BUY}&b=1`,
  )
}

// TODO: Refactor this according to the Postman
const changeUserBuyTemplatesOrder = async (updatedTemplateOrder: { id: string; order: number }[]) =>
  api.patch<TBuyTemplate[]>(`${BASE_URL}/update-order/buy`, updatedTemplateOrder)

export {
  createBuyTemplate,
  updateBuyTemplate,
  deleteBuyTemplate,
  getUserBuyTemplates,
  changeUserBuyTemplatesOrder,
}
