import { FC } from 'react'

import { Icon } from '@/libs/common/icon'
import { Tooltip } from '@/libs/common/tooltip/index'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = {
  info: string
  marginLeft?: number
  marginRight?: number
}

const TooltipIcon: FC<TProps> = ({ marginLeft = 0, marginRight = 0, info }) => {
  return (
    <Tooltip title={info} withIcon={false}>
      <div className={styles.infoIcon} style={{ marginLeft, marginRight }}>
        <Icon name={IconName.INFO_ICON} />
      </div>
    </Tooltip>
  )
}

export { TooltipIcon }
