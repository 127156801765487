import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { SelectFees } from '@/components/select-fees'
import { SelectWallet } from '@/components/select-wallet'
import { useAppMode } from '@/hooks/useAppMode'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { Button, InputWithRadioGroup, Typography } from '@/libs/common'
import { AmountDropdown } from '@/libs/common/input/components/amount-dropdown'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppRoute } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { TFees } from '@/libs/types'
import { TSelectOption } from '@/libs/types/select-option.type'
import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const spendOptions: TSelectOption<string>[] = [
  {
    value: '50%',
    label: '50%',
  },
  {
    value: '75%',
    label: '75%',
  },
  {
    value: '100%',
    label: '100%',
  },
]

const defaultValues = {
  amount: '',
  wallets: [],
}

const CollectMulti: FC = () => {
  const { mode } = useAppMode()
  const navigate = useNavigate()
  const currentWallet = useWalletFromParams()

  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const userHoldings = useAppSelector((state) => state.user.userHoldingsWebsocket)

  const [fees, setFees] = useState<TFees>()
  const [wallets, setWallets] = useState<string[]>([])
  const [isDisabled, setIsDisable] = useState(true)
  const [holding, setHolding] = useState<THoldingsToken | null>(null)

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({
      title: `Collect multi to ${currentWallet?.name}`,
      titleProps: { className: styles.mainTitle },
      withBackButton: true,
    })
  }, [currentWallet])

  const schema = yup.object({
    amount: stringOfNumbersValidation.required().test((value) => value.includes('%') || +value > 0),
    address: yup.string(),
    wallets: yup.array().of(yup.string()).required().min(1),
  })

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  })

  const amount = watch('amount')

  const availableWallets = useMemo(() => {
    if (!holding) {
      return userWallets?.filter(
        (el) =>
          el.address !== currentWallet?.address &&
          +(el.balanceFormatted || 0) > (amount.includes('%') ? 0 : +amount),
      )
    }
    const walletsWithHolding = userWallets?.map((el) => {
      const holdingData = holding?.w.find((wallet) => wallet.a === el.address)
      return {
        ...el,
        holdingData,
      }
    })
    return walletsWithHolding?.filter(
      (el) =>
        el.address !== currentWallet?.address &&
        el.holdingData &&
        +(el.holdingData.balanceFormatted || 0) > (amount.includes('%') ? 0 : +amount),
    )
  }, [userWallets, holding, amount])

  useEffect(() => {
    const walletsWithValidBalance = wallets.filter((el) =>
      availableWallets?.find((available) => available.address === el),
    )
    setWallets(walletsWithValidBalance)
  }, [availableWallets])

  useEffect(() => {
    setIsDisable(!wallets.length || !fees || !isValid || !fees.minerTip)
  }, [fees, isValid, wallets, amount])

  const calculateWorth = (amount: string) => {
    const percentage = +amount.replace(/%/g, '')
    return (percentage * (holding ? +holding.tvu : +(userHoldings?.v.tvu || 0))) / 100
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    if (!fees) return

    const currentAmount = data.amount.includes('%') ? '0' : data.amount
    const percentage = data.amount.includes('%') ? +data.amount.replace(/%/g, '') : 0

    const payload = {
      type: 'collect',
      token: holding || null,
      amount: currentAmount,
      wallets: wallets,
      minerTip: fees.minerTip,
      percentage,
    }

    navigate(
      `${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.CONFIRM_TRANSFER}/${currentWallet?.address}`,
      {
        state: { payload },
      },
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography variant="body2" align="center">
          {holding ? (
            <>
              ~{formatNumber(holding?.balanceFormatted || 0).formatted} {holding.s}
              ($
              {(+(holding.tvu || '0')).toFixed(2)})
            </>
          ) : (
            <>
              ~{formatNumber(userHoldings?.v.tvcfw || 0).formatted} ETH ($
              {formatNumber(userHoldings?.v.tvu || 0).formatted})
            </>
          )}
        </Typography>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.content}>
        <div className={styles.content}>
          <div className={styles.inputWrapper}>
            <Controller
              name="amount"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => {
                return (
                  <InputWithRadioGroup
                    label="Amount"
                    placeholder="Enter amount to transfer"
                    isNumeric
                    percentage
                    className={styles.input}
                    withCustomInput
                    onOptionSelect={(value) => {
                      onChange(value)
                      trigger('amount')
                    }}
                    endAdornment={
                      <AmountDropdown
                        isGeneral
                        onTokenSelect={(value) => {
                          setHolding(value)
                        }}
                      />
                    }
                    radioGroupName="amount"
                    options={spendOptions}
                    onChange={(e) => {
                      if (!currentWallet || !currentChain.nativeTokenPriceInUsd) return
                      const newValue = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      onChange(newValue + '%')
                    }}
                    error={!!errors.amount?.message}
                    {...field}
                  />
                )
              }}
            />
            <Typography variant="body2">${calculateWorth(amount).toFixed(2)}</Typography>
          </div>
          <Controller
            name="wallets"
            control={control}
            render={({ field: { ref, value, onChange, ...field } }) => (
              <SelectWallet
                tokenSymbol={holding?.s}
                wallet={wallets}
                wallets={availableWallets}
                onChange={(value) => {
                  setWallets(value)
                  onChange(value)
                }}
                isMulti={true}
                {...field}
              />
            )}
          />
          <SelectFees fees={fees} onChange={(value) => setFees(value)} />
        </div>
        <Button type="submit" disabled={isDisabled} className={styles.button} checkOnAccountLock>
          Continue
        </Button>
      </form>
    </div>
  )
}

export { CollectMulti }
