import { FC } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import cls from 'classnames'

import styles from './styles.module.scss'

type TButtonLinkProps = Omit<LinkProps, 'to'> & {
  to?: string
}

const ButtonLink: FC<TButtonLinkProps> = ({ className, to = '/', children, ...props }) => {
  return (
    // @TODO replacee Link with ButtonIcon with tooltip here and use useNavigate onClick
    // to achieve consistensy of tooltip functionality
    <Link {...props} to={to} className={cls(styles.link, className)}>
      {children}
    </Link>
  )
}

export { ButtonLink, type TButtonLinkProps }
