import { FC } from 'react'

import MUIDivider, { DividerProps as MUIDividerProps } from '@mui/material/Divider'
import cn from 'classnames'

import styles from './styles.module.scss'

type TDividerProps = MUIDividerProps & {
  color?: 'default' | 'dashboard-border'
}

const Divider: FC<TDividerProps> = ({ children, color = 'default', className, ...props }) => {
  return (
    <MUIDivider classes={styles} className={cn(styles[color], className)} {...props}>
      {children}
    </MUIDivider>
  )
}

export { Divider }
