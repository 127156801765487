import { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { ProfitSellTabCard } from '@/components/buy-sell-snipe-profit-sell'
import { useAppMode } from '@/hooks/useAppMode'
import { useSorting } from '@/hooks/useSorting'
import { Button, Typography } from '@/libs/common'
import { SearchTextfield } from '@/libs/common/input-search/libs'
import { AppRoute, IconName, Sorting } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  usedInModal?: boolean
}

const PositionsSummariesPage: FC<TProps> = ({ usedInModal }) => {
  const positions = useAppSelector((state) => state.profitSell.profitSell?.ap)

  const [search, setSearch] = useState('')

  const { SortIcon, sorting } = useSorting()

  const { mode } = useAppMode()

  const availablePositions = useMemo(
    () =>
      positions
        ?.filter((el) => {
          let filterConstraint = el.v && !!el.tb
          if (search) {
            const keyword = search.replace(/(^\s+|\s+$)/g, '').toLowerCase()

            filterConstraint =
              filterConstraint &&
              (el.tn.toLowerCase().includes(keyword) || el.ta.toLowerCase().includes(keyword))
          }
          return filterConstraint
        })
        .sort((a, b) => (sorting === Sorting.ASC ? +a.tw.u - +b.tw.u : +b.tw.u - +a.tw.u)),
    [positions, search, sorting],
  )

  const navigate = useNavigate()

  const addNew = useCallback(() => {
    navigate(`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.PROFIT_SELL_ADD_TOKEN}`)
  }, [navigate])

  return (
    <>
      <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Grid display="flex" alignItems="center" gap={1}>
          <Typography variant="body2" textColor="light-grey">
            Select position
          </Typography>
          <SortIcon />
        </Grid>
        <Button
          className={styles.button}
          styleVariant="borderless-gold"
          iconStartName={IconName.ADD_CIRCLE}
          onClick={addNew}
          transparent
        >
          Add new
        </Button>
      </Grid>
      <SearchTextfield
        value={search}
        onChange={(event) => {
          setSearch(event.currentTarget.value)
        }}
        variant="standard"
        className={styles.search}
        placeholder="Enter token name or address"
      />

      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap={1.5}
        className={cn(styles.cardsContainer, { [styles.usedInModal]: usedInModal })}
      >
        {availablePositions?.map((position) => (
          <ProfitSellTabCard key={position.id} position={position} />
        ))}
        {!availablePositions?.length && (
          <Typography variant="body1" textColor="grey" align="center">
            No positions available
          </Typography>
        )}
      </Grid>
    </>
  )
}

export { PositionsSummariesPage }
