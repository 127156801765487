import { Icon } from '@/libs/common'
import { ChainName, IconName } from '@/libs/enums'

const ChainNameToChainName = {
  [ChainName.ETHEREUM]: 'ETH',
  [ChainName.ARBITRUM]: 'Arbitrum Chain',
  [ChainName.BNB]: 'BNB Smart Chain',
  [ChainName.BASE]: 'Base Chain',
  [ChainName.AVAX]: 'Avax Chain',
}
const ChainNameToChainIcon = {
  [ChainName.ETHEREUM]: <Icon name={IconName.ETHEREUM} />,
  [ChainName.ARBITRUM]: <Icon name={IconName.ARBITRUM} />,
  [ChainName.BNB]: <Icon name={IconName.BNB} />,
  [ChainName.BASE]: <Icon name={IconName.BASE_CHAIN} />,
  [ChainName.AVAX]: <Icon name={IconName.AVAX_CHAIN} />,
}

export { ChainNameToChainIcon, ChainNameToChainName }
