import { FC } from 'react'

import MUIChip, { ChipProps as MUIChipProps } from '@mui/material/Chip'
import cn from 'classnames'

import styles from './styles.module.scss'

type TChipProps = MUIChipProps & {
  styleVariant?: 'purple' | 'green' | 'red' | 'grey' | 'grey2' | string
}

const Chip: FC<TChipProps> = ({ className, styleVariant = 'grey', ...props }) => {
  return (
    <MUIChip
      classes={styles}
      className={cn(styles.chipRoot, styles[styleVariant], className)}
      {...props}
    />
  )
}

export { Chip }
