import { FC } from 'react'

import cn from 'classnames'

import { AuthCard, TAuthCardProps } from '@/components/auth-card'

// import { Credentials } from './libs/components/credentials/credentials'
import styles from './styles.module.scss'

const SignUp: FC<TAuthCardProps> = ({ className, ...props }) => {
  return (
    <AuthCard className={cn(styles.signUpAuthCard, className)} {...props}>
      {/* <Credentials /> */}
    </AuthCard>
  )
}

export { SignUp }
