import { THoldingsToken } from '@/libs/types/sniper-holdings-socket-response.type'

// TODO: Check if balanceFormatted field is needed it is deprecated now
const formatBalanceForHoldings = (holdings: THoldingsToken[]) => {
  return holdings.map((token) => {
    return {
      ...token,
      // balanceFormatted: setDecimals(token.tb, +token.d),
      w: token.w.map((wallet) => {
        return {
          ...wallet,
          // balanceFormatted: setDecimals(token.tb, +token.d),
        }
      }),
    }
  })
}

export { formatBalanceForHoldings }
