import { FC } from 'react'

import Stack, { StackProps } from '@mui/material/Stack'
import cn from 'classnames'

import { Typography } from '@/libs/common/index'

// import { TabName } from '@/libs/enums'
// import { TTab } from '@/libs/types'
import { OrderHistoryTab } from './libs/components'
// import { SnipeTab } from './libs/components/snipe-tab'
import styles from './styles.module.scss'

const PendingOrders: FC<StackProps> = ({ className, ...props }) => {
  // const tabs: TTab[] = [
  //   {
  //     label: 'Active Snipes',
  //     content: <SnipeTab />,
  //   },
  //   {
  //     label: 'Active Limit orders',
  //     content: <LimitOrdersTab />,
  //   },
  //   {
  //     label: 'History',
  //     content: <OrderHistoryTab />,
  //   },
  // ]

  return (
    <Stack className={cn(styles.container, className)} spacing={2} useFlexGap {...props}>
      <Typography variant="h5" component="h2">
        Order History
      </Typography>
      {/* <Tabs name={TabName.PENDING_ORDERS} tabs={tabs} defaultValue={1} /> */}
      <OrderHistoryTab />
    </Stack>
  )
}

export { PendingOrders }
