import { FC } from 'react'

import MUISwitch, { SwitchProps } from '@mui/material/Switch'
import cn from 'classnames'

import styles from './styles.module.scss'

const Switch: FC<SwitchProps> = ({ className, ...props }) => {
  return <MUISwitch classes={styles} className={cn(className)} {...props} />
}

export { Switch }
