import { FC } from 'react'

import cn from 'classnames'

import { SpinnerSize } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = {
  absoluteCentered?: boolean
  size?: SpinnerSize | number
  centered?: boolean
}

const Spinner: FC<TProps> = ({ size = SpinnerSize.BIG, absoluteCentered = false, centered }) => {
  const sizeProps = { minWidth: size, minHeight: size, maxWidth: size, maxHeight: size }
  return centered ? (
    <div className={styles.spinnerWrapper}>
      <div>
        <div className={cn(styles.container)} style={sizeProps}>
          <div className={styles.loader} style={sizeProps}></div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={cn(styles.container, { [styles.absoluteCentered]: absoluteCentered })}
      style={sizeProps}
    >
      <div className={styles.loader} style={sizeProps}></div>
    </div>
  )
}

export { Spinner }
