function coinAge(creationTimestamp: number) {
  const currentDate = new Date()
  const creationDate = new Date(creationTimestamp * 1000)
  const timeDifference = currentDate.getTime() - creationDate.getTime()
  const millisecondsInDay = 1000 * 60 * 60 * 24
  const millisecondsInMonth = millisecondsInDay * 30.44
  const months = Math.floor(timeDifference / millisecondsInMonth)
  const days = Math.floor((timeDifference % millisecondsInMonth) / millisecondsInDay)

  return months + 'mo ' + days + 'd'
}

export { coinAge }
