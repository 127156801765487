import { FC, ReactNode } from 'react'

import { CardActionArea } from '@mui/material'
import cls from 'classnames'

import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'

import styles from './styles.module.scss'

type TProperty = {
  className?: string
  children: ReactNode
  address: string
  redirect?: boolean
}

const TokenRedirect: FC<TProperty> = ({ className, children, address, redirect }) => {
  const handleNewTokenSelection = useSelectNewToken()

  const handleChangeCurrentToken = () => {
    handleNewTokenSelection(address, { skipRedirect: !redirect })
  }

  return (
    <CardActionArea
      disableRipple
      component="div"
      className={cls(styles.cardArea, className)}
      onClick={handleChangeCurrentToken}
    >
      {children}
    </CardActionArea>
  )
}

export { TokenRedirect }
