import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'
import { TBackendResponse } from '@/libs/types/backend-response.type'

import { TUserFavorite } from './types'

const BASE_URL = 'api/v1/user/favorite'

const getUserFavorites = (networkId: number) => {
  const userId = getCurrentUserId()
  return api.get<TBackendResponse<TUserFavorite[]>>(`${BASE_URL}/${userId}/get?l=en&b=${networkId}`)
}

const addUserFavoriteToken = (token: string, blockchain: number) => {
  const userId = getCurrentUserId()
  return api.post<TBackendResponse<TUserFavorite[]>>(`${BASE_URL}/${userId}/create?l=en`, {
    blockchain,
    token,
  })
}

const deleteUserFavoriteToken = (token: string, blockchain: number) => {
  const userId = getCurrentUserId()
  return api.post<TBackendResponse<TUserFavorite[]>>(`${BASE_URL}/${userId}/delete?l=en`, {
    blockchain,
    token,
  })
}

const updateFavoritesTokensOrder = (newOrder: {
  blockchain: number
  token: string
  order: string
}) => {
  const userId = getCurrentUserId()
  return api.post(`${BASE_URL}/${userId}/update?l=en`, newOrder)
}

export {
  getUserFavorites,
  addUserFavoriteToken,
  deleteUserFavoriteToken,
  updateFavoritesTokensOrder,
}
