import { LocalStorageItem, THoldingsBalancesType } from '@/libs/enums'
import { ESorting } from '@/libs/enums/sorting.enum'
import { THoldingsSettings } from '@/libs/types'

const defaultSettings = {
  hideSmallBalances: false,
  sorting: ESorting.DESC,
  selectedBalancesType: THoldingsBalancesType.ALL,
}

const getHoldingsSettings = () => {
  const holdingsSettingsFromStorage = localStorage.getItem(LocalStorageItem.HOLDINGS_SETTINGS)

  let settings: THoldingsSettings
  if (holdingsSettingsFromStorage) {
    settings = { ...defaultSettings, ...JSON.parse(holdingsSettingsFromStorage) }
  } else {
    settings = defaultSettings
    localStorage.setItem(LocalStorageItem.HOLDINGS_SETTINGS, JSON.stringify(settings))
  }
  return settings
}

const setHoldingsSettingsToStorage = (settings: THoldingsSettings) => {
  localStorage.setItem(LocalStorageItem.HOLDINGS_SETTINGS, JSON.stringify(settings))
}

export { getHoldingsSettings, setHoldingsSettingsToStorage }
