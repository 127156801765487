import { CustomToast } from '@/components/custom-toast'

import { getErrorMessage } from './getErrorMessage'

function handleError(error: unknown, message = '') {
  const errorMessage = getErrorMessage(error)
  CustomToast('error', message + errorMessage)
}

export { handleError }
