import { FC, useEffect, useState } from 'react'

import { getSubscriptionsList } from '@/api/subscriptions'
import { TSubscription } from '@/api/subscriptions/types'
import { AuthCard } from '@/components/auth-card'
import { PlanCard } from '@/components/plan-card'
import { Link } from '@/libs/common/link'
import { Typography } from '@/libs/common/typography'
import { AppRoute, VerificationStatus } from '@/libs/enums'

import styles from './styles.module.scss'

const SelectPlan: FC = () => {
  const [subscriptions, setSubscriptions] = useState<TSubscription[] | null>(null)

  useEffect(() => {
    ;(async () => {
      const { data } = await getSubscriptionsList()
      setSubscriptions(data)
    })()
  }, [])

  return (
    <AuthCard
      isHeaderButtons
      className={styles.card}
      verificationStatus={VerificationStatus.SELECT_PLAN}
    >
      <div className={styles.content}>
        <Typography variant="h1" className={styles.title}>
          Select Plan
        </Typography>
        <Typography variant="body1" className={styles.desc}>
          Take charge of your choices with the flexibility to upgrade anytime! Read detailed
          descriptions of plans <Link href="/" label="here" />.
        </Typography>
        <div className={styles.plans}>
          {subscriptions?.map((item, index) => (
            // @TODO Get all the info from the backend
            <PlanCard
              key={item.name}
              name={item.name}
              price={item.price}
              link={AppRoute.SETUP_WALLETS}
              isActive={!index}
            />
          ))}
        </div>
      </div>
    </AuthCard>
  )
}

export { SelectPlan }
