import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { deleteProfitPanel } from '@/api/profit-loss'
import { VerticalGroup } from '@/components/buy-sell-snipe-profit-sell/index'
import { CustomToast } from '@/components/custom-toast'
import styles from '@/components/profit-sell-tab/libs/components/styles-cards.module.scss'
import { SuspiciousTokenWarningLabel } from '@/components/suspicious-token-warning-label'
import { useAppMode } from '@/hooks/useAppMode.ts'
import { useSelectedPanel } from '@/hooks/useSelectedPanel'
import {
  Button,
  ButtonIcon,
  Popover,
  TOnChangeVisible,
  TokenPlaceholder,
  TokenRedirect,
  Typography,
} from '@/libs/common'
import stylesPopoverDelete from '@/libs/common/popover/styles-popover-delete.module.scss'
import { AppRoute, IconName } from '@/libs/enums'
import { createSellPayload, formatNumber, getPriceImpactColor, handleSell } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { handleError } from '@/libs/helper/handleError'
import { TActivePanelItem } from '@/libs/types/panels-data-socket-response.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentPositionId } from '@/store/slices/profit-sell.slice'

type TProps = {
  position: TActivePanelItem
  className?: string
}

const defaultLoadingState = {
  initial: false,
  all: false,
}

const ProfitSellTabCard: FC<TProps> = ({ className, position }) => {
  const defaultPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const [loadingState, setLoadingState] = useState(defaultLoadingState)
  const { mode } = useAppMode()
  const chainSymbol = useAppSelector((state) => state.chain.currentChain.description)

  const currentPosition = useSelectedPanel()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  // const [isActiveLoading, setIsActiveLoading] = useState(false)
  // const [isActive, setIsActive] = useState(position.panel.isActive)

  // const handleActive = async (_: BaseSyntheticEvent, newValue: boolean) => {
  //   try {
  //     setIsActiveLoading(true)
  //     setIsActive(newValue)
  //     await setProfitPanelActivity(position.panel._id, newValue)
  //   } catch (err) {
  //     CustomToast('error', (err as any).message)
  //     setIsActive(!newValue)
  //   } finally {
  //     setIsActiveLoading(false)
  //   }
  // }

  const handleDelete = async () => {
    try {
      await deleteProfitPanel(position)
      CustomToast('success', 'Position deleted successfully')
    } catch (err) {
      handleError(err)
    }
  }

  const handleOpenShareProfitModal = () => {
    navigate(`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.SHARE_PROFIT}`, {
      state: { positionsSummary: position },
    })
  }

  // const handleOpenEditPosition = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.EDIT_POSITION}/${position.panel.token_address}`)
  // }

  const switchToViewPage = () => {
    if (currentPosition?.ta !== position.ta) {
      dispatch(setCurrentPositionId(position.id))
    }
  }

  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  const onSell = async (type: 'all' | 'initial') => {
    if (!position.po.length) return

    setLoadingState({ ...defaultLoadingState, [type]: true })
    const wallets = position.po.map((item) => ({
      name: item.wn,
      public_key: item.wa,
    }))

    const payload = await createSellPayload({
      data: {
        receive: '0',
        sell: '0',
        sellPriority: defaultPriorities.sell_priority,
        slippage: 50,
        privateTransaction: true,
      },
      token: position.ta,
      wallets,
      mode: type === 'all' ? 0 : 2,
      sellPercentage: type === 'all' ? 100 : 0,
      dex: position.sd,
    })

    if (payload) {
      await handleSell(payload)
    }

    setLoadingState({ ...defaultLoadingState, [type]: false })
  }

  return (
    <Grid
      className={cn(
        styles.card,
        //  { [styles.inactiveCard]: !isActive }
        className,
      )}
    >
      {/* header row */}
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className={styles.rowContainer}
      >
        <TokenRedirect className={styles.rowContainerItem} address={position.ta}>
          <Grid container className={styles.coinIcon}>
            {position.i && position.i.s ? (
              <img className={styles.tokenLogo} src={position.i.s} alt={position.i.s + ' token'} />
            ) : (
              <TokenPlaceholder token={{} as any} />
            )}
          </Grid>
          {position.tn && (
            <Typography variant="body2" className={styles.tokenName}>
              {position.tn}
            </Typography>
          )}
          {position.tps && (
            <Typography variant="body2" marginBottom={-0.3}>
              {formatTokenPrice(`${position.tps}`).formatted}%
            </Typography>
          )}
        </TokenRedirect>
        {/* <Grid container className={styles.rowContainerItem}>
          <Typography variant="body2">P/S Panel active</Typography>
          <Switch checked={isActive} onChange={handleActive} disabled={isActiveLoading} />
        </Grid> */}
        <Grid container className={styles.headerButtonsGroup}>
          {
            <Button
              className={styles.shareBtn}
              styleVariant="borderless-gold"
              iconStartName={IconName.SHARE}
              transparent
              onClick={handleOpenShareProfitModal}
            >
              Share
            </Button>
            /*  <Button
                className={styles.headerButton}
                styleVariant="borderless-gold"
                iconStartName={IconName.EDIT}
                transparent
                onClick={handleOpenEditPosition}
                disabled
              >
                Edit position
              </Button> */
          }
          <Button
            tooltipTitle="Delete"
            className={cn(stylesPopoverDelete.deleteButton)}
            styleVariant="borderless-red"
            iconStartName={IconName.DELETE}
            transparent
            onClick={(e) => setDeletePopup({ visible: true, anchorE: e.currentTarget })}
          />

          <Popover
            className={cn(stylesPopoverDelete.popover, styles.popover)}
            popoverState={deletePopup}
            onChangeVisible={setDeletePopup}
          >
            <Typography variant="body2" className={stylesPopoverDelete.title}>
              Confirm Delete
            </Typography>
            <Typography variant="body2">Are you sure you want to delete this position?</Typography>
            <div className={stylesPopoverDelete.popoverButtons}>
              <ButtonIcon
                onClick={() => {
                  setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                }}
              >
                <Typography variant="body2" className={stylesPopoverDelete.cancel}>
                  Cancel
                </Typography>
              </ButtonIcon>
              <Button
                className={stylesPopoverDelete.confirm}
                styleVariant="red"
                onClick={() => {
                  handleDelete()
                  setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                }}
              >
                Delete
              </Button>
            </div>
          </Popover>
        </Grid>
      </Grid>

      <SuspiciousTokenWarningLabel tokenPosition={position} marginTop={-0.8} marginBottom={1} />

      {/* middle rows */}
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        direction="column"
        className={styles.rowContainer}
        gap={1}
      >
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          className={styles.rowContainer}
        >
          <VerticalGroup
            //TO DO get wallets count with positive balance from back
            // title={`Total balance (${position.panel_data.userWallets.length} wallets)`}
            title={`Total balance`}
            description={<>{formatTokenPrice(position.tb).formatted}</>}
          />
          <Grid container className={styles.middleButtonsGroup}>
            <Button
              styleVariant="black"
              className={cn(styles.middleRowButton, styles.buttonSellAll)}
              transparent
              onClick={() => onSell('all')}
              isLoading={loadingState.all}
            >
              Sell all
            </Button>

            <Button
              styleVariant="black"
              className={cn(styles.middleRowButton, styles.buttonSellInitial)}
              transparent
              onClick={() => onSell('initial')}
              isLoading={loadingState.initial}
            >
              Sell initial
            </Button>

            <Button
              styleVariant="black"
              iconEndName={IconName.ARROW_RIGHT2}
              className={styles.middleRowButton}
              onClick={switchToViewPage}
              transparent
            >
              View
            </Button>
          </Grid>
        </Grid>
        {/* <Grid container>
          <Button
            className={styles.transferButton}
            styleVariant="borderless-gold"
            iconStartName={IconName.WITHDRAW}
            transparent
            disabled
          >
            Transfer to backup wallets
          </Button>
        </Grid> */}
      </Grid>

      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className={styles.rowContainer}
      >
        <VerticalGroup
          title={'Profit/Loss'}
          description={
            <span className={cn(+position.tpl > 0 ? styles.isProfit : styles.isLoss)}>
              {'%'}
              {Math.abs(+position.tpl).toFixed(2)}
            </span>
          }
        />
        <VerticalGroup
          title={'Cost'}
          description={
            <>
              {formatTokenPrice(`${position.tc.t}`, 2).formatted} {chainSymbol}
              {/* {currentChain.description} */}
            </>
          }
        />
        <VerticalGroup
          title={'Worth'}
          descClassName={position.tw.u > position.tc.u ? styles.greenText : ''}
          description={
            <>
              {formatTokenPrice(`${position.tw.t}`, 2).formatted} {chainSymbol}
              {/* {currentChain.description} */}
            </>
          }
        />
        <VerticalGroup
          title={'After Tax'}
          descClassName={+position.tat.u > +position.tb * +position.p ? styles.greenText : ''}
          description={
            <>
              {formatTokenPrice(`${position.tat.t}`, 2).formatted} {chainSymbol}
              {/* {currentChain.description} */}
            </>
          }
        />
        <VerticalGroup
          title={'Sold'}
          description={
            <>
              {formatTokenPrice(position.ts.t).formatted} {chainSymbol}
            </>
          }
        />
        <VerticalGroup
          title={'Price impact'}
          descClassName={getPriceImpactColor(+position.tpi)}
          description={<>{formatNumber(+position.tpi).formatted}%</>}
        />
      </Grid>

      {/* footer row */}
      {/* <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="center"
        className={cn(styles.rowContainer, styles.footerRow)}
        gap={3}
      >
        <Grid
          container
          gap={0.5}
          justifyContent="center"
          wrap="nowrap"
          alignItems="center"
          className={styles.footerItem}
        >
          <Typography variant="body2" resetLineHeight noWrap>
            Order type:
          </Typography>
          <OrderType type="snipe" />
        </Grid>
        <Grid
          container
          gap={0.5}
          justifyContent="center"
          wrap="nowrap"
          alignItems="center"
          className={styles.footerItem}
        >
          <Typography variant="body2" resetLineHeight noWrap>
            Date:
          </Typography>
          <Typography variant="body2" textColor="light-grey" resetLineHeight noWrap>
            2024-01-25
          </Typography>
        </Grid>
      </Grid> */}
    </Grid>
  )
}

export { ProfitSellTabCard }
