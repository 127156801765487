import { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import cn from 'classnames'

import { AuthCard, TAuthCardProps } from '@/components/auth-card'
import {
  Button,
  ButtonCopy,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  Divider,
  Icon,
  Input,
  Typography,
} from '@/libs/common/index'
import { RadioButtonContent } from '@/libs/common/radio-button-content'
import { AppRoute, IconName, VerificationStatus } from '@/libs/enums'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'

import styles from './styles.module.scss'

// example only
type mockState = {
  plan: string | null
  currency: string | null
  mainWalletAddress: string | null
}

type TSuccessPaidPlanProps = TAuthCardProps & {
  isTokeBalanceSufficient?: boolean
}

const SuccessPaidPlan: FC<TSuccessPaidPlanProps> = ({
  className,
  isTokeBalanceSufficient = false,
  ...props
}) => {
  // example only
  const [formValues, setFormValues] = useState<mockState>({
    plan: null,
    currency: null,
    mainWalletAddress: '0x698253463634636346346346346363d4ec3d2311933',
  })
  const [tokenBalanceSufficient /*, setTokeBalanceSufficient*/] =
    useState<boolean>(isTokeBalanceSufficient)

  // example only
  const handleRadio = (e: React.BaseSyntheticEvent, newValue: string | null) => {
    setFormValues({
      ...formValues,
      [e.currentTarget.name]: newValue,
    })
  }

  return (
    <AuthCard
      className={cn(styles.authCard, className)}
      verificationStatus={VerificationStatus.ENJOY_BLAZING_BOT}
      isHeaderButtons
      isBackButton
      footerContent={
        <Link to="/">
          <Typography variant="body1" isEmphasize>
            Switch to essential plan and upgrade later
          </Typography>
        </Link>
      }
      {...props}
    >
      {/* if payment wasn't verified */}
      <Grid container alignItems="center" direction="column" gap={1} className={styles.title}>
        <Typography variant="h1">Pay for the Pro plan</Typography>
        <Typography variant="body1">
          Payment for the plan will be deducted from your main wallet and automatically be renewed
          after the selected period.
        </Typography>
      </Grid>
      <Stack spacing={3} useFlexGap>
        <ButtonGroupRadio
          label="Select payment plan"
          exclusive
          value={formValues.plan}
          onChange={handleRadio}
          className={styles.buttonGroupRadio}
        >
          <ButtonGroupRadioButton value="3" name="plan">
            <RadioButtonContent title="3 Months - $50" description="$15.50 / Monthly" />
          </ButtonGroupRadioButton>
          <ButtonGroupRadioButton value="6" name="plan">
            <RadioButtonContent title="6 Months - $90" description="$12.50 / Monthly" />
          </ButtonGroupRadioButton>
          <ButtonGroupRadioButton value="12" name="plan">
            <RadioButtonContent title="12 Months - $200" description="$9.55 / Monthly" />
          </ButtonGroupRadioButton>
        </ButtonGroupRadio>

        {formValues.plan && (
          <ButtonGroupRadio
            label="Select currency"
            exclusive
            value={formValues.currency}
            onChange={handleRadio}
            className={styles.buttonGroupRadio}
          >
            <ButtonGroupRadioButton value="1" name="currency">
              <RadioButtonContent title="Ethereum" description="ETH" iconName={IconName.COIN_ETH} />
            </ButtonGroupRadioButton>
            <ButtonGroupRadioButton value="2" name="currency">
              <RadioButtonContent title="Ethereum" description="ETH" iconName={IconName.COIN_ETH} />
            </ButtonGroupRadioButton>
            <ButtonGroupRadioButton value="3" name="currency">
              <RadioButtonContent title="Ethereum" description="ETH" iconName={IconName.COIN_ETH} />
            </ButtonGroupRadioButton>
            <ButtonGroupRadioButton value="4" name="currency">
              <RadioButtonContent title="Ethereum" description="ETH" iconName={IconName.COIN_ETH} />
            </ButtonGroupRadioButton>
          </ButtonGroupRadio>
        )}

        {/* if token balance sufficient */}
        {tokenBalanceSufficient && formValues.currency && (
          <Link to={AppRoute.SUCCESS_PAID_PLAN_SUCCESS} className={styles.buttonLink}>
            <Button type="submit">Upgrade</Button>
          </Link>
        )}

        {/* if token balance insufficient */}
        {!tokenBalanceSufficient && formValues.currency && (
          <Stack spacing={1} useFlexGap>
            <Typography variant="body1" textColor="light-grey">
              Deposit payment to main wallet
            </Typography>
            <Input
              className={styles.input}
              label="Amount to deposit"
              placeholder="0.123844"
              isCopy
              endAdornment={
                <InputAdornment position="end" className={styles.InputAdornment}>
                  <Grid></Grid>
                  <Grid container className={styles.InputAdornmentIcon}>
                    <Icon name={IconName.COIN_ETH} />
                  </Grid>
                  <Typography variant="caption" className={styles.InputAdornmentTypo}>
                    ETH
                  </Typography>
                </InputAdornment>
              }
            />
            <Grid container wrap="nowrap">
              <Grid container direction="column">
                <Typography variant="caption">Main wallet address</Typography>
                <Grid container gap={0.5}>
                  <Typography variant="body2" textColor="light-grey">
                    {hideWalletAddress(formValues.mainWalletAddress)}
                  </Typography>
                  <ButtonCopy value={formValues.mainWalletAddress ?? ''} />
                </Grid>
              </Grid>
              <Grid container direction="column">
                <Typography variant="caption">Main wallet balance</Typography>
                <Typography variant="body2" textColor="light-grey">
                  0.000000 ETH
                </Typography>
              </Grid>
            </Grid>
            <Divider className={styles.divider}>OR</Divider>
            <Link to={AppRoute.SUCCESS_PAID_PLAN_SUCCESS} className={styles.buttonLink}>
              <Button type="submit">
                <Icon name={IconName.METAMASK_LOGO} />
                <span className={styles.buttonText}>Deposit</span>
              </Button>
            </Link>
          </Stack>
        )}
      </Stack>
    </AuthCard>
  )
}

export { SuccessPaidPlan, type TSuccessPaidPlanProps }
