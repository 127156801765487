import { useMemo } from 'react'

import { THoldingsBalancesType } from '@/libs/enums'
import { ESorting } from '@/libs/enums/sorting.enum'
import { setHoldingsSettingsToStorage, sortHoldings } from '@/libs/helper'
import { TValidToken } from '@/libs/types/sniper-holdings-socket-response.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserHoldingsSettings } from '@/store/slices/user.slice'

const useHoldingsWithSettings = () => {
  const holdingsSettings = useAppSelector((state) => state.user.userHoldingsSettings)
  const holdings = useAppSelector((state) => state.user.userHoldingsWebsocket?.v)

  const dispatch = useAppDispatch()

  const filteredHoldings = useMemo(() => {
    if (!holdings) return null

    const res: TValidToken = {
      ...holdings,
      t: sortHoldings(holdings.t, holdingsSettings.sorting),
    }

    if (holdingsSettings.hideSmallBalances) {
      // Hide tokens that worth less than 1 USD
      res.t = res.t.filter((token) => (+token.tvu || 0) > 1)
    }

    setHoldingsSettingsToStorage(holdingsSettings)
    return res
  }, [holdingsSettings, holdings])

  const totalHoldingByFilter = useMemo(
    () =>
      ({
        [THoldingsBalancesType.ALL]: {
          usd: filteredHoldings?.tvu,
          native: filteredHoldings?.tvc,
        },
        [THoldingsBalancesType.WALLETS]: {
          usd: filteredHoldings?.tvufw,
          native: filteredHoldings?.tvcfw,
        },
        [THoldingsBalancesType.TOKENS]: {
          usd: filteredHoldings?.tvuft,
          native: filteredHoldings?.tvcft,
        },
      })[holdingsSettings.selectedBalancesType],
    [filteredHoldings, holdingsSettings],
  )

  const toggleSort = () => {
    dispatch(
      setUserHoldingsSettings({
        ...holdingsSettings,
        sorting: holdingsSettings.sorting === ESorting.DESC ? ESorting.ASC : ESorting.DESC,
      }),
    )
  }

  const handleHideSmallBalances = (newValue?: boolean) => {
    dispatch(
      setUserHoldingsSettings({
        ...holdingsSettings,
        hideSmallBalances: newValue !== undefined ? newValue : !holdingsSettings.hideSmallBalances,
      }),
    )
  }

  const handleBalancesType = (newType: THoldingsBalancesType) => {
    dispatch(
      setUserHoldingsSettings({
        ...holdingsSettings,
        selectedBalancesType: newType,
      }),
    )
  }

  return {
    filteredHoldings,
    totalHoldingByFilter,
    holdingsSettings,
    toggleSort,
    handleHideSmallBalances,
    handleBalancesType,
  }
}

export { useHoldingsWithSettings }
