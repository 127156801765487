import { FC } from 'react'

import { ListButton } from '@/libs/common'
import { ButtonLink, type TButtonLinkProps } from '@/libs/common/button-link'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

type TSetupWalletsButtonLinkProps = TButtonLinkProps & {
  title: string
  description: string
  iconName?: ValueOf<typeof IconName>
}

const SetupWalletsButtonLink: FC<TSetupWalletsButtonLinkProps> = ({
  iconName,
  title,
  description,
  ...props
}) => {
  return (
    <ButtonLink {...props}>
      <ListButton iconName={iconName} title={title} description={description} />
    </ButtonLink>
  )
}

export { SetupWalletsButtonLink, type TSetupWalletsButtonLinkProps }
