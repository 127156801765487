import { FC } from 'react'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Grid } from '@mui/material'

import { ButtonIcon, LinearProgressBar, Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = {
  title: string
  value: number
  maxValue?: number
  onClick?: () => void
}

const UserStatisticWithProgress: FC<TProps> = ({ title, value, maxValue = 100, onClick }) => {
  return (
    <ButtonIcon className={styles.userStatisticBlock} onClick={onClick}>
      <Grid container marginBottom={1.15} alignItems="center" justifyContent="space-between">
        <div>
          <Typography variant="body2">{title}</Typography>

          <Typography variant="body1" textAlign="left">
            <span className={styles.userStatisticSpan}>{value}</span> / {maxValue}
          </Typography>
        </div>

        <ChevronRightIcon viewBox="0 0 10 24" className={styles.chevron} />
      </Grid>

      <LinearProgressBar value={(value / maxValue) * 100} />
    </ButtonIcon>
  )
}

export { UserStatisticWithProgress }
