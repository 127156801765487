import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Stack } from '@mui/material'

import { BackButton, Button, Typography } from '@/libs/common'
import { AppMode, AppRoute } from '@/libs/enums'

import styles from './styles.module.scss'

const WalletFile: FC = () => {
  const navigate = useNavigate()

  const handleImportWallet = () => {
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.IMPORT_WALLETS}?type=wallet-file`,
    )
  }
  return (
    <Stack className={styles.container} useFlexGap>
      <BackButton isAbsolute />

      <Typography variant="h1" align="center" className={styles.title}>
        Import wallet file
      </Typography>

      <div className={styles.dropBlock}>
        <Typography className={styles.dropDescription}>
          Drag and drop file here
          <span className={styles.span}>or</span>
          <Link to="/" className={styles.link}>
            Browser files
          </Link>
        </Typography>
      </div>

      <Button onClick={handleImportWallet}>Continue</Button>
    </Stack>
  )
}

export { WalletFile }
