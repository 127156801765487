import { FC, useMemo, useState } from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { RowLoadingPlaceholder } from '@/components/row-loading-placeholder'
import { ButtonIcon } from '@/libs/common'
import { IconName, Sorting } from '@/libs/enums'
import { createSellPayload, handleSell } from '@/libs/helper'
import { ValueOf } from '@/libs/types'
import { TActivePanelItem } from '@/libs/types/panels-data-socket-response.type'
import { useAppSelector } from '@/store'

import { Row } from './libs/row/row'
import styles from './styles.module.scss'

const HoldingsTable: FC = () => {
  const profitSell = useAppSelector((state) => state.profitSell.profitSell)
  const isLoading = useAppSelector((state) => state.profitSell.isLoading)
  const mainWallet = useAppSelector((state) => state.user.mainWallet)

  const rows = useMemo(
    () =>
      profitSell
        ? profitSell.ap.filter((el) => {
            const panel = el.po.find((wallet) => wallet.wa === mainWallet?.address)
            return panel && +panel.ba
          })
        : [],
    [profitSell],
  )

  const handleMarketSellAll = async (tokenAddress: string, row: TActivePanelItem) => {
    if (!mainWallet) return

    const payload = await createSellPayload({
      token: tokenAddress,
      wallets: [
        {
          name: mainWallet.name,
          public_key: mainWallet.public_key,
        },
      ],
      sellPercentage: 100,
      dex: row.sd,
    })
    if (payload) {
      await handleSell(payload)
    }
  }
  const [sorting, setSorting] = useState<ValueOf<typeof Sorting>>(Sorting.DESC)
  const [keySort, setKeySorting] = useState('tn')

  const handleSort = (key: string) => {
    setKeySorting(key)
    setSorting((sorting) => {
      return sorting === Sorting.ASC && key === keySort ? Sorting.DESC : Sorting.ASC
    })
  }

  const sortedRows = useMemo(() => {
    return rows
      .filter((row) => +row.ba !== 0)
      .sort((a, b) => {
        const aValue = a[keySort]?.u ?? a[keySort]
        const bValue = b[keySort]?.u ?? b[keySort]

        const numA = parseFloat(aValue)
        const numB = parseFloat(bValue)

        if (!isNaN(numA) && !isNaN(numB)) {
          return sorting === Sorting.ASC ? numA - numB : numB - numA
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sorting === Sorting.ASC
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue)
        }

        return 0
      })
  }, [rows, sorting, keySort])

  return (
    <Paper className={styles.root}>
      <TableContainer className={cn(styles.container)} id="holdings-table">
        <Table stickyHeader aria-label="sticky table" classes={styles}>
          <TableHead>
            <TableRow className={styles.headRow}>
              <TableCell className={styles.tableHeadCell}>
                <div>
                  Token
                  <ButtonIcon
                    icon={
                      keySort === 'tn' && sorting === Sorting.ASC
                        ? IconName.SORT_ASC
                        : IconName.SORT_DESC
                    }
                    onClick={() => handleSort('tn')}
                  />
                </div>
              </TableCell>
              <TableCell className={styles.tableHeadCell}>
                <div>
                  Invested
                  <ButtonIcon
                    icon={
                      keySort === 'tb' && sorting === Sorting.ASC
                        ? IconName.SORT_ASC
                        : IconName.SORT_DESC
                    }
                    onClick={() => handleSort('tb')}
                  />
                </div>
              </TableCell>
              <TableCell className={styles.tableHeadCell}>
                <div>
                  Remaining
                  <ButtonIcon
                    icon={
                      keySort === 'tw' && sorting === Sorting.ASC
                        ? IconName.SORT_ASC
                        : IconName.SORT_DESC
                    }
                    onClick={() => handleSort('tw')}
                  />
                </div>
              </TableCell>
              <TableCell className={styles.tableHeadCell}>
                <div>
                  Sold
                  <ButtonIcon
                    icon={
                      keySort === 'ts' && sorting === Sorting.ASC
                        ? IconName.SORT_ASC
                        : IconName.SORT_DESC
                    }
                    onClick={() => handleSort('ts')}
                  />
                </div>
              </TableCell>
              <TableCell className={styles.tableHeadCell}>
                <div>
                  Change in P&L
                  <ButtonIcon
                    icon={
                      keySort === 'tpl' && sorting === Sorting.ASC
                        ? IconName.SORT_ASC
                        : IconName.SORT_DESC
                    }
                    onClick={() => handleSort('tpl')}
                  />
                </div>
              </TableCell>
              <TableCell className={styles.tableHeadCell}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedRows &&
              sortedRows.map((row) => (
                <Row
                  row={row}
                  key={row.ta}
                  handleMarketSellAll={(address) => handleMarketSellAll(address, row)}
                />
              ))}
            {!sortedRows && isLoading && <RowLoadingPlaceholder styleValiant="lite" />}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export { HoldingsTable }
