import { ReactElement } from 'react'

import { formatDecimalString } from './formatDecimalString'
import { numberWithCommas } from './numberWithCommas'
import { removeTrailingZeros } from './removeTrailingZeros'

function formatTokenPrice(value: string, precision?: number) {
  const isMinus = +value < 0
  const number = Math.abs(+value)
  let raw = value
  let formatted: ReactElement | null = null

  if (number < 0.009 && number > 0) {
    const parsedValue = formatDecimalString(number)

    let currentNumber = number
    let count = 0

    while (currentNumber < 1) {
      currentNumber *= 10
      count++
    }

    const numbersAfterZero = parseInt(
      parsedValue.substring(count + 1, count + 4).replace(/0+$/, ''),
    )
    if (numbersAfterZero) {
      raw = (isMinus ? '-' : '') + `0.0${count - 1}/${numbersAfterZero}`
      formatted = (
        <span>
          {isMinus ? '-' : ''}0.0<sub>{count - 1}</sub>
          {numbersAfterZero}
        </span>
      )
    } else {
      raw = '0'
      formatted = <span>0</span>
    }
  } else if (number === 0) {
    raw = '0'
  } else if (number < 1) {
    raw = parseFloat(value).toFixed(precision || 5)
  } else if (number < 1000) {
    raw = removeTrailingZeros(number.toFixed(precision || 5))
  } else if (number >= 1000 && number < 1e6) {
    raw = `${(number / 1000).toFixed(2)}k`
  } else if (number >= 1e6 && number < 1e9) {
    raw = `${removeTrailingZeros((number / 1e6).toFixed(2))}M`
  } else if (number >= 1e9 && number < 1e12) {
    raw = `${removeTrailingZeros((number / 1e9).toFixed(3))}B`
  } else if (number >= 1e12 && number < 1e15) {
    raw = `${removeTrailingZeros((number / 1e9).toFixed(3))}B`
  } else if (number >= 1e15 && number < 1e18) {
    raw = `${removeTrailingZeros((number / 1e9).toFixed(3))}B`
  } else if (number >= 1e18 && number < 1e21) {
    raw = `${removeTrailingZeros((number / 1e9).toFixed(3))}B`
  } else if (number >= 1e21 && number < 1e24) {
    raw = `${removeTrailingZeros((number / 1e9).toFixed(3))}B`
  } else {
    raw = number.toFixed(0)
  }

  return { raw, formatted: formatted || <span>{numberWithCommas(raw)}</span> }
}

export { formatTokenPrice }
