import { FC } from 'react'

import MUITab, { TabProps } from '@mui/material/Tab'

import styles from './styles.module.scss'

type TProperty = TabProps & {
  label: string
}

const Tab: FC<TProperty> = ({ label, ...props }) => {
  return <MUITab label={label} classes={styles} disableRipple {...props} />
}

export { Tab }
