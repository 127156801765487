import { FC } from 'react'

import { Grid, GridOwnProps } from '@mui/material'

import { Typography } from '@/libs/common'
import { TActivePanelItem } from '@/libs/types/panels-data-socket-response.type'

type TProps = GridOwnProps & {
  tokenPosition: TActivePanelItem
}

const SuspiciousTokenWarningLabel: FC<TProps> = ({ tokenPosition, ...rest }) => {
  if (!tokenPosition.h) return <></>

  return (
    <Grid display="flex" justifyContent="center" alignItems="center" {...rest}>
      <Typography textColor="gold" letterSpacing={3}>
        HONEYPOT
      </Typography>
    </Grid>
  )
}

export { SuspiciousTokenWarningLabel }
