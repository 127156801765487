import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Grid, InputLabel, Stack } from '@mui/material'
import cls from 'classnames'

import { SelectWallet } from '@/components/select-wallet'
import {
  BackButton,
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  Input,
  StepInput,
  Tabs,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { IconName, ManualSettingsSection } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'

import styles from './styles.module.scss'

const ProfitSettingsTabs: TSelectOption<string>[] = [
  { value: 'sellInSteps', label: 'Sell in steps' },
  { value: 'sellOnProfit', label: 'Sell on profit' },
  { value: 'trailingSell', label: 'Trailing sell' },
]

const defaultValues = {
  templateName: '',
  amount: '',
  privateTransaction: false,
  degenChadMode: false,
  slippage: 0,
  selectedWallets: [],
  buyPriority: '',
  approvePriority: '',
  duplicateBuy: false,
  transferOnBlacklist: false,
  antiBlacklist: false,
  antiRug: false,
  antiRugThreshold: 0,
  sellRugExtraTip: '12',
  maxBuyTax: '',
  maxSellTax: '',
  minLiquidity: '',
  maxLiquidity: '',
  maxMarketcap: '',
  autoSell: false,
  sellPriority: '',
  walletsToSellOnProfit: [],
  walletsToSellOnLoss: [],
  stopLoss: '',
  stopLossAmount: '',
  w1: '25',
  w2: '32',
  w3: '40',
  w4: '25',
  w5: '32',
  w6: '40',
  sellOnProfit: '',
  sellOnProfitAmount: '',
  walletsToTrailingSell: [],
  trailingSell: '',
}

const AddNewScrapeTemplate = () => {
  const [mainTab, setMainTab] = useState(0)
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)
  const [isAutoSellSettings, setIsAutoSellSetting] = useState(false)
  const [walletsOnProfit, setWalletsOnProfit] = useState<string[]>(
    defaultValues.walletsToSellOnProfit,
  )
  const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  const isLastMainTab = mainTab === Object.values(ManualSettingsSection).length - 1
  const { control } = useForm({ defaultValues })

  const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
    setProfitTab(newValue)
  }

  const handleChangeMainTab = () => {
    setMainTab((value) => (value < Object.values(ManualSettingsSection).length ? value + 1 : value))
  }

  return (
    <Stack spacing={3} useFlexGap>
      <BackButton isAbsolute />
      <Typography variant="h1" textColor="light-grey" textAlign="center">
        Add new scrape template
      </Typography>
      <form className={styles.form}>
        <Controller
          name="templateName"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Input
              isTransparent
              label="Template Name"
              placeholder="Enter template name"
              maxLength={15}
              {...field}
            />
          )}
        />
        <Tabs
          tabControl
          value={mainTab}
          tabs={[
            {
              label: 'Buy',
              content: (
                <Grid>
                  <Grid container rowGap={2}>
                    <Controller
                      name="amount"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          isTransparent
                          label="Amount"
                          placeholder="Enter amount"
                          isNumeric
                          endAdornment={<EndAdornment label="ETH" icon={IconName.ETHEREUM} />}
                          {...field}
                        />
                      )}
                    />
                    <Grid container columnGap={3} flexWrap="nowrap">
                      <Controller
                        name="privateTransaction"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label="Anti-Mev"
                            tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="degenChadMode"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label="Degen Chad Mode"
                            tooltipInfo="For advanced traders. If enabled, the safety rules from the simulation will be ignored."
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Controller
                      name="slippage"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <PercentsInput
                          label="Slippage"
                          onOptionSelect={(value) => field.onChange(value)}
                          tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                          placeholder="X"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="selectedWallets"
                      control={control}
                      render={({ field: { ref, value, ...field } }) => (
                        <SelectWallet
                          tooltipInfo="Select which wallets will be used to purchase token."
                          label="Select wallets"
                          isMulti
                          wallet={value}
                          {...field}
                        />
                      )}
                    />
                    <Grid container columnGap={3} flexWrap="nowrap">
                      <Controller
                        name="buyPriority"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            className={styles.input}
                            label="Buy Priority"
                            placeholder="Enter gwei amount"
                            isNumeric
                            tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                            onAutoChange={() => {
                              //TO DO: added auto switch
                            }}
                            endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="approvePriority"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            className={styles.input}
                            label="Approve priority"
                            placeholder="Enter gwei amount"
                            isNumeric
                            tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
                            endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Controller
                      name="duplicateBuy"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <SwitchInline
                          label="Duplicate Buy"
                          tooltipInfo="If enabled, it will buy even if you already own that token, disable it and the bot will not buy if you own that token."
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              ),
            },
            {
              label: 'Safety',
              content: (
                <Grid container rowGap={2}>
                  <Grid container columnGap={3} flexWrap="nowrap">
                    <Controller
                      name="transferOnBlacklist"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <SwitchInline
                          label="Transfer On Blacklist"
                          tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="antiBlacklist"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <SwitchInline
                          label="Anti-Blacklist"
                          tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <HiddenInputs
                    expanded={isAntiRugSettings}
                    trigger={
                      <Controller
                        name="antiRug"
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <SwitchInline
                            label="Anti-Rug"
                            tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                            onChange={(
                              _: React.ChangeEvent<HTMLInputElement>,
                              checked: boolean,
                            ) => {
                              setIsAntiRugSettings(checked)
                              onChange(checked)
                            }}
                            {...field}
                          />
                        )}
                      />
                    }
                  >
                    <Grid container rowGap={2}>
                      <Controller
                        name="antiRugThreshold"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <PercentsInput
                            label="Anti-Rug Threshold"
                            onOptionSelect={(value) => field.onChange(value)}
                            tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="sellRugExtraTip"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Amount"
                            isNumeric
                            label="Sell Rug Extra Tip"
                            tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </HiddenInputs>

                  <Grid container flexWrap="nowrap" columnGap={3}>
                    <Controller
                      name="maxBuyTax"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder="Enter percentage"
                          isNumeric
                          label="Max Buy Tax"
                          adornmentText="%"
                          tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="maxSellTax"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder="Enter percentage"
                          isNumeric
                          label="Max Sell Tax"
                          adornmentText="%"
                          tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container flexWrap="nowrap" columnGap={3}>
                    <Controller
                      name="minLiquidity"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder="Enter min liquidity amount"
                          isNumeric
                          label="Min Liquidity"
                          adornmentText="$"
                          tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="maxLiquidity"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder="Enter max liquidity amount"
                          isNumeric
                          label="Max Liquidity"
                          adornmentText="$"
                          tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
                          {...field}
                        />
                      )}
                    />
                  </Grid>

                  <Controller
                    name="maxMarketcap"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <OptionalInput
                        placeholder="Enter max marketcap"
                        isNumeric
                        label="Max Marketcap"
                        adornmentText="$"
                        tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
                        {...field}
                      />
                    )}
                  />
                </Grid>
              ),
            },
            {
              label: 'Sell',
              content: (
                <Grid>
                  <HiddenInputs
                    expanded={isAutoSellSettings}
                    trigger={
                      <Controller
                        name="autoSell"
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <SwitchInline
                            label="Auto Sell"
                            tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                            {...field}
                            onChange={(
                              _: React.ChangeEvent<HTMLInputElement>,
                              checked: boolean,
                            ) => {
                              setIsAutoSellSetting(checked)
                              onChange(checked)
                            }}
                          />
                        )}
                      />
                    }
                  >
                    <Grid container rowGap={2} flexDirection="column">
                      <Controller
                        name="sellPriority"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter gwei amount"
                            isNumeric
                            label="Sell Priority"
                            tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                            endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                            onAutoChange={() => {
                              //TO DO: added auto switch
                            }}
                            {...field}
                          />
                        )}
                      />
                      <div className={styles.dividerContainer}>
                        <Typography variant="body2" textColor="grey">
                          Profit
                        </Typography>
                        <div className={styles.divider}></div>
                      </div>

                      <Controller
                        name="walletsToSellOnProfit"
                        control={control}
                        render={({ field: { ref, value, onChange, ...field } }) => (
                          <SelectWallet
                            tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                            label="Wallets to sell on Profit"
                            isMulti
                            wallet={value}
                            onChange={(value) => {
                              setWalletsOnProfit(value)
                              onChange(value)
                            }}
                            {...field}
                          />
                        )}
                      />

                      <ButtonGroupRadio
                        onChange={handleProfitTabChange}
                        value={profitTab}
                        exclusive
                        className={styles.group}
                      >
                        {ProfitSettingsTabs.map((tab) => (
                          <ButtonGroupRadioButton
                            value={tab.value}
                            key={tab.value}
                            className={cls({ [styles.bgGrey]: tab.value === profitTab })}
                          >
                            {tab.label}
                          </ButtonGroupRadioButton>
                        ))}
                      </ButtonGroupRadio>

                      {profitTab === ProfitSettingsTabs[0].value && (
                        <div>
                          <div className={styles.labelContent}>
                            <InputLabel className={styles.label}>
                              <Typography variant="body2" textColor="light-grey">
                                Sell in steps
                              </Typography>
                            </InputLabel>
                            <TooltipIcon
                              info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%."
                              marginLeft={5}
                            />
                          </div>
                          {walletsOnProfit.length !== 0 ? (
                            <div className={styles.stepWrapper}>
                              {walletsOnProfit.map((wallet, index) => (
                                <Controller
                                  key={wallet}
                                  name={`w${wallet}` as keyof typeof defaultValues}
                                  control={control}
                                  render={({ field: { ref, ...field } }) => (
                                    <StepInput
                                      wallet="#1"
                                      balance="12.58 ETH"
                                      className={cls({
                                        [styles.firstInput]: index === 0,
                                        [styles.lastInput]: index === walletsOnProfit.length - 1,
                                      })}
                                      {...field}
                                    />
                                  )}
                                />
                              ))}
                            </div>
                          ) : (
                            <Grid marginTop={1}>
                              <Typography variant="body1">No wallets selected</Typography>
                            </Grid>
                          )}
                        </div>
                      )}
                      {profitTab === ProfitSettingsTabs[1].value && (
                        <Grid container flexWrap="nowrap" columnGap={3}>
                          <Controller
                            name="sellOnProfit"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter sell on profit"
                                isNumeric
                                label="Sell on Profit"
                                adornmentText="%"
                                tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="sellOnProfitAmount"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter sell profit amount"
                                isNumeric
                                label="Sell Profit Amount"
                                adornmentText="%"
                                tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {profitTab === ProfitSettingsTabs[2].value ? (
                        <>
                          <Controller
                            name="walletsToTrailingSell"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <SelectWallet
                                isMulti
                                wallet={value}
                                label="Wallets to trailing sell"
                                tooltipInfo="Select which wallets will be used to purchase token."
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="trailingSell"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter trailing sell"
                                isNumeric
                                label="Trailing Sell"
                                adornmentText="%"
                                tooltipInfo="tooltipInfo"
                                {...field}
                              />
                            )}
                          />
                        </>
                      ) : (
                        <>
                          <div className={styles.dividerContainer}>
                            <Typography variant="body2" textColor="grey">
                              Loss
                            </Typography>
                            <div className={styles.divider}></div>
                          </div>

                          <Controller
                            name="walletsToSellOnLoss"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <SelectWallet
                                label="Wallets to sell on Loss"
                                tooltipInfo="tooltipInfo"
                                isMulti
                                wallet={value}
                                {...field}
                              />
                            )}
                          />

                          <Grid container flexWrap="nowrap" columnGap={3}>
                            <Controller
                              name="stopLoss"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter amount"
                                  isNumeric
                                  label="Stop Loss"
                                  tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                                  adornmentText="%"
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="stopLossAmount"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter amount"
                                  isNumeric
                                  label="Stop Loss Amount"
                                  tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                                  adornmentText="%"
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </HiddenInputs>
                </Grid>
              ),
            },
          ]}
        />
        <Grid container columnGap={3} flexWrap="nowrap" marginTop={2}>
          <Button styleVariant="black" onClick={handleChangeMainTab} disabled={isLastMainTab}>
            Continue
          </Button>
          <Button type="submit" disabled={!isLastMainTab}>
            Add
          </Button>
        </Grid>
      </form>
    </Stack>
  )
}

export { AddNewScrapeTemplate }
