import { FC } from 'react'

import cn from 'classnames'

import { ButtonIcon } from '@/libs/common/button-icon'
import { IconName } from '@/libs/enums'
import { ESorting } from '@/libs/enums/sorting.enum'

import styles from './styles.module.scss'

type TProps = {
  sorting: ESorting
  handleSorting: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const ButtonSort: FC<TProps> = ({ sorting, handleSorting }) => {
  return (
    <div className={cn(styles.container, styles[sorting])}>
      <ButtonIcon icon={IconName.FILTER} onClick={handleSorting} />
    </div>
  )
}

export { ButtonSort }
