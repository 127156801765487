const TabName = {
  DASHBOARD_NAV: 'dashboardNav',
  BUY_SELL: 'buySell',
  BUY_SELL_LITE: 'buySellLite',
  PENDING_ORDERS: 'pendingOrders',
  DASHBOARD_TABLE: 'dashboardTable',
  COPY_TRADE_TABLE: 'copyTradeTable',
  SCRAPE_TABLE: 'scrapeTable',
  SETTING_NAV: 'settingNav',
  Wallet_DETAILS: 'walletDetails',
}

export { TabName }
