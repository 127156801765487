import { useNavigate } from 'react-router-dom'

import { AuthCard } from '@/components/auth-card'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { Button, Icon } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config'
import { AppMode, AppRoute, IconName, LocalStorageItem, VerificationStatus } from '@/libs/enums'

import styles from './styles.module.scss'

const SetupSuccess = () => {
  const navigate = useNavigate()
  const handleNewTokenSelection = useSelectNewToken()

  const redirectToDashboard = async () => {
    localStorage.removeItem('signData')
    localStorage.setItem(LocalStorageItem.MODE, AppMode.LITE)
    handleNewTokenSelection(DEFAULT_TOKEN, {
      skipTokenSetToUrl: true,
      customAppMode: AppMode.LITE,
      isForceNavigate: true,
    })
    if (localStorage.code) {
      navigate(`/${AppRoute.ADD_DEVICE}?code=${localStorage.code}`)
    }
  }
  return (
    <AuthCard
      verificationStatus={VerificationStatus.ENJOY_BLAZING_BOT}
      className={styles.card}
      isHeaderButtons
    >
      <div className={styles.content}>
        <div className={styles.desc}>
          <Icon name={IconName.SUCCESS} />
          <Typography variant="h1" align="center">
            Success
          </Typography>
          <Typography variant="body1" align="center">
            Your account is set up and your pro plan is now active, you can access the dashboard
          </Typography>
        </div>

        <Button onClick={redirectToDashboard}>Continue to dashboard</Button>
      </div>
    </AuthCard>
  )
}

export { SetupSuccess }
