import { FC } from 'react'

import { Box } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'

import styles from './styles.module.scss'

type TProps = {
  value: number
}

const LinearProgressBar: FC<TProps> = ({ value }) => {
  return (
    <Box
      sx={{
        height: 4,
        width: '100%',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        '& .MuiLinearProgress-colorInfo': {
          backgroundColor: '#303030',
        },
        '& .MuiLinearProgress-barColorInfo': {
          backgroundColor: '#ffc800',
        },
      }}
    >
      <LinearProgress
        variant="determinate"
        value={value}
        color="info"
        className={styles.progressBar}
      />
    </Box>
  )
}

export { LinearProgressBar }
