import { FC } from 'react'

import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import { IconNameToIcon } from './libs/maps/icon-name-to-icon'

type TProperty = {
  name: ValueOf<typeof IconName>
}

const Icon: FC<TProperty> = ({ name }) => {
  return <>{IconNameToIcon[name]}</>
}

export { Icon }
