import { FC, useMemo } from 'react'

import { Grid } from '@mui/material'

import { Typography } from '@/libs/common'
import { TemplateItem } from '@/pages/modal-page/libs/components/template-management/libs/components/template-item'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  selectedChainId: number
}

const Templates: FC<TProps> = ({ selectedChainId }) => {
  const userTemplates = useAppSelector((state) => state.user.userTemplates)

  const buyTemplates = useMemo(
    () =>
      userTemplates.buyTemplates?.filter(
        (item) => item.setup.network.blockchain === selectedChainId,
      ),
    [userTemplates, selectedChainId],
  )

  return (
    <Grid gap={3} container flexDirection="column" className={styles.container}>
      <Grid gap={2} container flexDirection="column">
        <Typography variant="body2" className={styles.title}>
          Manual Buy
        </Typography>

        {buyTemplates?.length ? (
          buyTemplates.map((item) => (
            <TemplateItem key={item.id} title={item.name} isDefault={false} templateId={item.id} />
          ))
        ) : (
          <Typography variant="body2" textAlign="center" marginBottom={2}>
            No templates
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export { Templates }
