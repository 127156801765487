type TGetResponsiveCanvasConfigProps = {
  windowWidth: number
  isPositionOpen: boolean
  width: number
  height: number
}

const getResponsiveCanvasConfig = ({
  windowWidth,
  isPositionOpen,
  width,
  height,
}: TGetResponsiveCanvasConfigProps) => {
  const isSmallDevice = windowWidth < 480
  const isMobileDevice = windowWidth < 768

  const titleGap = 30
  const tokenNameOffsetY = isPositionOpen ? 150 : 170
  const spendTitleOffsetY = isPositionOpen ? 275 : 115
  const spendEthOffsetY = isPositionOpen ? 298 : 233
  const spendUsdOffsetY = isPositionOpen ? 318 : 247
  const tplOffsetY = isPositionOpen ? 225 : 215
  const logoTitleOffsetY = isPositionOpen ? 60 : 67

  const mobileLogoWidth = Math.min(...[width / 10, 50])
  const profitValuesOffsetTop = mobileLogoWidth * 0.8 + height / 6

  if (isSmallDevice) {
    return {
      tokenNameOffsetX: 14,
      tokenNameOffsetY: tokenNameOffsetY - (mobileLogoWidth + titleGap - 10),
      spendTitleOffsetY: height - profitValuesOffsetTop,
      spendETHValueOffsetY: height - profitValuesOffsetTop + 20,
      spendUsdValueOffsetY: height - profitValuesOffsetTop + 40,
      revenueTitleOffsetX: 120,
      tplOffsetY: tplOffsetY - 105,
      logoTextOffsetX: 67,
      logoTextOffsetY: logoTitleOffsetY - 30,
    }
  }

  if (isMobileDevice) {
    return {
      tokenNameOffsetX: 24,
      tokenNameOffsetY: tokenNameOffsetY - 40,
      spendTitleOffsetY: spendTitleOffsetY - 70,
      spendETHValueOffsetY: spendEthOffsetY - 70,
      spendUsdValueOffsetY: spendUsdOffsetY - 70,
      revenueTitleOffsetX: 135,
      tplOffsetY: tplOffsetY - 60,
      logoTextOffsetX: 95,
      logoTextOffsetY: logoTitleOffsetY - 30,
    }
  }

  return {
    tokenNameOffsetX: 44,
    tokenNameOffsetY: tokenNameOffsetY,
    spendTitleOffsetY: spendTitleOffsetY,
    spendETHValueOffsetY: spendEthOffsetY,
    spendUsdValueOffsetY: spendUsdOffsetY,
    revenueTitleOffsetX: 200,
    tplOffsetX: 44,
    tplOffsetY,
    logoTextOffsetX: 107,
    logoTextOffsetY: logoTitleOffsetY,
  }
}

export { getResponsiveCanvasConfig }
