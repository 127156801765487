import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { renameWallet } from '@/api/wallet'
import { Button, Input } from '@/libs/common'
import { AppMode, AppRoute } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserWallets } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type WalletNameType = {
  walletName: string
}

const walletNameSchema = yup.object({
  walletName: yup
    .string()
    .min(5, 'Wallet name must be at least 5 characters')
    .max(30)
    .required('Enter wallet name'),
})

const defaultValues: WalletNameType = {
  walletName: 'Wallet1121133',
}

const EditWalletName: FC = () => {
  const user = useAppSelector((state) => state.user.userData)
  const wallets = useAppSelector((state) => state.user.userWallets)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { walletAddress } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Edit Wallet Name', withBackButton: true })
  }, [])

  const currentWallet = useMemo(
    () => wallets?.find((item) => item.address === walletAddress),
    [walletAddress, wallets],
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { walletName: currentWallet?.name || '' },
    resolver: yupResolver(walletNameSchema),
  })

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!user || !currentWallet) return
    try {
      setIsLoading(true)
      const { data: res } = await renameWallet({
        name: data.walletName.trim(),
        address: currentWallet.address,
      })
      dispatch(setUserWallets(res.data))
      navigate(
        `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`,
      )
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="walletName"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Input
              error={Boolean(errors.walletName)}
              errorMessage={errors.walletName?.message}
              maxLength={30}
              isTransparent
              label="Wallet name"
              placeholder="Enter wallet name"
              {...field}
            />
          )}
        />

        <Button type="submit" isLoading={isLoading} checkOnAccountLock>
          Save
        </Button>
      </form>
    </div>
  )
}

export { EditWalletName }
