import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AuthCard } from '@/components/auth-card'
import { Button, Typography } from '@/libs/common'
import { ListChain } from '@/libs/common/list-chain'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config.ts'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import { chains } from './chains.ts'
import styles from './styles.module.scss'

export const AddChain = () => {
  const navigate = useNavigate()
  const [activated, setActivate] = useState<boolean>(false)
  const userData = useAppSelector((state) => state.user.userData)
  const myChains = useMemo(
    () =>
      chains.map((chain) => {
        return {
          ...chain,
          connected: userData?.accounts.some((item) => chain.indexerChainId === item.blockchain_id),
        }
      }),
    [userData],
  )
  return (
    <>
      <AuthCard
        className={styles.card}
        footerLink={{
          title: 'Go to dashboard',
          link: `${AppRoute.DASHBOARD}/${AppMode.PRO}?token=${DEFAULT_TOKEN}`,
        }}
      >
        <div className={styles.content}>
          <div className={styles.titleWrapper}>
            <Typography variant="h1" className={styles.title} align="center">
              Which chain would you like to add?
            </Typography>
          </div>

          <div className={styles.chainsList}>
            {myChains.map((chain) => (
              <ListChain
                iconName={(IconName as any)[chain.iconName]}
                title={`${chain.label} Chain`}
                description={chain.description}
                chainId={chain.indexerChainId}
                key={chain.iconName}
                status={chain.connected}
                onStatusChange={() => setActivate(true)}
              />
            ))}
          </div>

          {activated && (
            <Button
              styleVariant="black"
              onClick={() =>
                navigate(
                  localStorage.code
                    ? `/${AppRoute.ADD_DEVICE}?code=${localStorage.code}`
                    : `${AppRoute.DASHBOARD}/${AppMode.PRO}?token=${DEFAULT_TOKEN}`,
                )
              }
            >
              Done
            </Button>
          )}
        </div>
      </AuthCard>
    </>
  )
}
