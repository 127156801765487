import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  TActivePanelItem,
  TPanelsDataSocketResponse,
} from '@/libs/types/panels-data-socket-response.type'

type TStoreError = {
  profitSellFailed: boolean
}

type TInitialState = {
  profitSell: TPanelsDataSocketResponse | null
  currentPositionId: string | null
  isLoading: boolean
  error: TStoreError
}

const initialStatus: TStoreError = {
  profitSellFailed: false,
}

const initialState: TInitialState = {
  isLoading: false,
  profitSell: null,
  currentPositionId: null,
  error: initialStatus,
}

const profitSellSlice = createSlice({
  name: 'profitSell',
  initialState,
  reducers: {
    setCurrentPositionId: (state, { payload }: PayloadAction<string | null>) => {
      state.currentPositionId = payload
    },
    setUserProfitPanels: (state, { payload }: PayloadAction<TPanelsDataSocketResponse>) => {
      state.profitSell = payload
    },
    clearUserSellPanels: (state) => {
      if (state.profitSell?.sp) state.profitSell.sp = []
    },
  },
})

const { setUserProfitPanels, clearUserSellPanels, setCurrentPositionId } = profitSellSlice.actions

export {
  profitSellSlice,

  // Reducers
  setUserProfitPanels,
  clearUserSellPanels,
  setCurrentPositionId,
}
