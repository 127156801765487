import { FC } from 'react'

import { ConnectWallet } from '@/components/header/libs/components/connect-wallet'

const WalletConnect: FC = () => {
  return (
    <div>
      <ConnectWallet isAuth />
    </div>
  )
}

export { WalletConnect }
