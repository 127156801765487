import { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Grid, InputLabel } from '@mui/material'
import cls from 'classnames'

import { SelectWallet } from '@/components/select-wallet'
import {
  Accordion,
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  Icon,
  InputWithRadioGroup,
  StepInput,
  Typography,
} from '@/libs/common'
import { ControlledAccordion } from '@/libs/common/controlled-accordion'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { IconName } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const spendOptions: TSelectOption<number>[] = [
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.5,
    label: '50%',
  },
  {
    value: 0.75,
    label: '75%',
  },
  {
    value: 1,
    label: '100%',
  },
]

// @TODO remove duplicate data
const ProfitSettingsTabs: TSelectOption<string>[] = [
  { value: 'sellInSteps', label: 'Sell in steps' },
  { value: 'sellOnProfit', label: 'Sell on profit' },
  { value: 'trailingSell', label: 'Trailing sell' },
]

const defaultValues = {
  firstBundleOrFail: '',
  firstBundleBuy: '',
  backupBundle: '',
  backupBundleBuy: '',
  maxTxOrFail: false,
  spend: undefined,
  slippage: 25,
  selectedWallets: [],
  // Snipe settings
  mempoolBackupBuy: '',
  minPercentForFail: '',
  approvePriority: '',
  transferOnBlacklist: false,
  antiBlacklist: false,
  antiRug: false,
  antiRugThreshold: 50,
  sellRugExtraTip: '',
  maxBuyTax: '',
  maxSellTax: '',
  minLiquidity: '',
  maxLiquidity: '',
  maxMarketcap: '',
  autoSell: '',
  walletsToSellOnLoss: [],
  walletsToSellOnProfit: [],
  sellPriority: '',
  selectedWalletsForProfit: [],
  selectedWalletsForLoss: [],
  stopLoss: '',
  stopLossAmount: '',
  firstStep: '25',
  secondStep: '32',
  thirdStep: '40',
  sellOnProfit: '',
  sellOnProfitAmount: '',
  walletsToTrailingSell: [],
  trailingSell: '',
}

type TProperty = {
  buttonName: string
}

const ManualSnipeForm: FC<TProperty> = ({ buttonName }) => {
  const userWallets = useAppSelector((state) => state.user.userWallets)

  const [availableTokens, setAvailableTokens] = useState(0)
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)

  const [isFirstBundle, setIsFirstBundle] = useState(false)
  const [isBackupBundle, setIsBackupBundle] = useState(false)
  const [isAutoSell, setIsAutoSell] = useState(false)
  const [selectedWallets, setSelectedWallets] = useState<number[]>([])
  const [profitTab, setProfitTab] = useState('sellInSteps')

  const { control, handleSubmit } = useForm({
    defaultValues,
  })

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }

  const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
    setProfitTab(newValue)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowGap={2} marginTop={2}>
        <Accordion
          className={styles.accordion}
          titleClassName={styles.subTitle}
          title={'Advanced Snipe Settings'}
          withDivider
          mainColorArrow
        >
          <Grid container rowGap={2} marginTop={1} marginBottom={3}>
            <Typography variant="body1" className={styles.subTitle}>
              Buy
            </Typography>
            <Controller
              name="mempoolBackupBuy"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder="Amount"
                  isNumeric
                  label="Mempool Backup Buy"
                  isOptional
                  tooltipInfo="It will send mempool transaction with your other type of transactions, in case there is a bad block or any other situation that may arise it is very helpful to try with mempool too (This will send in same block along with bribe and backup bribe if selected)."
                  {...field}
                />
              )}
            />
            <Grid container flexWrap="nowrap" columnGap={3}>
              <Controller
                name="minPercentForFail"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder="ETH amount"
                    isNumeric
                    label="Min % / Tokens or fail"
                    tooltipInfo="Minimal percentage of total supply or exact amount in ETH equivalent of tokens that should be bought or the transaction will fail."
                    {...field}
                  />
                )}
              />
              <Controller
                name="approvePriority"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder="Enter gwei amount"
                    isNumeric
                    label="Approve priority"
                    tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
                    {...field}
                  />
                )}
              />
            </Grid>
            <Typography variant="body1" className={styles.subTitle} marginTop={1}>
              Safety
            </Typography>
            <Grid container flexWrap="nowrap" columnGap={3}>
              <Controller
                name="transferOnBlacklist"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <SwitchInline
                    label="Transfer On Blacklist"
                    tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                    {...field}
                  />
                )}
              />
              <Controller
                name="antiBlacklist"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <SwitchInline
                    label="Anti-Blacklist"
                    tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                    {...field}
                  />
                )}
              />
            </Grid>

            <HiddenInputs
              expanded={isAntiRugSettings}
              trigger={
                <Controller
                  name="antiRug"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <SwitchInline
                      label="Anti-Rug"
                      tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                      onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        setIsAntiRugSettings(checked)
                        onChange(checked)
                      }}
                      {...field}
                    />
                  )}
                />
              }
            >
              <Grid container rowGap={2}>
                <Controller
                  name="antiRugThreshold"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <PercentsInput
                      label="Anti-Rug Threshold"
                      onOptionSelect={(value) => field.onChange(value)}
                      tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="sellRugExtraTip"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      placeholder="Amount"
                      isNumeric
                      label="Sell Rug Extra Tip"
                      tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                      {...field}
                    />
                  )}
                />
              </Grid>
            </HiddenInputs>

            <Grid container flexWrap="nowrap" columnGap={3}>
              <Controller
                name="maxBuyTax"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder="Enter percentage"
                    isNumeric
                    label="Max Buy Tax"
                    adornmentText="%"
                    tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                    {...field}
                  />
                )}
              />
              <Controller
                name="maxSellTax"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder="Enter percentage"
                    isNumeric
                    label="Max Sell Tax"
                    adornmentText="%"
                    tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid container flexWrap="nowrap" columnGap={3}>
              <Controller
                name="minLiquidity"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder="Enter min liquidity amount"
                    isNumeric
                    label="Min Liquidity"
                    adornmentText="$"
                    tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
                    {...field}
                  />
                )}
              />
              <Controller
                name="maxLiquidity"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder="Enter max liquidity amount"
                    isNumeric
                    label="Max Liquidity"
                    adornmentText="$"
                    tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
                    {...field}
                  />
                )}
              />
            </Grid>
            <Controller
              name="maxMarketcap"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder="Enter max marketcap"
                  isNumeric
                  label="Max Marketcap"
                  adornmentText="$"
                  tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
                  {...field}
                />
              )}
            />
            <Typography variant="body1" className={styles.subTitle} marginTop={1}>
              Sell
            </Typography>

            <Grid container rowGap={2}>
              <Controller
                name="autoSell"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <SwitchInline
                    label="Auto Sell"
                    tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                    onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                      setIsAutoSell(checked)
                      onChange(checked)
                    }}
                    {...field}
                  />
                )}
              />

              <ControlledAccordion expanded={isAutoSell}>
                <Grid container rowGap={2}>
                  <Controller
                    name="sellPriority"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <OptionalInput
                        className={styles.optionalInput}
                        placeholder="Enter gwei amount"
                        isNumeric
                        label="Sell Priority"
                        onAutoChange={() => {
                          // Hendle auto toggle change
                        }}
                        tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                        onExpand={setIsFirstBundle}
                        {...field}
                      />
                    )}
                  />
                  <Grid container alignItems="center">
                    <Typography variant="body2" textColor="grey">
                      Profit
                    </Typography>

                    <div className={styles.divider}></div>
                  </Grid>

                  <Controller
                    name="walletsToSellOnProfit"
                    control={control}
                    render={({ field: { ref, value, onChange, ...field } }) => {
                      setSelectedWallets(value)

                      return (
                        <SelectWallet
                          isMulti
                          tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                          wallet={value}
                          wallets={userWallets}
                          onChange={(value) => {
                            onChange(value)
                            let availableBalance = 0
                            value.forEach((walletPosition) => {
                              const currentBalance =
                                userWallets?.[+walletPosition - 1]?.balanceFormatted
                              if (currentBalance) {
                                availableBalance += +currentBalance
                              }
                            })
                            setAvailableTokens(availableBalance)
                          }}
                          {...field}
                          label="Wallets to sell on Profit"
                        />
                      )
                    }}
                  />

                  <ButtonGroupRadio
                    onChange={handleProfitTabChange}
                    value={profitTab}
                    exclusive
                    className={styles.group}
                  >
                    {ProfitSettingsTabs.map(({ value, label }) => (
                      <ButtonGroupRadioButton
                        value={value}
                        key={value}
                        className={cls({ [styles.bgGrey]: value === profitTab })}
                      >
                        {label}
                      </ButtonGroupRadioButton>
                    ))}
                  </ButtonGroupRadio>

                  {profitTab === 'sellInSteps' && selectedWallets.length > 0 && (
                    <div className={styles.stepContainer}>
                      <div className={styles.labelContent}>
                        <InputLabel className={styles.label}>Sell in steps</InputLabel>

                        <TooltipIcon
                          info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%."
                          marginLeft={5}
                        />
                      </div>

                      <div className={styles.stepWrapper}>
                        <Controller
                          name="firstStep"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <StepInput
                              wallet="#1"
                              balance="12.58 ETH"
                              className={styles.stepInput}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="secondStep"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <StepInput
                              wallet="#2"
                              balance="12.58 ETH"
                              {...field}
                              className={styles.stepInput}
                            />
                          )}
                        />

                        <Controller
                          name="thirdStep"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <StepInput
                              wallet="#3"
                              balance="12.58 ETH"
                              className={cls(styles.stepInput, styles.lastStepInput)}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}

                  {profitTab === 'sellInSteps' && !selectedWallets.length && (
                    <Typography variant="body1" className={styles.walletsDescr}>
                      No wallets selected
                    </Typography>
                  )}

                  {profitTab === 'sellOnProfit' && (
                    <Grid container flexWrap="nowrap" columnGap={3}>
                      <Controller
                        name="sellOnProfit"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter sell on profit"
                            isNumeric
                            label="Sell on Profit"
                            adornmentText="%"
                            tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="sellOnProfitAmount"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter sell profit amount"
                            isNumeric
                            label="Sell Profit Amount"
                            adornmentText="%"
                            tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {profitTab === 'trailingSell' ? (
                    <>
                      <Controller
                        name="walletsToTrailingSell"
                        control={control}
                        render={({ field: { ref, value, ...field } }) => (
                          <SelectWallet
                            isMulti
                            wallet={value}
                            label="Wallets to trailing sell"
                            tooltipInfo="Select which wallets will be used to purchase token."
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="trailingSell"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter trailing sell"
                            isNumeric
                            label="Trailing Sell"
                            adornmentText="%"
                            tooltipInfo="tooltipInfo"
                            {...field}
                          />
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <Grid container alignItems="center">
                        <Typography variant="body2" textColor="grey">
                          Loss
                        </Typography>

                        <div className={styles.divider}></div>
                      </Grid>

                      <Controller
                        name="selectedWalletsForLoss"
                        control={control}
                        render={({ field: { ref, value, ...field } }) => (
                          <SelectWallet
                            isMulti
                            wallet={value}
                            label="Wallets to sell on Stop Loss"
                            tooltipInfo="Select how many wallets will sell when the loss threshold is reached. For example, 1st wallet will sell at 20% loss, 2nd wallet will sell at 30% loss."
                            {...field}
                          />
                        )}
                      />

                      <Grid container flexWrap="nowrap" columnGap={3}>
                        <Controller
                          name="stopLoss"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Enter sell on loss %"
                              isNumeric
                              label="Stop Loss"
                              adornmentText="%"
                              tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="stopLossAmount"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Enter sell on loss amount"
                              isNumeric
                              label="Stop Loss Amount"
                              adornmentText="%"
                              tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </ControlledAccordion>
            </Grid>
          </Grid>
        </Accordion>
        <Grid container rowGap={isFirstBundle ? 2 : 0}>
          <Controller
            name="firstBundleOrFail"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                placeholder="Amount"
                isNumeric
                label="First Bundle or fail"
                isOptional
                tooltipInfo="Be the first buyer or don't buy anything. If enabled, your transaction will only proceed if it was in the first block (bundle)."
                onExpand={setIsFirstBundle}
                {...field}
              />
            )}
          />
          <Controller
            name="firstBundleBuy"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <ControlledAccordion expanded={isFirstBundle}>
                <OptionalInput
                  placeholder="Amount"
                  isNumeric
                  label="First Bundle Buy (Tip)"
                  tooltipInfo="tooltipInfo"
                  {...field}
                />
              </ControlledAccordion>
            )}
          />
        </Grid>
        <Grid container rowGap={isBackupBundle ? 2 : 0}>
          <Controller
            name="backupBundle"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                placeholder="Amount"
                isNumeric
                label="Backup Bundle"
                isOptional
                tooltipInfo="Sends a backup bundle together with main bundle or just backup bundle if only selected backup - It will be accepted anywhere in the block, not just 1st positions like first bundle or fail"
                onExpand={setIsBackupBundle}
                {...field}
              />
            )}
          />
          <Controller
            name="backupBundleBuy"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <ControlledAccordion expanded={isBackupBundle}>
                <OptionalInput
                  placeholder="Amount"
                  isNumeric
                  label="Backup Bundle Buy"
                  tooltipInfo="The amount of ETH that will be spent to buy token at backup transaction."
                  {...field}
                />
              </ControlledAccordion>
            )}
          />
        </Grid>
        <Controller
          name="maxTxOrFail"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <SwitchInline
              label="Max Tx or fail"
              tooltipInfo="Will try to buy maximum available amount of tokens in one transaction. The transaction will fail if this cannot be done."
              {...field}
            />
          )}
        />
        <Controller
          name="slippage"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <PercentsInput
              label="Slippage"
              onOptionSelect={(value) => field.onChange(value)}
              tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
              {...field}
            />
          )}
        />
        <Controller
          name="selectedWallets"
          control={control}
          render={({ field: { ref, value, ...field } }) => (
            <SelectWallet
              isMulti
              wallet={value}
              {...field}
              tooltipInfo="Select which wallets will be used to purchase token."
            />
          )}
        />
        <Grid container className={styles.dividerWithArrow}>
          <div className={styles.dividerLine} />
          <Icon name={IconName.ARROW_DOWN_GREY} />
          <div className={styles.dividerLine} />
        </Grid>
        <Controller
          name="spend"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputWithRadioGroup
              label="Spend"
              placeholder="Enter amount to spend"
              isNumeric
              onOptionSelect={(value) => field.onChange(value)}
              endAdornment={<EndAdornment label="ETH" icon={IconName.ETHEREUM} />}
              radioGroupName="amount"
              options={spendOptions}
              {...field}
            />
          )}
        />
      </Grid>
      <div className={styles.available}>
        <Typography variant="body2" className={styles.availableParam}>
          Available:
        </Typography>
        <Typography variant="body2" className={styles.availableValue}>
          {availableTokens} ETH
        </Typography>
      </div>
      <Button type="submit" className={styles.submit}>
        {buttonName}
      </Button>
    </form>
  )
}

export { ManualSnipeForm }
