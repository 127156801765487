import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Grid } from '@mui/material'

import { ButtonIcon, Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

const PositionCardMessage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const removeSellCardIdParam = () => {
    if (searchParams.has('sellCardId')) {
      searchParams.delete('sellCardId')
      setSearchParams(searchParams)
    }
  }

  return (
    <Grid className={styles.message}>
      <div className={styles.titleWrapper}>
        <Typography variant="body1" className={styles.title}>
          <Icon name={IconName.CHECK} />
          Position sold successfully!
        </Typography>
        <ButtonIcon icon={IconName.CLOSE} onClick={removeSellCardIdParam} />
      </div>

      <div className={styles.descWrapper}>
        <Typography variant="body2" textColor="light-grey">
          12.580000 PEPE
        </Typography>

        <div className={styles.arrow}>
          <Icon name={IconName.ARROW_RIGHT} />
        </div>

        <Typography variant="body2" textColor="light-grey">
          2.344546 ETH
        </Typography>
      </div>
    </Grid>
  )
}

export { PositionCardMessage }
