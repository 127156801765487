import { FC, useEffect, useState } from 'react'

import cn from 'classnames'

import { HoldingsList } from '@/components/holdings-list'
import { useHoldingsWithSettings } from '@/hooks/useHoldingsWithSettings.hook'
import { Accordion, Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = {
  isOpen?: boolean
}

const HoldingsAccordion: FC<TProps> = ({ isOpen }) => {
  const [expanded, setExpanded] = useState(false)
  const [isValid, setIsValid] = useState(true)

  const { filteredHoldings, holdingsSettings, toggleSort } = useHoldingsWithSettings()

  useEffect(() => {
    if (!isOpen && expanded) {
      setExpanded(false)
    }
  }, [isOpen])

  return (
    <Accordion
      title={
        <div className={styles.titleGroup}>
          <Typography
            className={cn(styles.titleTab, { [styles.activeTab]: isValid })}
            onClick={(e) => {
              e.stopPropagation()
              setIsValid(true)
            }}
          >
            Holdings
          </Typography>
          <span className={styles.titleTab}>/</span>
          <Typography
            className={cn(styles.titleTab, { [styles.activeTab]: !isValid })}
            onClick={(e) => {
              e.stopPropagation()
              setIsValid(false)
            }}
          >
            Honeypot
          </Typography>
        </div>
      }
      headerIcon={<Icon name={IconName.HOLDINGS_GREY} />}
      titleClassName={styles.title}
      enableSorting={isValid && !!filteredHoldings}
      sorting={holdingsSettings.sorting}
      onSortingClick={toggleSort}
      contentWithScroll="holdings"
      expanded={expanded}
      onChange={setExpanded}
    >
      <HoldingsList isValid={isValid} />
    </Accordion>
  )
}

export { HoldingsAccordion }
