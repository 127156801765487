import { FC } from 'react'

import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton'

import styles from './styles.module.scss'

const ButtonGroupRadioButton: FC<ToggleButtonProps> = ({ children, ...props }) => {
  return (
    <ToggleButton classes={styles} fullWidth {...props}>
      {children}
    </ToggleButton>
  )
}

export { ButtonGroupRadioButton }
