import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId.ts'

const BASE_URL = 'api/v1/device'

const getDeviceCodeInfo = (code: string) => api.get(`${BASE_URL}/authentication/${code}/get?l=en`)
const getDeviceCodeStatus = (code: string) =>
  api.get(`${BASE_URL}/authentication/${code}/status?l=en`)

const updateDeviceCodeStatus = (data: object) => {
  const userId = getCurrentUserId()
  return api.post(`${BASE_URL}/authentication/${userId}/update?l=en`, data)
}

export { getDeviceCodeInfo, getDeviceCodeStatus, updateDeviceCodeStatus }
