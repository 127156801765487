import { useEffect } from 'react'

import { SellTab } from '@/components/dashboard-component-lite/libs/buy-sell-block/libs/sell-tab'
import { useModal } from '@/pages/modal-page/modal-page'

const LiteSell = () => {
  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Sell' })
  }, [])

  return (
    <>
      <SellTab />
    </>
  )
}

export { LiteSell }
