import { FC, PropsWithChildren } from 'react'

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import cls from 'classnames'

import styles from './styles.module.scss'

type TProps = PropsWithChildren & {
  expanded: boolean
  className?: string
}

const ControlledAccordion: FC<TProps> = ({ children, expanded = false, className }) => {
  return (
    <Accordion expanded={expanded} className={cls(styles.accordion, className)}>
      <AccordionSummary className={styles.accordionHeader}></AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  )
}

export { ControlledAccordion }
