const formatNumberWithCommas = (number: number) => {
  const [integerPart, decimalPart] = number.toFixed(4).toString().split('.')

  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  const trimmedDecimalPart = decimalPart ? decimalPart.replace(/0+$/, '') : ''

  const formattedNumber = trimmedDecimalPart
    ? `${formattedIntegerPart}.${trimmedDecimalPart}`
    : formattedIntegerPart

  return formattedNumber
}

export { formatNumberWithCommas }
