import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'

import { getSetup, setSetup } from '@/api/user'
import { useAppMode } from '@/hooks/useAppMode'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { AppRoute, IconName } from '@/libs/enums'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserPrioritySettings } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const defaultValues = {
  approve_priority: '',
  buy_priority: '',
  sell_priority: '',
}

const SetupPriorities = () => {
  const { mode } = useAppMode()
  const tokenAddress = useAppSelector((state) => state.chain.currentToken?.token.address)
  const navigate = useNavigate()
  const userData = useAppSelector((state) => state.user.userData)

  const { isBottomTabsVisible } = useWindowDimensions()

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Setup Default Priorities', withBackButton: isBottomTabsVisible })
  }, [])

  useEffect(() => {
    if (!userData) return
    const setSetupValues = async () => {
      const { data } = await getSetup(userData.user_id)
      return {
        approve_priority: data.data.approve_priority,
        buy_priority: data.data.buy_priority,
        sell_priority: data.data.sell_priority,
      }
    }
    setSetupValues().then((res) => {
      Object.keys(res).forEach((key: any) => {
        setValue(key, (res as any)[key].toString())
      })
    })
  }, [userData])
  const dispatch = useAppDispatch()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues })

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    if (!userData) return
    setSetup(userData.user_id, { ...data, blockchain: 1 })
    dispatch(setUserPrioritySettings(data))
    navigate(
      `${AppRoute.DASHBOARD}/${mode}${isBottomTabsVisible ? `/${AppRoute.MODAL}/${AppRoute.BUY_MODAL}` : ''}${tokenAddress ? `?token=${tokenAddress}` : ''}`,
    )
  }

  return (
    <Grid spacing={3} gap={2}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" gap={2}>
          <Grid container rowGap={2}>
            <Controller
              name="buy_priority"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  className={styles.input}
                  label="Default Buy Priority"
                  placeholder="Enter gwei amount"
                  isNumeric
                  tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                  endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                  error={!!errors.buy_priority?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="sell_priority"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  className={styles.input}
                  label="Default Sell Priority"
                  placeholder="Enter gwei amount"
                  isNumeric
                  tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                  endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                  error={!!errors.sell_priority?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="approve_priority"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  className={styles.input}
                  label="Default Approve priority"
                  placeholder="Enter gwei amount"
                  isNumeric
                  tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
                  endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                  error={!!errors.approve_priority?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>

        <Button type="submit" className={styles.button}>
          Save
        </Button>
      </form>
    </Grid>
  )
}

export { SetupPriorities }
