import { FC, ReactNode } from 'react'

import cn from 'classnames'

import { Typography } from '@/libs/common'
import { TooltipIcon } from '@/libs/common/tooltip-icon'

import styles from './styles.module.scss'

type TProps = {
  title: string
  value?: ReactNode | string | number
  isGreen?: boolean
  children?: ReactNode
  isDataAvailable?: boolean
  noDataValue?: string
  isCentered?: boolean
  className?: string
  tooltipInfo?: string
  componentToHide?: string
  currencySymbol?: string
  titleRightAlign?: boolean
}

const TokenDataItem: FC<TProps> = ({
  title,
  value,
  isGreen,
  children,
  isDataAvailable = true,
  noDataValue,
  isCentered,
  className,
  tooltipInfo,
  componentToHide = '',
  currencySymbol = '',
  titleRightAlign = false,
}) => (
  <div className={cn(styles[componentToHide])}>
    <div className={cn(styles.titleWrapper, { [styles.titleRightAlign]: titleRightAlign })}>
      <Typography
        variant="body1"
        className={cn(styles.tokenDataDescription, isCentered && styles.centered)}
      >
        {title}
      </Typography>
      {tooltipInfo && <TooltipIcon info={tooltipInfo} />}
    </div>

    {isDataAvailable ? (
      `${value}` ? (
        <Typography
          variant="body1"
          className={cn(
            styles.tokenDataInfo,
            isGreen && styles.colorGreen,
            isCentered && styles.centered,
            className,
          )}
        >
          {currencySymbol} {value}
        </Typography>
      ) : (
        children
      )
    ) : (
      <Typography variant="body1" className={cn(styles.tokenDataInfo, styles.centered)}>
        {noDataValue || '-'}
      </Typography>
    )}
  </div>
)

export { TokenDataItem }
