import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { generateWallets } from '@/api/wallet'
import { TGenerateWalletsResponse } from '@/api/wallet/types'
import { CustomToast } from '@/components/custom-toast'
import { PrivateKeysTable } from '@/components/private-keys-table'
// import { PrivateKeysTable } from '@/components/private-keys-table'
import { SelectWalletCount } from '@/components/select-wallet-count'
import { useAppMode } from '@/hooks/useAppMode'
import { Button, Checkbox, Typography } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import {
  clearUserWalletsUnconfirmed,
  fetchUserWalletsById,
  setUserWalletsUnconfirmed,
} from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type TProps = {
  duringSignUp?: boolean
  isModal?: boolean
}
const title = 'Generate new wallet(s)'
const GenerateNewWallet: FC<TProps> = ({ duringSignUp = false, isModal = true }) => {
  const { mode } = useAppMode()
  const userData = useAppSelector((state) => state.user.userData)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [counter, setCounter] = useState<number>()
  const [generatedWallets, setGeneratedWallets] = useState<TGenerateWalletsResponse[] | null>(null)
  const [check, setCheck] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheck(event.target.checked)
  }

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title, withBackButton: !duringSignUp })
  }, [])

  const handleChange = (newValue: number | string) => {
    if (newValue) {
      setCounter(+newValue)
      return
    }
    if (newValue !== undefined) setCounter(0)
  }

  const onGenerateWallets = async () => {
    if (!userData || !counter) return
    try {
      setIsLoading(true)
      const { data } = await generateWallets(userData.user_id, {
        amount: counter,
        blockchain: 1,
      })
      setGeneratedWallets(data.data)
      dispatch(setUserWalletsUnconfirmed(data.data))
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }

  const onAddWallets = async () => {
    if (!userData) return
    try {
      setIsLoading(true)
      await dispatch(fetchUserWalletsById(null)).unwrap()
      CustomToast('success', 'Your wallets were successfully generated')
      dispatch(clearUserWalletsUnconfirmed())
      navigate(duringSignUp ? AppRoute.SETUP_SUCCESS : `${AppRoute.DASHBOARD}/${mode}`, {
        replace: duringSignUp,
      })
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.container}>
      {!isModal && (
        <Typography variant="h1" align="center">
          {title}
        </Typography>
      )}
      <Typography variant="body1" align="center" className={styles.explanationWrapper}>
        Just choose the number of wallets you want and the bot will create them automatically. You
        will get their private keys right after generation.
      </Typography>
      <SelectWalletCount counter={counter} handleChange={handleChange} />

      {!!generatedWallets?.length && (
        <>
          <div className={styles.tableWrapper}>
            <PrivateKeysTable wallets={generatedWallets} />
            {/* <Button styleVariant="black">Export wallets file</Button> */}
          </div>
        </>
      )}
      {!!generatedWallets?.length && (
        <div className={styles.checkboxWrapper}>
          <label className={styles.label} htmlFor="agreements">
            <Checkbox whiteLabel value={check} onChange={onChangeCheckBox} id="agreements" />

            <Typography variant="body2">
              I have securely stored the private keys for the wallets and acknowledge that I will
              not have the ability to retrieve them if I forget my encryption password.
            </Typography>
          </label>
        </div>
      )}
      {generatedWallets?.length ? (
        <Button disabled={!check} onClick={onAddWallets} isLoading={isLoading}>
          Add wallets
        </Button>
      ) : (
        <Button
          disabled={!counter}
          onClick={onGenerateWallets}
          isLoading={isLoading}
          checkOnAccountLock
        >
          Generate wallets
        </Button>
      )}
    </div>
  )
}

export { GenerateNewWallet }
