export const ChipToOrderStatus = {
  pending: {
    label: 'Pending',
    color: 'grey2',
  },
  success: {
    label: 'Complete',
    color: 'green',
  },
  'partially completed': {
    label: 'Partially Completed',
    color: 'green',
  },
  successful: {
    label: 'Complete',
    color: 'green',
  },
  completed: {
    label: 'Complete',
    color: 'green',
  },
  dropped: {
    label: 'Canceled',
    color: 'grey2',
  },
  failed: {
    label: 'Error',
    color: 'red',
  },
} as const
