import { useEffect } from 'react'

import { HoldingsList } from '@/components/holdings-list'
import { Tabs } from '@/libs/common'
import { useModal } from '@/pages/modal-page/modal-page'

const HoldingsModal = () => {
  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Holdings' })
  }, [])

  return (
    <Tabs
      tabs={[
        { label: 'Holdings', content: <HoldingsList isValid={true} withSorting /> },
        { label: 'Honeypot', content: <HoldingsList isValid={false} /> },
      ]}
    />
  )
}

export { HoldingsModal }
