// Enum values are picked from the API side
enum ChainName {
  ETHEREUM = 'Eth',
  ARBITRUM = 'arbitrum',
  BNB = 'bnb',
  BASE = 'base',
  AVAX = 'avax',
}

export { ChainName }
