import { AuthCard } from '@/components/auth-card'
import { VerificationStatus } from '@/libs/enums'
import { ImportPrivateKeys } from '@/pages/modal-page/libs/components/import-private-keys'

import styles from './styles.module.scss'

const ImportKeys = () => {
  return (
    <AuthCard
      className={styles.section}
      verificationStatus={VerificationStatus.SETUP_WALLETS}
      isHeaderButtons
      isBackButton
    >
      <ImportPrivateKeys duringSignup />
    </AuthCard>
  )
}
export { ImportKeys }
