import { ManualBuyTemplateAction } from '@/libs/enums'

const getTemplateActionTitle = (query: string | null) => {
  switch (query) {
    case ManualBuyTemplateAction.ADD:
      return 'Add new Manual Buy Template'
    case ManualBuyTemplateAction.EDIT:
      return 'Edit Manual Buy Template'
    case 'add-snipe':
      return 'Add new Snipe Template'
    case 'edit-snipe':
      return 'Edit Snipe Template'
    case 'add-copytrade':
      return 'Add new copytrade wallet'
    case 'edit-copytrade':
      return 'Edit copytrade settings'
    default:
      return 'Unknown type'
  }
}

export { getTemplateActionTitle }
