import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { SelectWallet } from '@/components/select-wallet'
import { SettingField } from '@/components/setting-field'
import { TemplatesControl } from '@/components/templates-control'
import {
  BackButton,
  Button,
  ButtonLink,
  HiddenInputs,
  Icon,
  Input,
  Tabs,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { AppMode, AppRoute, IconName, ManualSettingsSection } from '@/libs/enums'
import { TSnipeTemplate } from '@/libs/types/snipe.type'

import styles from './styles.module.scss'

const defaultValues = {
  name: '',
  privateTransaction: false,
  degenChadMode: true,
  slippage: 0,
  selectedWallets: [],
  duplicateBuy: false,
  buyPriority: '',
  approvePriority: '',
  maxTxOrFail: true,
  transferOnBlacklist: false,
  antiBlacklist: true,
  antiRug: false,
  antiRugThreshold: 0,
  sellRugExtraTip: '12',
  maxBuyTax: '',
  maxSellTax: '',
  minLiquidity: '',
  maxLiquidity: '',
  maxMarketcap: '',
  autoSell: false,
  sellPriority: '',
  walletsToSellOnProfit: [],
  walletsToSellOnLoss: [],
  stopLoss: '',
  stopLossAmount: '',
  firstStep: '25',
  secondStep: '32',
  thirdStep: '40',
  amount: '',
  firstBundleOrFail: '',
  firstBundleBuy: '',
  backupBundle: '',
  backupBundleBuy: '',
  mempoolBackupBuy: '',
  minPercentForFail: '',
  selectedWalletsForProfit: [],
  selectedWalletsForLoss: [],
  sellOnProfit: '',
  sellOnProfitAmount: '',
  walletsToTrailingSell: [],
  trailingSell: '',
}

const mockData = {
  buy: {
    amount: '0.1 ETH',
    frontrunPriority: '20 GWEI',
    privateTransaction: true,
    walletsSelected: '2/6',
    approvePriority: '20 GWEI',
    slippage: 'Unlimited',
    buyPriority: '20 GWEI',
    degenChadMode: true,
  },
  safety: {
    antiRug: true,
    sellRugExtraTip: '20 GWEI',
    maxBuyTax: '30%',
    minLiquidity: '1000 USD',
    maxMcap: '30000 USD',
    antiRugThreshold: '30%',
    antiBlacklist: false,
    maxSellTax: '30%',
    maxLiquidity: '3000 USD',
    transferOnBlacklist: true,
  },
  sell: {
    frontrunSellPriority: '20 GWEI',
    autoSell: true,
    sellProfitAmount: '100%',
    walletsToSellOnLoss: '3/10',
    trailingSell: '20%',
    sellPriority: '20 GWEI',
    sellOnProfit: '100%',
    walletsToSellOnProfit: '3/10',
    sellInSteps: ['10x-2', '20x-2'],
    sellOnLoss: '-50%',
  },
}

// const ProfitSettingsTabs: TSelectOption<string>[] = [
//   { value: 'sellInSteps', label: 'Sell in steps' },
//   { value: 'sellOnProfit', label: 'Sell on profit' },
//   { value: 'trailingSell', label: 'Trailing sell' },
// ]

const mockTemplates: TSnipeTemplate[] = [
  {
    _id: '1',
    name: 'Manual',
  },
  {
    _id: '2',
    name: 'Fast Buy',
  },
  {
    _id: '3',
    name: 'Max profit',
  },
  {
    _id: '4',
    name: 'Quick trade',
  },
]

const AddNewScrape = () => {
  const [templates] = useState<TSnipeTemplate[]>(mockTemplates)
  const [currentTemplate] = useState<TSnipeTemplate | null>(mockTemplates[0])

  const [mainTab, setMainTab] = useState(0)
  // const [, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)
  const { handleSubmit, control } = useForm({ defaultValues })

  const isLastMainTab = mainTab === Object.values(ManualSettingsSection).length - 1

  const customizeTemplate = () => {
    // Implement template customization here
  }

  // const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
  //   setProfitTab(newValue)
  // }

  const handleChangeMainTab = () => {
    setMainTab((value) => (value < Object.values(ManualSettingsSection).length ? value + 1 : value))
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }

  return (
    <div>
      <BackButton isAbsolute />

      <Typography variant="h1" align="center">
        Add new scrape
      </Typography>

      <Grid className={styles.content}>
        <div className={styles.groupWrapper}>
          <div className={styles.groupTitleWrapper}>
            <Icon name={IconName.ETHEREUM} />

            <Typography variant="body1" textColor="light-grey" className={styles.groupName}>
              BlazingBot General
            </Typography>
          </div>

          <ButtonLink
            to={`${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.ADD_NEW_SCRAPE_GROUP}`}
          >
            <Icon name={IconName.CLOSE} />
          </ButtonLink>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <Grid container rowGap={3}>
            <Grid container rowGap={3}>
              <Grid className={styles.templates}>
                <TemplatesControl
                  templates={templates}
                  currentTemplateId={currentTemplate?._id || mockTemplates[0]._id}
                  onSelectTemplate={(id) => id}
                  onCustomize={customizeTemplate}
                  customizeDisabled={currentTemplate?._id === '1'}
                  editDisabled={currentTemplate?._id === '1'}
                  deleteDisabled={!!currentTemplate && ['1', '2'].includes(currentTemplate._id)}
                />
              </Grid>

              <Grid container>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <Input
                      isTransparent
                      className={styles.input}
                      label="Name"
                      placeholder="Enter name"
                      maxLength={15}
                      {...field}
                    />
                  )}
                />

                {currentTemplate === mockTemplates[0] && (
                  <Tabs
                    tabControl
                    value={mainTab}
                    tabs={[
                      {
                        label: ManualSettingsSection.BUY,
                        content: (
                          <Grid container rowGap={2}>
                            <Controller
                              name="amount"
                              control={control}
                              rules={{ required: true, min: 0 }}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  className={cn(styles.input, styles.inputSize)}
                                  label="Amount"
                                  type="number"
                                  placeholder="Enter gwei amount"
                                  isNumeric
                                  endAdornment={
                                    <EndAdornment label="ETH" icon={IconName.ETHEREUM} />
                                  }
                                  {...field}
                                />
                              )}
                            />

                            <Grid container columnGap={3} flexWrap="nowrap">
                              <Controller
                                name="privateTransaction"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <SwitchInline
                                    label="Anti-Mev"
                                    tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="degenChadMode"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <SwitchInline
                                    label="Degen Chad Mode"
                                    tooltipInfo="For advanced traders. If enabled, the safety rules from the simulation will be ignored."
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>

                            <Controller
                              name="slippage"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <PercentsInput
                                  label="Slippage"
                                  onOptionSelect={(value) => field.onChange(value)}
                                  tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                                  placeholder="X"
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="selectedWallets"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <SelectWallet
                                  isMulti
                                  wallet={value}
                                  {...field}
                                  tooltipInfo="Select which wallets will be used to purchase token."
                                />
                              )}
                            />

                            <Grid container display="flex" flexWrap="nowrap" gap={3}>
                              <Controller
                                name="buyPriority"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    className={styles.input}
                                    label="Buy Priority"
                                    placeholder="Enter gwei amount"
                                    isNumeric
                                    tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                                    onAutoChange={() => {
                                      //TO DO: added auto switch
                                    }}
                                    endAdornment={
                                      <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                                    }
                                    {...field}
                                  />
                                )}
                              />

                              <Controller
                                name="approvePriority"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    className={cn(styles.input, styles.inputSize)}
                                    label="Approve priority"
                                    placeholder="Enter gwei amount"
                                    isNumeric
                                    tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
                                    endAdornment={
                                      <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Controller
                              name="duplicateBuy"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <SwitchInline
                                  label="Duplicate Buy"
                                  tooltipInfo="If enabled, it will buy even if you already own that token, disable it and the bot will not buy if you own that token."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        ),
                      },
                      {
                        label: ManualSettingsSection.SAFETY,
                        content: (
                          <Grid container rowGap={2} flexDirection="column">
                            <Grid container columnGap={3} flexWrap="nowrap">
                              <Controller
                                name="transferOnBlacklist"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <SwitchInline
                                    label="Transfer On Blacklist"
                                    tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="antiBlacklist"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <SwitchInline
                                    label="Anti-Blacklist"
                                    tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>

                            <HiddenInputs
                              expanded={isAntiRugSettings}
                              trigger={
                                <Controller
                                  name="antiRug"
                                  control={control}
                                  render={({ field: { ref, onChange, ...field } }) => (
                                    <SwitchInline
                                      label="Anti-Rug"
                                      tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                                      onChange={(
                                        _: React.ChangeEvent<HTMLInputElement>,
                                        checked: boolean,
                                      ) => {
                                        setIsAntiRugSettings(checked)
                                        onChange(checked)
                                      }}
                                      {...field}
                                    />
                                  )}
                                />
                              }
                            >
                              <Grid container rowGap={2}>
                                <Controller
                                  name="antiRugThreshold"
                                  control={control}
                                  render={({ field: { ref, ...field } }) => (
                                    <PercentsInput
                                      label="Anti-Rug Threshold"
                                      onOptionSelect={(value) => field.onChange(value)}
                                      tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                                      {...field}
                                    />
                                  )}
                                />

                                <Controller
                                  name="sellRugExtraTip"
                                  control={control}
                                  render={({ field: { ref, ...field } }) => (
                                    <OptionalInput
                                      placeholder="Amount"
                                      isNumeric
                                      label="Sell Rug Extra Tip"
                                      tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                                      {...field}
                                    />
                                  )}
                                />
                              </Grid>
                            </HiddenInputs>

                            <Grid container flexWrap="nowrap" columnGap={3}>
                              <Controller
                                name="maxBuyTax"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder="Enter percentage"
                                    isNumeric
                                    label="Max Buy Tax"
                                    adornmentText="%"
                                    tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="maxSellTax"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder="Enter percentage"
                                    isNumeric
                                    label="Max Sell Tax"
                                    adornmentText="%"
                                    tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid container flexWrap="nowrap" columnGap={3}>
                              <Controller
                                name="minLiquidity"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder="Enter min liquidity amount"
                                    isNumeric
                                    label="Min Liquidity"
                                    adornmentText="$"
                                    tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="maxLiquidity"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder="Enter max liquidity amount"
                                    isNumeric
                                    label="Max Liquidity"
                                    adornmentText="$"
                                    tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                            <Controller
                              name="maxMarketcap"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Enter max marketcap"
                                  isNumeric
                                  label="Max Marketcap"
                                  adornmentText="$"
                                  tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        ),
                      },
                      // {
                      //   label: ManualSettingsSection.SELL,
                      //   content: (
                      //     <Grid gap={3} container flexDirection="column">
                      //       <HiddenInputs
                      //         expanded={isAutoSellSettings}
                      //         trigger={
                      //           <Controller
                      //             name="autoSell"
                      //             control={control}
                      //             render={({ field: { ref, onChange, ...field } }) => (
                      //               <SwitchInline
                      //                 label="Auto Sell"
                      //                 tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                      //                 {...field}
                      //                 onChange={(
                      //                   _: React.ChangeEvent<HTMLInputElement>,
                      //                   checked: boolean,
                      //                 ) => {
                      //                   setIsAutoSellSetting(checked)
                      //                   onChange(checked)
                      //                 }}
                      //               />
                      //             )}
                      //           />
                      //         }
                      //       >
                      //         <Grid container rowGap={2} flexDirection="column">
                      //           <Controller
                      //             name="sellPriority"
                      //             control={control}
                      //             render={({ field: { ref, ...field } }) => (
                      //               <OptionalInput
                      //                 placeholder="Enter gwei amount"
                      //                 isNumeric
                      //                 label="Sell Priority"
                      //                 tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                      //                 endAdornment={
                      //                   <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                      //                 }
                      //                 onAutoChange={() => {
                      //                   //TO DO: added auto switch
                      //                 }}
                      //                 {...field}
                      //               />
                      //             )}
                      //           />
                      //           <div className={styles.dividerContainer}>
                      //             <Typography variant="body2" textColor="grey">
                      //               Profit
                      //             </Typography>

                      //             <div className={styles.divider}></div>
                      //           </div>
                      //           <Controller
                      //             name="walletsToSellOnProfit"
                      //             control={control}
                      //             render={({ field: { ref, value, ...field } }) => {
                      //               setSelectedWallets(value)

                      //               return (
                      //                 <SelectWallet
                      //                   isMulti
                      //                   tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                      //                   wallet={value}
                      //                   {...field}
                      //                   label="Wallets to sell on Profit"
                      //                 />
                      //               )
                      //             }}
                      //           />

                      //           <ButtonGroupRadio
                      //             onChange={handleProfitTabChange}
                      //             value={profitTab}
                      //             exclusive
                      //             className={styles.group}
                      //           >
                      //             {ProfitSettingsTabs.map((tab) => (
                      //               <ButtonGroupRadioButton
                      //                 value={tab.value}
                      //                 key={tab.value}
                      //                 className={cn({ [styles.bgGrey]: tab.value === profitTab })}
                      //               >
                      //                 {tab.label}
                      //               </ButtonGroupRadioButton>
                      //             ))}
                      //           </ButtonGroupRadio>

                      //           {profitTab === ProfitSettingsTabs[0].value &&
                      //             selectedWallets.length > 0 && (
                      //               <div>
                      //                 <div className={styles.labelContent}>
                      //                   <InputLabel className={styles.label}>
                      //                     <Typography variant="body2" textColor="light-grey">
                      //                       Sell in steps
                      //                     </Typography>
                      //                   </InputLabel>
                      //                   <TooltipIcon info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%." marginLeft={5} />
                      //                 </div>
                      //                 <div className={styles.stepWrapper}>
                      //                   <Controller
                      //                     name="firstStep"
                      //                     control={control}
                      //                     render={({ field: { ref, ...field } }) => (
                      //                       <StepInput
                      //                         wallet="#1"
                      //                         balance="12.58 ETH"
                      //                         className={styles.firstInput}
                      //                         {...field}
                      //                       />
                      //                     )}
                      //                   />
                      //                   <Controller
                      //                     name="secondStep"
                      //                     control={control}
                      //                     render={({ field: { ref, ...field } }) => (
                      //                       <StepInput wallet="#2" balance="12.58 ETH" {...field} />
                      //                     )}
                      //                   />
                      //                   <Controller
                      //                     name="thirdStep"
                      //                     control={control}
                      //                     render={({ field: { ref, ...field } }) => (
                      //                       <StepInput
                      //                         wallet="#3"
                      //                         balance="12.58 ETH"
                      //                         className={styles.lastInput}
                      //                         {...field}
                      //                       />
                      //                     )}
                      //                   />
                      //                 </div>
                      //               </div>
                      //             )}

                      //           {profitTab === ProfitSettingsTabs[0].value &&
                      //             !selectedWallets.length && (
                      //               <Typography variant="body1" className={styles.walletsDescr}>
                      //                 No wallets selected
                      //               </Typography>
                      //             )}

                      //           {profitTab === ProfitSettingsTabs[1].value && (
                      //             <Grid container flexWrap="nowrap" columnGap={3}>
                      //               <Controller
                      //                 name="sellOnProfit"
                      //                 control={control}
                      //                 render={({ field: { ref, ...field } }) => (
                      //                   <OptionalInput
                      //                     placeholder="Enter sell on profit"
                      //                     isNumeric
                      //                     label="Sell on Profit"
                      //                     adornmentText="%"
                      //                     tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                      //                     {...field}
                      //                   />
                      //                 )}
                      //               />

                      //               <Controller
                      //                 name="sellOnProfitAmount"
                      //                 control={control}
                      //                 render={({ field: { ref, ...field } }) => (
                      //                   <OptionalInput
                      //                     placeholder="Enter sell profit amount"
                      //                     isNumeric
                      //                     label="Sell Profit Amount"
                      //                     adornmentText="%"
                      //                     tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                      //                     {...field}
                      //                   />
                      //                 )}
                      //               />
                      //             </Grid>
                      //           )}

                      //           {profitTab === ProfitSettingsTabs[2].value && (
                      //             <>
                      //               <Controller
                      //                 name="walletsToTrailingSell"
                      //                 control={control}
                      //                 render={({ field: { ref, value, ...field } }) => (
                      //                   <SelectWallet
                      //                     isMulti
                      //                     wallet={value}
                      //                     label="Wallets to trailing sell"
                      //                     tooltipInfo="Select which wallets will be used to purchase token."
                      //                     {...field}
                      //                   />
                      //                 )}
                      //               />
                      //               <Controller
                      //                 name="trailingSell"
                      //                 control={control}
                      //                 render={({ field: { ref, ...field } }) => (
                      //                   <OptionalInput
                      //                     placeholder="Enter trailing sell"
                      //                     isNumeric
                      //                     label="Trailing Sell"
                      //                     adornmentText="%"
                      //                     tooltipInfo="tooltipInfo"
                      //                     {...field}
                      //                   />
                      //                 )}
                      //               />
                      //             </>
                      //           )}

                      //           {profitTab !== ProfitSettingsTabs[2].value && (
                      //             <>
                      //               <div className={styles.dividerContainer}>
                      //                 <Typography variant="body2" textColor="grey">
                      //                   Loss
                      //                 </Typography>
                      //                 <div className={styles.divider}></div>
                      //               </div>
                      //               <Controller
                      //                 name="walletsToSellOnLoss"
                      //                 control={control}
                      //                 render={({ field: { ref, value, ...field } }) => (
                      //                   <SelectWallet
                      //                     label="Wallets to sell on Loss"
                      //                     isMulti
                      //                     tooltipInfo="Select which wallets will be used to purchase token."
                      //                     wallet={value}
                      //                     {...field}
                      //                   />
                      //                 )}
                      //               />
                      //               <Grid container flexWrap="nowrap" columnGap={3}>
                      //                 <Controller
                      //                   name="stopLoss"
                      //                   control={control}
                      //                   render={({ field: { ref, ...field } }) => (
                      //                     <OptionalInput
                      //                       placeholder="Enter amount"
                      //                       label="Stop Loss"
                      //                       tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                      //                       adornmentText="%"
                      //                       {...field}
                      //                     />
                      //                   )}
                      //                 />
                      //                 <Controller
                      //                   name="stopLossAmount"
                      //                   control={control}
                      //                   render={({ field: { ref, ...field } }) => (
                      //                     <OptionalInput
                      //                       placeholder="Enter amount"
                      //                       label="Stop Loss Amount"
                      //                       tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                      //                       adornmentText="%"
                      //                       {...field}
                      //                     />
                      //                   )}
                      //                 />
                      //               </Grid>
                      //             </>
                      //           )}
                      //         </Grid>
                      //       </HiddenInputs>
                      //     </Grid>
                      //   ),
                      // },
                    ]}
                  />
                )}

                {currentTemplate === mockTemplates[1] && (
                  <Grid width="100%" gap={2} container>
                    <Typography variant="body2" textColor="white">
                      Template Settings
                    </Typography>

                    <Grid container flexDirection="column" gap={1.5}>
                      <Typography variant="body2" textColor="white">
                        Buy
                      </Typography>

                      <Grid container gap={3}>
                        <Grid width="47.9%" container flexDirection="column" gap={1}>
                          <SettingField title="Anti-Mev" value={defaultValues.privateTransaction} />

                          <SettingField
                            title="Wallets Selected"
                            value={mockData.buy.walletsSelected}
                          />

                          <SettingField
                            title="Approve Priority"
                            value={defaultValues.approvePriority || mockData.buy.approvePriority}
                          />
                        </Grid>

                        <Grid width="47.9%" container flexDirection="column" gap={1}>
                          <SettingField
                            title="Degen Chad Mode"
                            value={defaultValues.degenChadMode}
                          />

                          <SettingField
                            title="Buy Priority"
                            value={defaultValues.buyPriority || mockData.buy.buyPriority}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container flexDirection="column" gap={1.5}>
                      <Typography variant="body2" textColor="white">
                        Safety
                      </Typography>

                      <Grid container gap={3}>
                        <Grid width="47.9%" container flexDirection="column" gap={1}>
                          <SettingField
                            title="Anti-Rug"
                            value={defaultValues.antiRug || mockData.safety.antiRug}
                          />

                          <SettingField
                            title="Anti-Rug Threshold"
                            value={
                              defaultValues.antiRugThreshold || mockData.safety.antiRugThreshold
                            }
                          />

                          <SettingField
                            title="Sell Rug Extra Tip"
                            value={defaultValues.sellRugExtraTip || mockData.safety.sellRugExtraTip}
                          />

                          <SettingField
                            title="Max Buy Tax"
                            value={defaultValues.maxBuyTax || mockData.safety.maxBuyTax}
                          />

                          <SettingField
                            title="Min Liquidity"
                            value={defaultValues.minLiquidity || mockData.safety.minLiquidity}
                          />
                        </Grid>

                        <Grid width="47.9%" container flexDirection="column" gap={1}>
                          <SettingField
                            title="Anti-Blacklist"
                            value={defaultValues.antiBlacklist}
                          />

                          <SettingField
                            title="Transfer On Blacklist"
                            value={defaultValues.transferOnBlacklist}
                          />

                          <SettingField
                            title="Max Marketcap"
                            value={defaultValues.maxMarketcap || mockData.safety.maxMcap}
                          />

                          <SettingField
                            title="Max Sell Tax"
                            value={defaultValues.maxSellTax || mockData.safety.maxSellTax}
                          />

                          <SettingField
                            title="Max Liquidity"
                            value={defaultValues.maxLiquidity || mockData.safety.maxLiquidity}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container flexDirection="column" gap={1.5}>
                      <Typography variant="body2" textColor="white">
                        Sell
                      </Typography>

                      <Grid container gap={3}>
                        <Grid width="47.9%" container flexDirection="column" gap={1}>
                          <SettingField
                            title="Sell Priority"
                            value={defaultValues.sellPriority || mockData.sell.sellPriority}
                          />

                          <SettingField title="Sell on Profit" value={mockData.sell.sellOnProfit} />

                          <SettingField
                            title="Wallets to sell on Profit"
                            value={
                              defaultValues.walletsToSellOnProfit ||
                              mockData.sell.walletsToSellOnProfit
                            }
                          />

                          <SettingField title="Sell in steps" value={mockData.sell.sellInSteps} />
                        </Grid>

                        <Grid width="47.9%" container flexDirection="column" gap={1}>
                          <SettingField
                            title="Auto Sell"
                            value={defaultValues.autoSell || mockData.sell.autoSell}
                          />

                          <SettingField
                            title="Sell Profit Amount"
                            value={mockData.sell.sellProfitAmount}
                          />

                          <SettingField title="Trailing Sell" value={mockData.sell.trailingSell} />

                          <SettingField title="Sell in steps:" value={''} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container columnGap={3} flexWrap="nowrap">
              <Button styleVariant="black" onClick={handleChangeMainTab} disabled={isLastMainTab}>
                Continue
              </Button>

              <Button disabled={!isLastMainTab} type="submit">
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  )
}

export { AddNewScrape }
