import { FC, useRef } from 'react'

import cn from 'classnames'

import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { VerificationStatus } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import styles from './styles.module.scss'

type TProperty = {
  status: ValueOf<typeof VerificationStatus>
  className?: string
}

const VerificationStatusBar: FC<TProperty> = ({ status, className }) => {
  // This is used to rerender the component on resize
  useWindowDimensions()

  const list = useRef<HTMLUListElement>(null)
  const progressBg = useRef<HTMLDivElement>(null)
  const progressLine = useRef<HTMLDivElement>(null)

  const handleActiveIndex = (index: number) => {
    if (!list.current || !progressLine.current || !progressBg.current) {
      return
    }

    const activeEl = list.current.querySelector(`li:nth-of-type(${index + 1})`)
    if (activeEl) {
      const elWidth = activeEl.clientWidth
      progressLine.current.style.left = `${elWidth / 2}px`
      progressLine.current.style.right = `${list.current.clientWidth - (activeEl as any).offsetLeft - elWidth / 2}px`
      progressBg.current.style.left = `${elWidth / 2}px`
      progressBg.current.style.right = `${elWidth / 2}px`
    }
  }

  const getCurrentStatus = () => {
    let isLower = true
    const values = Object.values(VerificationStatus)
    return values.map((statusName, index) => {
      const [firstRow, secondRow] = statusName.split(/\s(.*)/s)
      if (statusName === status) {
        isLower = false
      }

      const activeLast = statusName === status

      if (activeLast) {
        setTimeout(() => {
          handleActiveIndex(index)
        }, 0)
      }

      return (
        <li
          key={statusName}
          className={cn(styles.statusLineItem, {
            [styles.active]: isLower || activeLast,
            [styles.currentStatus]: activeLast,
            [styles.activeLast]: activeLast,
          })}
        >
          {firstRow}
          <br />
          {secondRow}
        </li>
      )
    })
  }

  return (
    <div className={cn(styles.statusBarContainer, className)}>
      <ul className={styles.statusLine} ref={list}>
        <div className={cn(styles.progress)} ref={progressBg} />
        <div className={cn(styles.progress, styles.progressActive)} ref={progressLine} />
        {getCurrentStatus()}
      </ul>
    </div>
  )
}

export { VerificationStatusBar }
