import { FC } from 'react'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InputAdornment, { InputAdornmentProps } from '@mui/material/InputAdornment'

import styles from './styles.module.scss'

type AdormentErrorProps = Omit<InputAdornmentProps, 'position'>

const AdormentError: FC<AdormentErrorProps> = ({ ...props }) => {
  return (
    <InputAdornment classes={styles} position="end" {...props}>
      <ErrorOutlineIcon fontSize="small" />
    </InputAdornment>
  )
}

export { AdormentError }
