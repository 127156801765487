// @TODO delete mock data
const selectedTokenData = {
  tokenName: 'PEPE',
  pairName: 'UNIBOT / WETH',
  pairAddress: '0xf819d...7c369',
  marketCap: '$140,031',
  tradeable: 'Yes',
  taxBuySell: '0 / 0',
  burn: '22%',
  maxBuy: '9.9% / 2%',
  clog: '22%',
  gain: '55.74x / 62.57x',
  liquidity: '$14,404',
  totalSupply: '420.69T PEPE',
  price: '$0.00001418',
  caSellCount: '$12,928 / 7',
  locked: 'Yes (300d)',
  renounced: 'Yes',
  contractVerified: 'Yes',
  hasBlacklist: 'No',
  ownerSupply: '2%',
  topHolders: '7%',
  volume24h: '$12,404',
  holderCount: '12364',
}

export { selectedTokenData }
