import { useEffect } from 'react'

import { Grid } from '@mui/material'

import { Button, ButtonCopy, Typography } from '@/libs/common'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

const Affiliates = () => {
  const userData = useAppSelector((state) => state.user.userData)

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Affiliates' })
  }, [])

  const webAffiliateLink = `${window.location.origin}?affiliateId=${userData?.username}`
  const tgAffiliateLink = `${import.meta.env.VITE_TG_URL}?start=${userData?.username}`

  return (
    <Grid>
      <Grid display="flex" marginBottom={1} marginTop={1}>
        <Typography>My commission tier:</Typography>
        <Typography marginLeft={1} textColor="white">
          {userData?.tier || '-'}
        </Typography>
      </Grid>
      <Grid display="flex" alignItems="center" marginTop={1}>
        <Typography>My web invite link:</Typography>
        <Typography marginLeft={1} marginRight={1} textColor="white">
          {webAffiliateLink}
        </Typography>
        <ButtonCopy value={webAffiliateLink} />
      </Grid>
      <Grid display="flex" alignItems="center" marginBottom={2} marginTop={1}>
        <Typography>My Telegram invite link:</Typography>
        <Typography marginLeft={1} marginRight={1} textColor="white">
          {tgAffiliateLink}
        </Typography>
        <ButtonCopy value={tgAffiliateLink} />
      </Grid>
      <Button
        styleVariant="black"
        onClick={() => window.open(import.meta.env.VITE_AFFILIATES_DASHBOARD_URL, '_blank')}
      >
        Open Affiliates Dashboard
      </Button>
    </Grid>
  )
}

export { Affiliates }
