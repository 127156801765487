import { useEffect } from 'react'

import { Grid } from '@mui/material'

import { SellTab } from '@/components/sell-tab'
import { useModal } from '@/pages/modal-page/modal-page'

const SellModal = () => {
  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Sell', titleProps: { marginBottom: 3 } })
  }, [])

  return (
    <Grid>
      <SellTab usedInModal />
    </Grid>
  )
}

export { SellModal }
