import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { deleteWallets, setWalletAsDefault } from '@/api/wallet'
import { Spinner } from '@/components/spinner/spinner'
import { useAppMode } from '@/hooks/useAppMode'
import {
  Button,
  ButtonIcon,
  ButtonLink,
  Icon,
  Popover,
  type TOnChangeVisible,
  Typography,
} from '@/libs/common'
import { AppRoute, IconName, SpinnerSize } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { TUserWallet } from '@/libs/types/user.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserWallets } from '@/store/slices/user.slice'

import { MIN_VISIBLE_BALANCE_VALUE } from './libs/constants'
import styles from './styles.module.scss'

type TProps = {
  wallet: TUserWallet
}

const WalletItem: FC<TProps> = ({ wallet }) => {
  const { mode } = useAppMode()
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user.userData)
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })
  const setMainWallet = async (wallet: TUserWallet) => {
    if (!user) return
    try {
      setIsLoading(true)

      const { data: res } = await setWalletAsDefault({ address: wallet.address })
      dispatch(setUserWallets(res.data))
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteSelectedWallet = async () => {
    if (!user) return

    try {
      setIsLoading(true)

      const { data: res } = await deleteWallets([wallet.address])
      dispatch(setUserWallets(res.data))
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }

  const walletBalance =
    Number(wallet.balanceFormatted) > MIN_VISIBLE_BALANCE_VALUE
      ? Number(wallet.balanceFormatted)?.toFixed(5)
      : 0

  const open = Boolean(deletePopup.anchorE)
  const id = open ? 'wallet-popover' : undefined

  return (
    <div className={styles.element}>
      <div className={styles.infoWrapper}>
        <div>
          <div className={styles.info}>
            <div>
              <Typography variant="body2" className={styles.title}>
                {wallet.name}
              </Typography>
              <Typography variant="body2">{hideWalletAddress(wallet.address)}</Typography>
            </div>
          </div>
          <Typography variant="body2">{walletBalance} ETH</Typography>
        </div>

        {wallet.is_default ? (
          <Typography className={styles.label} variant="body2">
            Main
          </Typography>
        ) : isLoading ? (
          <Spinner size={SpinnerSize.SMALL} />
        ) : (
          <Typography
            className={styles.button}
            variant="body2"
            onClick={() => setMainWallet(wallet)}
          >
            Set as main
          </Typography>
        )}
      </div>
      <div className={styles.controls}>
        <ButtonIcon
          tooltipTitle="Edit"
          icon={IconName.EDIT}
          className={styles.link}
          onClick={() =>
            navigate(
              `${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.EDIT_WALLET_NAME}/${wallet.address}`,
            )
          }
        />

        <ButtonIcon
          id={id}
          tooltipTitle="Delete"
          icon={IconName.DELETE}
          className={styles.delete}
          onClick={(e) => setDeletePopup({ visible: true, anchorE: e.currentTarget })}
        />

        <Popover
          id={id}
          className={styles.popover}
          popoverState={deletePopup}
          onChangeVisible={setDeletePopup}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Typography variant="body2" className={styles.title}>
            Confirm Delete
          </Typography>
          <Typography variant="body2">
            Are you sure you want to delete {wallet.name} wallet?
          </Typography>
          <div className={styles.popoverButtons}>
            <ButtonIcon
              onClick={() => {
                setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
              }}
            >
              <Typography variant="body2" className={styles.cancel}>
                Cancel
              </Typography>
            </ButtonIcon>
            <Button
              className={styles.confirm}
              styleVariant="red"
              onClick={() => {
                deleteSelectedWallet()
                setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
              }}
            >
              Delete
            </Button>
          </div>
        </Popover>

        <ButtonLink
          to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.WALLET_DETAILS}/${wallet.address}`}
          className={styles.link}
        >
          <Icon name={IconName.CHEVRON_RIGHT} />
        </ButtonLink>
      </div>
    </div>
  )
}

export { WalletItem }
