import { FC, useEffect } from 'react'

import { Tabs } from '@/libs/common'
import { ExportTab } from '@/pages/modal-page/libs/components/wallet-management/libs/components/export-tab'
import { useModal } from '@/pages/modal-page/modal-page'

import { AddWalletTab } from './libs/components/add-wallet-tab'
import { WalletsTab } from './libs/components/wallets-tab'
import styles from './styles.module.scss'

const WalletManagement: FC = () => {
  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Wallet Management' })
  }, [])

  return (
    <div className={styles.content}>
      <Tabs
        tabs={[
          { label: 'Wallets', content: <WalletsTab /> },
          { label: 'Add new wallet', content: <AddWalletTab /> },
          { label: 'Export', content: <ExportTab /> },
        ]}
      />
    </div>
  )
}

export { WalletManagement }
