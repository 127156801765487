import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import Container from '@mui/material/Container'

import styles from './styles.module.scss'

const Auth: FC = () => {
  return (
    <div className={styles.authContainer}>
      <Container className={styles.muiContainer}>
        <Outlet />
      </Container>
    </div>
  )
}

export { Auth }
