import { FC, useMemo } from 'react'

import moment from 'moment'

import { OrderHistoryTable } from '@/components/pending-orders/libs/components/order-history-table'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const OrderHistoryTab: FC = () => {
  const loading = useAppSelector((state) => state.orders.isLoading)
  const userHistory = useAppSelector((state) => state.favoritesTokens.orderHistory)

  const rows = useMemo(
    () => [...(userHistory || [])].sort((a, b) => moment(b.d).valueOf() - moment(a.d).valueOf()),
    [userHistory],
  )

  return (
    <div className={styles.tableContainer}>
      <OrderHistoryTable rows={rows} loading={loading} />
    </div>
  )
}

export { OrderHistoryTab }
