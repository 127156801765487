import { EImportModalType } from '@/pages/modal-page/libs/components/import-wallets/libs/enum'

const getWalletsTitle = (query: string | null) => {
  switch (query) {
    case EImportModalType.PUBLIC_AND_PRIVATE_KEYS:
      return 'Import wallets keys'
    case 'private-keys':
      return 'Import private key(s)'
    case 'wallet-file':
      return 'Import wallet file'
    default:
      return 'Unknown type'
  }
}

export { getWalletsTitle }
