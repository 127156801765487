import { ComponentProps, FC } from 'react'
import { useNavigate } from 'react-router-dom'

import cls from 'classnames'

import { ButtonIcon } from '@/libs/common/button-icon'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProperty = {
  isAbsolute?: boolean
}

const BackButton: FC<ComponentProps<'button'> & TProperty> = ({
  className,
  isAbsolute,
  ...props
}) => {
  const navigate = useNavigate()
  const onClick = () => {
    navigate(-1)
  }
  return (
    <ButtonIcon
      icon={IconName.ARROW_RIGHT}
      onClick={onClick}
      className={cls({ [styles.absolute]: isAbsolute }, className)}
      {...props}
    />
  )
}

export { BackButton }
