import { FC } from 'react'

import { Grid } from '@mui/material'
import MUITooltip, { TooltipProps as MUITooltipProps } from '@mui/material/Tooltip'
import cn from 'classnames'

import { Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'

import stylesTooltip from './styles-mui-tooltip.module.scss'
import styles from './styles.module.scss'

type TChipProps = MUITooltipProps & {
  withIcon?: boolean
}

const Tooltip: FC<TChipProps> = ({ children, className, withIcon = true, ...props }) => {
  return withIcon ? (
    <Grid
      className={cn(styles.container, className)}
      container
      alignItems="center"
      wrap="nowrap"
      gap={0.5}
    >
      {children}
      <MUITooltip classes={stylesTooltip} {...props} arrow>
        <Grid container className={styles.icon}>
          <Icon name={IconName.INFO_ICON} />
        </Grid>
      </MUITooltip>
    </Grid>
  ) : (
    <MUITooltip classes={stylesTooltip} className={className} {...props} arrow>
      <div className={cn({ [styles.childrenContainerStyles]: withIcon })}>{children}</div>
    </MUITooltip>
  )
}

export { Tooltip }
