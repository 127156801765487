import { useLocation } from 'react-router-dom'

import { AppMode, AppRoute } from '@/libs/enums'

const useAppMode = () => {
  const { pathname } = useLocation()
  const mode = pathname.includes(`${AppRoute.DASHBOARD}/${AppMode.LITE}`)
    ? AppMode.LITE
    : AppMode.PRO
  return { mode }
}

export { useAppMode }
