import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { EBackendResponseStatus } from '@/libs/enums'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TSocketResponse } from '@/libs/types/backend-response.type'
import { TSniperSocketBalanceResponse } from '@/libs/types/sniper-socket-responses.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import { updateUserWallet } from '@/store/slices/user.slice'

const balancesSniperSocket = new SniperSockerService(import.meta.env.VITE_SNIPER_INDEXER_SOCKET_URL)

const useWatchBalances = () => {
  const dispatch = useAppDispatch()

  const userData = useAppSelector((state) => state.user.userData)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  useSniperWebsocket({
    socket: balancesSniperSocket,
    connectionProps: {
      endpoint: `indexer/stream/${userData?.user_id}/balances`,
      query: createQueryString({ l: 'en', b: currentChain.indexerChainId }),
    },
    verifyExistanceBeforeConnect: [!!userWallets],
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSocketResponse<TSniperSocketBalanceResponse | string>
      if (typeof data.data === 'string' || data.status !== EBackendResponseStatus.SUCCESS) {
        return
      }
      dispatch(updateUserWallet(data.data))
    },
  })
}

export { useWatchBalances }
