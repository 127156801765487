import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import cls from 'classnames'

import {
  ChainNameToChainIcon,
  ChainNameToChainName,
} from '@/components/header/libs/maps/chain-name-to-chain-element'
import { Spinner } from '@/components/spinner/spinner'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { ButtonIcon, Icon } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { AppRoute, IconName, SpinnerSize } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type Props = {
  className?: string
}

const ChainStatusInfo: FC<Props> = ({ className = '' }) => {
  const navigate = useNavigate()
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentChainBlock = useAppSelector((state) => state.chain.currentChainBlock)
  const nativeTokenPrice = useAppSelector((state) => state.chain.currentChain.nativeTokenPriceInUsd)
  const currentGas = useAppSelector((state) => state.chain.currentGas)
  const { width } = useWindowDimensions()
  const isMobileSidebar = width < 768

  const handleClick = () => {
    navigate(`${AppRoute.MODAL}/${AppRoute.SWITCH_CHAIN}`)
  }

  return (
    <div className={cls(styles.container, className)}>
      <div className={styles.activeChain}>
        {!isMobileSidebar && (
          <Typography variant="body2" className={styles.title}>
            Chain:
          </Typography>
        )}
        <Typography variant="body2" className={styles.chain}>
          {ChainNameToChainIcon[currentChain.chainName]}
          {!isMobileSidebar && <span>{ChainNameToChainName[currentChain.chainName]}</span>}
        </Typography>

        <ButtonIcon icon={IconName.EXCHANGE} onClick={handleClick} />
      </div>

      {!isMobileSidebar && (
        <>
          <div className={styles.verticalDivider}></div>
          <div className={styles.chainDetails}>
            {currentGas ? (
              <Typography variant="body2" className={styles.column}>
                <Icon name={IconName.GAS} /> {currentGas}
              </Typography>
            ) : (
              <Spinner size={SpinnerSize.SMALL} />
            )}

            <div className={styles.divider}></div>

            {currentChainBlock ? (
              <Typography variant="body2" className={styles.column}>
                <Icon name={IconName.BLOCK} />
                {currentChainBlock}
              </Typography>
            ) : (
              <Spinner size={SpinnerSize.SMALL} />
            )}

            <div className={styles.divider}></div>

            {nativeTokenPrice ? (
              <Typography variant="body2" className={styles.column}>
                <Icon name={IconName.DIAMOND} />${Math.round(nativeTokenPrice)}
              </Typography>
            ) : (
              <Spinner size={SpinnerSize.SMALL} />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export { ChainStatusInfo }
