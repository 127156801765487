import { FC } from 'react'

import InputAdornment from '@mui/material/InputAdornment'
import MUITextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField'
import cn from 'classnames'

import { Icon } from '@/libs/common/index'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

const SearchTextfield: FC<MUITextFieldProps> = ({ className, InputProps, ...props }) => {
  return (
    <MUITextField
      className={cn(styles.root, className)}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <Icon name={IconName.SEARCH} />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

export { SearchTextfield }
