import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { EBackendResponseStatus } from '@/libs/enums'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TOrderHistory } from '@/libs/types'
import { TSocketResponse } from '@/libs/types/backend-response.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import { setOrderHistory } from '@/store/slices/favorites-tokens.slice'

const searchHistorySniperSocket = new SniperSockerService(
  import.meta.env.VITE_SNIPER_INDEXER_SOCKET_URL,
)

const useWatchOrderHistory = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const dispatch = useAppDispatch()

  useSniperWebsocket({
    socket: searchHistorySniperSocket,
    connectionProps: {
      endpoint: 'order/stream/history',
      query: createQueryString({ b: currentChain.indexerChainId, l: 'en' }),
    },
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSocketResponse<TOrderHistory[] | string>
      if (typeof data.data === 'string' || data.status !== EBackendResponseStatus.SUCCESS) {
        return
      }
      dispatch(setOrderHistory(data.data))
    },
  })
}

export { useWatchOrderHistory }
