import axios from 'axios'

import { AppRoute, EBackendErrorCode } from '@/libs/enums'
import { store } from '@/store'
import { setNavigateTo } from '@/store/slices/app.slice'

import { refreshTokens } from './user'

type TGeneratorFunc = (params?: any) => Promise<string>
// It's not the best approach, but it's the only way to get access_token outside of a component
let tokenGenerator: TGeneratorFunc | null = null
const setTokenGenerator = (newTokenGenerator: TGeneratorFunc | null) => {
  if (!tokenGenerator) {
    tokenGenerator = newTokenGenerator
  }
}

// TODO: Consider adding a redirect
const removeJwtTokens = () => {
  localStorage.clear()
  location.reload()
}

const api = axios.create({
  baseURL: import.meta.env.VITE_INDEXER_URL,
  headers: {
    Accept: '*/*',
  },
})

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.token || ''

    if (!config.headers.Authorization && token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response?.data?.data?.code === EBackendErrorCode.ACCOUNT_LOCKED) {
      store.dispatch(
        setNavigateTo(`${AppRoute.DASHBOARD}/pro/${AppRoute.MODAL}/${AppRoute.ACCOUNT_LOCKING}`),
      )
      return Promise.reject({ ...error, message: error.message })
    }

    // If the user is unauthorised
    if (error.response?.status === 401) {
      if (!originalRequest._retry) {
        originalRequest._retry = true
        const refreshToken = localStorage.refreshToken
        if (!refreshToken) {
          return Promise.reject({ ...error, message: error.message })
        }

        try {
          const { data } = await refreshTokens()
          localStorage.token = data.data.token
          originalRequest.headers.Authorization = `Bearer ${data.data.token}`
          return api(originalRequest)
        } catch (err) {
          removeJwtTokens()
        }
      } else {
        removeJwtTokens()
      }
    }

    return Promise.reject({ ...error, message: error.message })
  },
)

export { setTokenGenerator, api, tokenGenerator as getAccessToken }
