import axios from 'axios'

const getErrorMessage = (error: unknown) => {
  if (typeof error === 'string') {
    return error
  } else if (axios.isAxiosError(error)) {
    const desc = error.response?.data.response?.description
    const title = error.response?.data.response?.title
    return desc ? desc : title ? title : 'Unknown error occurred'
  } else if (error instanceof Error) {
    return error.message
  } else {
    return 'Unknown error occurred'
  }
}

export { getErrorMessage }
