import { FC, ReactNode } from 'react'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProperty = {
  title: string
  value: ReactNode
}
const InfoItem: FC<TProperty> = ({ title, value }) => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <Typography variant="caption" className={styles.title}>
          {title}
        </Typography>

        <Typography variant="body1">{value}</Typography>
      </div>
    </div>
  )
}

export { InfoItem }
