import { CustomToast } from '@/components/custom-toast'

const extractErrorDescription = (err: any, showToast = true) => {
  const message =
    err.response?.data?.data?.description || err.message || 'Cannot extract error from the response'

  if (showToast) {
    CustomToast('error', message)
  }

  return message
}

export { extractErrorDescription }
