import { FC, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { deleteBuyTemplate } from '@/api/templates'
import { Spinner } from '@/components/spinner/spinner'
import {
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  ButtonIcon,
  Popover,
  type TOnChangeVisible,
  Typography,
} from '@/libs/common'
import { AppMode, AppRoute, IconName, SpinnerSize } from '@/libs/enums'
import { handleError } from '@/libs/helper/handleError'
import { TSnipeTemplate } from '@/libs/types/snipe.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserBuyTemplates } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type TProps = {
  templates: TSnipeTemplate[]
  currentTemplateId: string
  onSelectTemplate: (id: string) => void
  onCustomize: () => void
  addDisabled?: boolean
  customizeDisabled?: boolean
  editDisabled?: boolean
  deleteDisabled?: boolean
  isResponsive?: boolean
}

const TemplatesControl: FC<TProps> = ({
  templates,
  currentTemplateId,
  onSelectTemplate,
  onCustomize,
  addDisabled,
  customizeDisabled,
  editDisabled,
  deleteDisabled,
  isResponsive = false,
}) => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const currentTemplate = useMemo(
    () => templates.find((item) => item.id === currentTemplateId),
    [templates, currentTemplateId],
  )

  const handleAdd = () => {
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.MANUAL_BUY_TEMPLATE}`,
    )
  }

  const handleEdit = () => {
    navigate(
      `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.MANUAL_BUY_TEMPLATE}/${currentTemplateId}`,
    )
  }

  const handleDelete = async () => {
    if (!currentToken) return
    try {
      setIsDeleteLoading(true)
      const { data } = await deleteBuyTemplate(currentTemplateId)
      dispatch(setUserBuyTemplates(data.data))
      onSelectTemplate(templates[0].id)
    } catch (err) {
      handleError(err)
    } finally {
      setIsDeleteLoading(false)
    }
  }

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={cn(styles.header, { [styles.responsive]: isResponsive })}
      >
        <Grid
          container
          columnGap={2}
          width="fit-content"
          className={cn(styles.actionButtonsContainer, styles.addBtnContainer)}
        >
          <Typography className={styles.title}>Templates</Typography>
          <Button
            className={styles.headerButton}
            styleVariant="borderless-gold"
            iconStartName={IconName.ADD_CIRCLE}
            transparent
            onClick={handleAdd}
            disabled={addDisabled}
          >
            Add new
          </Button>
        </Grid>
        <Grid container columnGap={2} width="fit-content" className={styles.actionButtonsContainer}>
          <Button
            className={styles.headerButton}
            styleVariant="borderless-gold"
            iconStartName={IconName.SETTING}
            transparent
            onClick={onCustomize}
            disabled={customizeDisabled}
            type="button"
          >
            Customize
          </Button>
          <Button
            className={styles.headerButton}
            styleVariant="borderless-gold"
            iconStartName={IconName.EDIT}
            transparent
            onClick={handleEdit}
            disabled={editDisabled}
            type="button"
          >
            Edit
          </Button>

          <Grid className={styles.popupContainer}>
            <ButtonIcon
              disabled={deleteDisabled || isDeleteLoading}
              className={styles.trigger}
              icon={!isDeleteLoading ? IconName.DELETE : undefined}
              tooltipTitle={!isDeleteLoading ? 'Delete' : undefined}
              type="button"
              onClick={(e) => setDeletePopup({ visible: true, anchorE: e.currentTarget })}
            >
              <span>{isDeleteLoading && <Spinner size={SpinnerSize.SMALL} />} Delete</span>
            </ButtonIcon>

            <Popover
              className={styles.popover}
              popoverState={deletePopup}
              onChangeVisible={setDeletePopup}
            >
              <Typography variant="body2" className={styles.title}>
                Confirm Delete
              </Typography>
              <Typography variant="body2">
                Are you sure you want to delete {currentTemplate?.name} Template?
              </Typography>
              <div className={styles.buttons}>
                <ButtonIcon
                  onClick={() => {
                    setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                  }}
                >
                  <Typography variant="body2" className={styles.cancel}>
                    Cancel
                  </Typography>
                </ButtonIcon>
                <Button
                  className={styles.confirm}
                  styleVariant="red"
                  onClick={() => {
                    handleDelete()
                    setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                  }}
                >
                  Delete
                </Button>
              </div>
            </Popover>
          </Grid>
        </Grid>
      </Grid>
      <div className={styles.templatesContainer}>
        <div className={cn(styles.mobileGradient, { [styles.visible]: isResponsive })} />
        <ButtonGroupRadio
          groupClassName={cn(styles.radioGroup, { [styles.responsive]: isResponsive })}
          separated
          exclusive
          onChange={(_, value) => onSelectTemplate(value)}
          value={currentTemplateId}
        >
          {templates?.map((template) => (
            <ButtonGroupRadioButton
              value={template.id}
              name={template.name}
              key={template.id}
              className={cn(styles.customButton, {
                [styles.active]: template.id === currentTemplateId,
              })}
            >
              <Typography variant="body2" className={styles.templateName}>
                {template.name}
              </Typography>
            </ButtonGroupRadioButton>
          ))}
        </ButtonGroupRadio>
      </div>
    </>
  )
}

export { TemplatesControl }
