const MAX_CANVAS_WIDTH = 768
const MAX_CANVAS_HEIGHT = 432
const DEFAULT_MOBILE_OFFSET = 80

const horizontalMargin = 100
const horizontalPadding = 32

const HORIZONTAL_OFFSET = horizontalMargin + horizontalPadding

export { MAX_CANVAS_WIDTH, MAX_CANVAS_HEIGHT, HORIZONTAL_OFFSET, DEFAULT_MOBILE_OFFSET }
