import { FC } from 'react'

import { TableCell, TableRow } from '@mui/material'
import cn from 'classnames'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProperty = {
  colSpan?: number
  styleValiant?: 'basic' | 'lite'
}

const RowLoadingPlaceholder: FC<TProperty> = ({ colSpan = 6, styleValiant = 'basic' }) => {
  return (
    <TableRow className={cn(styles.row, styleValiant === 'basic' ? styles.basic : styles.lite)}>
      <TableCell colSpan={colSpan} className={cn(styles.cell, styles.placeHolder)}>
        <Typography variant="body1" align="center">
          Loading...
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export { RowLoadingPlaceholder }
