import { FC } from 'react'

import { useAppMode } from '@/hooks/useAppMode'
// import { ComingSoonOverlay } from '@/components/coming-soon-overlay'
import { ListButton } from '@/libs/common'
import { ButtonLink } from '@/libs/common/button-link'
import { AppRoute, IconName } from '@/libs/enums'

import styles from './styles.module.scss'

const AddWalletTab: FC = () => {
  const { mode } = useAppMode()
  return (
    <div className={styles.container}>
      <ButtonLink
        to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.SELECT_WALLET_COUNT}`}
      >
        <ListButton
          title="Generate new wallet"
          description="New wallets will be generated inside the bot. You will need to save their private keys in order to keep them safe."
          iconName={IconName.MANUFACTURING}
        />
      </ButtonLink>
      <div className={styles.buttonLink}>
        <ButtonLink
          to={`${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${AppRoute.IMPORT_PRIVATE_KEY}`}
        >
          <ListButton
            title="Import wallets"
            description=" You can connect existing wallets by importing their private keys. They will be stored encrypted and only you will have access to them."
            iconName={IconName.KEY}
          />
        </ButtonLink>
      </div>
      {/* <div className={styles.buttonLink}>
        <ComingSoonOverlay />
        <ButtonLink to={`${AppRoute.DASHBOARD}/${AppRoute.MODAL}/${AppRoute.WALLET_FILE}`}>
          <ListButton
            title="Import wallet file"
            description="Explanation..."
            iconName={IconName.LIST_ALT_ADD}
          />
        </ButtonLink>
      </div> */}
    </div>
  )
}

export { AddWalletTab }
