import { FC } from 'react'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = {
  variant?: string
  top?: number
  right?: number
  bottom?: number
  left?: number
  offset?: number
}

const ComingSoonOverlay: FC<TProps> = ({
  variant,
  offset = 0,
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
}) => {
  if (import.meta.env.VITE_IS_TEST_INSTANCE === 'true') {
    return <></>
  }

  return (
    <div
      className={styles.wrapper}
      style={{
        top: offset || top,
        right: offset || right,
        bottom: offset || bottom,
        left: offset || left,
      }}
    >
      <Typography variant={(variant as any) || 'h1'} textColor="color-grey-2">
        Coming soon
      </Typography>
    </div>
  )
}

export { ComingSoonOverlay }
