import { FC } from 'react'

import { AuthCard } from '@/components/auth-card'
import { VerificationStatus } from '@/libs/enums'
import { GenerateNewWallet } from '@/pages/modal-page/libs/components'

import styles from './styles.module.scss'

type Props = {
  isModal: boolean
}
const GenerateWallets: FC<Props> = ({ isModal = true }) => {
  return (
    <AuthCard
      verificationStatus={VerificationStatus.SETUP_WALLETS}
      className={styles.card}
      isHeaderButtons
    >
      <GenerateNewWallet duringSignUp isModal={isModal} />
    </AuthCard>
  )
}

export { GenerateWallets }
