import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Stack } from '@mui/material'
import cls from 'classnames'
import * as yup from 'yup'

import { useSelectedPanel } from '@/hooks/useSelectedPanel'
import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { Button, Icon, Input, InputWithRadioGroup, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppMode, AppRoute, EBackendResponseStatus, IconName } from '@/libs/enums'
import { createSellPayload, handleSell } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { createQueryString } from '@/libs/helper/createQueryString'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { validatePercentInput } from '@/libs/helper/validatePercentInput'
import { TSellForm } from '@/libs/types'
import { TSocketResponse } from '@/libs/types/backend-response.type'
import { TPanelPositionItemSocket } from '@/libs/types/panels-data-socket-response.type'
import { TSelectOption } from '@/libs/types/select-option.type'
import {
  TSniperSimulationData,
  TSniperSimulationResponse,
} from '@/libs/types/sniper-simulation-socket-response.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useModal } from '@/pages/modal-page/modal-page'
import { SniperSockerService } from '@/socket'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const defaultValues: TSellForm = {
  privateTransaction: true,
  slippage: 50,
  sellPriority: '',
  // dip: '',
  // marketcap: '',
  // price: '',
  // triggerPricePercent: '',
  // expiration: '',
  sell: '',
  receive: '',
}
const spendOptions: TSelectOption<number>[] = [
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
]

enum ELastUsedAmountInput {
  SELL = 'Sell',
  RECEIVE = 'Receive',
}

type TLocationState = {
  wallets?: TPanelPositionItemSocket[]
}

const simulationSniperSocket = new SniperSockerService()

const ManualSell = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const selectedWallets = (location.state as TLocationState)?.wallets
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const defaultPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const userData = useAppSelector((state) => state.user.userData)
  const [lastUsedAmountInput, setLastUsedAmountInput] = useState<ELastUsedAmountInput | null>(null)
  const [tokensAvailable, setTokensAvailable] = useState(0)
  const [sellPercentage, setSellPercentage] = useState<number>(0)
  const [isDisabled, setIsDisable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedToken, setSelectedToken] = useState<TSniperSimulationData | null>(null)

  const currentPosition = useSelectedPanel()

  const { setModalProps } = useModal()

  useSniperWebsocket({
    socket: simulationSniperSocket,
    connectionProps: {
      endpoint: 'indexer/stream/simulate',
      query: createQueryString({ b: currentChain.indexerChainId, l: 'en' }),
      isPublic: true,
      onOpen: () => {
        simulationSniperSocket.emit(
          JSON.stringify({
            s: currentPosition?.ta.toLowerCase(),
            b: currentChain.indexerChainId.toString(),
          }),
        )
      },
    },
    verifyExistanceBeforeConnect: [currentPosition],
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSocketResponse<TSniperSimulationResponse | string>
      if (
        typeof data.data === 'string' ||
        data.status !== EBackendResponseStatus.SUCCESS ||
        !data.data
      ) {
        return
      }

      setSelectedToken(data.data)
    },
  })

  useEffect(() => {
    setModalProps({ title: 'Manual sell' })
  }, [])

  const schema = yup.object({
    privateTransaction: yup.boolean().default(true),
    slippage: stringOfNumbersValidation.required().test((value) => +value <= 100),
    sellPriority: stringOfNumbersValidation,
    sell: stringOfNumbersValidation.required().test((value) => +value <= tokensAvailable),
    receive: stringOfNumbersValidation.required(),
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      ...defaultValues,
      sellPriority: defaultPriorities.sell_priority,
    },
    resolver: yupResolver(schema) as any,
  })

  const fieldsRequiredForSellActions = watch(['slippage'])

  useEffect(() => {
    let newTokensAvailable = 0
    if (selectedWallets) {
      selectedWallets.forEach((wallet) => {
        newTokensAvailable += +(wallet.ba || 0)
      })
    }
    setTokensAvailable(newTokensAvailable)
  }, [currentPosition])

  useEffect(() => {
    let newDisabled = tokensAvailable <= 0
    if (!newDisabled) {
      for (const field of fieldsRequiredForSellActions) {
        if ((Array.isArray(field) && !field.length) || !field) {
          newDisabled = true
          break
        }
      }
    }
    setIsDisable(newDisabled && !selectedToken?.l.dn)
  }, [fieldsRequiredForSellActions, tokensAvailable, selectedToken])

  useEffect(() => {
    if (!currentChain.nativeTokenPriceInUsd || !lastUsedAmountInput) return

    if (lastUsedAmountInput === ELastUsedAmountInput.SELL) {
      const sellValue = getValues().sell
      if (sellValue) {
        calculateReceive(sellValue)
      }
    } else if (lastUsedAmountInput === ELastUsedAmountInput.RECEIVE) {
      const receiveValue = getValues().receive
      if (receiveValue) {
        calculateSell(receiveValue)
      }
    }
  }, [currentChain])

  const calculateReceive = (value: string) => {
    if (!currentPosition || !currentChain.nativeTokenPriceInUsd) return

    setValue('sell', value, {
      shouldValidate: !!value,
    })

    if (!value) {
      setValue('sell', '')
      clearErrors(['sell', 'receive'])
      return
    }

    const coeff = +currentPosition.p / currentChain.nativeTokenPriceInUsd
    setValue(
      'receive',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      { shouldValidate: true },
    )
  }

  const calculateSell = (value: string) => {
    if (!currentPosition || !currentChain.nativeTokenPriceInUsd) return

    setValue('receive', value, {
      shouldValidate: !!value,
    })

    if (!value) {
      setValue('receive', '')
      clearErrors(['sell', 'receive'])
      return
    }

    const coeff = currentChain.nativeTokenPriceInUsd / +currentPosition.p
    setValue(
      'sell',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      {
        shouldValidate: true,
      },
    )
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!userData || !currentPosition || !selectedWallets) return

    setIsLoading(true)
    const wallets = selectedWallets.map((item) => {
      return {
        name: item.wn,
        public_key: item.wa,
      }
    })

    const payload = await createSellPayload({
      wallets,
      sellPercentage,
      data,
      dex: selectedWallets[0].sd,
      token: currentPosition.ta,
    })

    if (payload) {
      await handleSell(payload)
      navigate(`${AppRoute.DASHBOARD}/${AppMode.PRO}`)
    }
    setIsLoading(true)

    if (sellPercentage) setSellPercentage(0)
  }

  const tokenName = selectedToken?.t.n
  const tokenSymbol = selectedToken?.t.s
  const availableAmountFormatted = formatTokenPrice(
    convertScientificNotationNumber(tokensAvailable),
  ).formatted
  const currentDexName = selectedToken?.l.dn || ''

  return (
    <Stack spacing={3} gap={2}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Grid className={styles.formContainer}>
          <Controller
            name="privateTransaction"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label="Anti-Mev"
                tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                {...field}
              />
            )}
          />

          <Controller
            name="slippage"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <PercentsInput
                label="Slippage"
                onOptionSelect={(value) => field.onChange(value)}
                tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                placeholder="X"
                error={!!errors.slippage?.message}
                {...field}
              />
            )}
          />

          <Controller
            name="sellPriority"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                label="Sell Priority"
                placeholder="Enter gwei amount"
                isNumeric
                tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                error={!!errors.sellPriority?.message}
                {...field}
              />
            )}
          />

          {/* <Grid container rowGap={2} flexDirection="column">
                <ButtonGroupRadio
                  onChange={handleFooterTabChange}
                  value={footerTab}
                  exclusive
                  className={cls(styles.group, styles.bgGrey)}
                >
                  <ButtonGroupRadioButton value="market">Market</ButtonGroupRadioButton>
                  <ButtonGroupRadioButton value="limit">Limit</ButtonGroupRadioButton>
                </ButtonGroupRadio>
                {footerTab === 'limit' && (
                  <Grid container rowGap={2} flexDirection="column" className={styles.limitBlock}>
                    <div>
                      <Typography variant="body2" textColor="light-grey">
                        Trigger price
                      </Typography>
                      <div className={styles.inputWrapper}>
                        <Controller
                          name="dip"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <Input
                              className={cls(styles.input, styles.first)}
                              placeholder="Enter dip %"
                              endAdornment={<EndAdornment label="%" />}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="marketcap"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <Input
                              className={styles.input}
                              placeholder="Enter marketcap"
                              endAdornment={<EndAdornment label="MC" />}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="price"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <Input
                              className={cls(styles.input, styles.last)}
                              placeholder="Enter price"
                              endAdornment={<EndAdornment label="$" />}
                              {...field}
                            />
                          )}
                        />
                      </div>

                      <Controller
                        name="triggerPricePercent"
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <ButtonGroupRadio
                            className={styles.radioGroup}
                            onChange={(_, value: string) => onChange(value)}
                            exclusive
                            {...field}
                          >
                            {limitOptions.map((option) => (
                              <ButtonGroupRadioButton
                                value={option.value}
                                className={styles.radioButton}
                                key={option.value}
                              >
                                {option.label}
                              </ButtonGroupRadioButton>
                            ))}
                          </ButtonGroupRadio>
                        )}
                      />
                    </div>

                    <Controller
                      name="expiration"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                        tooltipInfo='Enter the number of hours the limit order is valid. After this time expires, it will be removed.'
                          label="Expiration"
                          className={styles.input}
                          placeholder="Enter the position expiration time in hours"
                          adornmentText="Hours"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid> */}
        </Grid>

        <Grid className={styles.footer}>
          <div className={styles.arrow}>
            <Icon name={IconName.ARROW_DOWN_GREY} />
          </div>

          <div className={styles.container}>
            <div className={styles.spend}>
              <Controller
                name="sell"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <InputWithRadioGroup
                    label="Sell"
                    placeholder="Enter amount to sell"
                    isNumeric
                    className={styles.input}
                    onOptionSelect={(value) => {
                      const newValue = convertScientificNotationNumber(
                        tokensAvailable * (+value / 100),
                        MAX_TRX_DECIMALS,
                      )
                      setLastUsedAmountInput(ELastUsedAmountInput.SELL)
                      calculateReceive(newValue)
                      setSellPercentage(+value)
                    }}
                    endAdornment={
                      <EndAdornment
                        label={tokenSymbol}
                        imageUrl={currentPosition?.i.s}
                        isDisabled={isDisabled}
                      />
                    }
                    radioGroupName="amount"
                    disabled={isDisabled}
                    options={spendOptions}
                    onChange={(e) => {
                      if (!currentPosition || !currentChain.nativeTokenPriceInUsd) return
                      if (e.target.value == '') {
                        calculateReceive(e.target.value)
                        return
                      }
                      const newValue = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      setLastUsedAmountInput(ELastUsedAmountInput.SELL)
                      calculateReceive(newValue)
                    }}
                    error={!!errors.sell?.message}
                    withCustomInput
                    validateCustomInput={validatePercentInput}
                    {...field}
                  />
                )}
              />

              <div className={styles.available}>
                <Typography variant="body2" className={styles.param}>
                  Available:
                </Typography>
                <Typography variant="body2" className={styles.value}>
                  {availableAmountFormatted} {tokenName}
                </Typography>
              </div>
            </div>
            <div className={styles.union}>
              <Controller
                name="receive"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Input
                    label="Receive"
                    placeholder="Enter amount to receive"
                    isNumeric
                    className={styles.input}
                    endAdornment={
                      <EndAdornment label="ETH" icon={IconName.ETHEREUM} isDisabled={isDisabled} />
                    }
                    disabled={isDisabled}
                    onChange={(e) => {
                      if (!currentPosition || !currentChain.nativeTokenPriceInUsd) return

                      if (e.target.value == '') {
                        calculateSell(e.target.value)
                        return
                      }
                      const newValue = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      setLastUsedAmountInput(ELastUsedAmountInput.RECEIVE)
                      calculateSell(newValue)
                    }}
                    error={!!errors.receive?.message}
                    {...field}
                  />
                )}
              />
              <div className={cls(styles.infoContainer, { [styles.disabled]: isDisabled })}>
                <div className={styles.info}>
                  <Typography variant="body2" className={styles.param}>
                    DEX:
                  </Typography>
                  <Typography variant="body2" className={styles.value}>
                    {currentDexName}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <Button type="submit" disabled={isDisabled} isLoading={isLoading}>
            Sell
          </Button>
        </Grid>
      </form>
    </Stack>
  )
}

export { ManualSell }
