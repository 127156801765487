import { FC, ReactNode } from 'react'

import { Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = {
  title: string
  value?: ReactNode
  isUpgradePlan?: boolean
}

const TokenDataItem: FC<TProps> = ({ title, value, isUpgradePlan = false }) => {
  return (
    <div className={styles.tokenDataWrapper}>
      <Typography variant="body1" className={styles.title}>
        {title}
      </Typography>

      {isUpgradePlan ? (
        <Typography variant="body1" className={styles.value}>
          {value}
        </Typography>
      ) : (
        <Icon name={IconName.LOCK} />
      )}
    </div>
  )
}

export { TokenDataItem }
