import { ChainName } from '@/libs/enums'
import { TChainConfig } from '@/libs/types/chain.type'

const chains: TChainConfig[] = [
  {
    id: 1,
    chainName: ChainName.ETHEREUM,
    label: 'Ethereum',
    description: 'ETH',
    iconName: 'ETHEREUM',
    nativeTokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    indexerChainId: 1,
    chainDexes: [
      {
        id: 0,
        label: 'Uniswap V2',
      },
      {
        id: 1,
        label: 'Uniswap V3',
      },
      {
        id: 10,
        label: 'SushiSwap V2',
      },
      {
        id: 11,
        label: 'SushiSwap V3',
      },
      {
        id: 20,
        label: 'PancakeSwap V2',
      },
      {
        id: 21,
        label: 'PancakeSwap V3',
      },
      {
        id: 30,
        label: 'ShibaSwap V2',
      },
    ],
  },
  {
    id: 56,
    chainName: ChainName.BNB,
    label: 'BNB Smart',
    description: 'BSC',
    iconName: 'BNB_V2',
    nativeTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    indexerChainId: 2,
    chainDexes: [
      {
        id: 0,
        label: 'PancakeSwap V2',
      },
      {
        id: 1,
        label: 'PancakeSwap V3',
      },
      {
        id: 11,
        label: 'Uniswap V3',
      },
      {
        id: 20,
        label: 'ApeSwap V2',
      },
      {
        id: 30,
        label: 'BakerySwap V2',
      },
    ],
  },
  {
    id: 42161,
    chainName: ChainName.ARBITRUM,
    label: 'Arbitrum',
    description: 'ARB',
    iconName: 'ARBITRUM',
    nativeTokenAddress: '0x912CE59144191C1204E64559FE8253a0e49E6548',
    indexerChainId: 4,
    chainDexes: [
      {
        id: 1,
        label: 'Uniswap V3',
      },
      {
        id: 10,
        label: 'SushiSwap V2',
      },
      {
        id: 11,
        label: 'SushiSwap V3',
      },
      {
        id: 20,
        label: 'Camelot V2',
      },
      {
        id: 21,
        label: 'Camelot V3',
      },
      {
        id: 30,
        label: 'ArbSwap V2',
      },
    ],
  },
]

export { chains }
