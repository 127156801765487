import { FC } from 'react'

import Grid, { GridProps } from '@mui/material/Grid'
import cn from 'classnames'

import { Icon } from '@/libs/common/icon'
import { SignOutButton } from '@/libs/common/sign-out-button'
import { IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const HeaderButtons: FC<GridProps> = ({ className, ...props }) => {
  const user = useAppSelector((state) => state.user.userData)

  return (
    <Grid
      container
      justifyContent="space-between"
      className={cn(styles.container, className)}
      flexWrap="nowrap"
      {...props}
    >
      <div className={styles.icon}>
        <Icon name={IconName.LOGO} /> <div className={styles.name}>{user?.name}</div>
      </div>
      <SignOutButton variant="onboarding" />
    </Grid>
  )
}

export { HeaderButtons }
