import { useState } from 'react'

import { ButtonIcon } from '@/libs/common'
import { IconName, Sorting } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

const useSorting = () => {
  const [sorting, setSorting] = useState<ValueOf<typeof Sorting>>(Sorting.DESC)

  const handleSort = () => {
    setSorting((sorting) => {
      return sorting === Sorting.ASC ? Sorting.DESC : Sorting.ASC
    })
  }

  const SortIcon = () => {
    return (
      <ButtonIcon
        icon={sorting === Sorting.ASC ? IconName.SORT_ASC : IconName.SORT_DESC}
        onClick={handleSort}
      />
    )
  }

  return { SortIcon, sorting, setSorting }
}

export { useSorting }
