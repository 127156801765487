import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { ListButton, Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = {
  title: string
  link: string
  icon: ReactNode
}

const AddTemplateItem: FC<TProps> = ({ icon, title, link }) => {
  return (
    <Link to={link} className={styles.link}>
      <ListButton className={styles.linkWrapper}>
        <Typography variant="body2" className={styles.content}>
          <span className={styles.icon}>{icon}</span>

          {title}
        </Typography>
      </ListButton>
    </Link>
  )
}

export { AddTemplateItem }
