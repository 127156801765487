import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { signIn } from '@/api/user'
import { AuthCard } from '@/components/auth-card'
import { CustomToast } from '@/components/custom-toast'
import { SixDigitForm } from '@/components/six-digit-form'
import { Spinner } from '@/components/spinner'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { Icon, Typography } from '@/libs/common'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config.ts'
import { AppMode, AppRoute, IconName, SpinnerSize } from '@/libs/enums'
import { useAppDispatch } from '@/store'
import { fetchUserById } from '@/store/slices/user.slice.ts'

import styles from './styles.module.scss'

export const EnterCode: React.FC = () => {
  const [code, setCode] = useState<string[]>(new Array(6).fill(''))
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const handleCodeChange = (newCode: string[]) => {
    setCode(newCode)
  }
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  const handleNewTokenSelection = useSelectNewToken()

  useEffect(() => {
    const codeString = code.join('')
    if (codeString.length === 6) {
      try {
        const data = JSON.parse(localStorage.signData)
        setLoading(true)
        signIn({
          blockchain: 1,
          signatory: data.signatory,
          signature: data.signature,
          nonce: data.nonce,
          code: codeString,
        })
          .then(async (res) => {
            localStorage.token = res.data.data.token
            localStorage.refreshToken = res.data.data.refresh_token
            await dispatch(fetchUserById(res.data.data.id)).unwrap()
            if (searchParams.get('wallet')) {
              navigate(`/${AppRoute.ADD_CHAIN}?wallet=${searchParams.get('wallet')}`)
            } else {
              // TODO: Check if this is needed
              handleNewTokenSelection(DEFAULT_TOKEN, {
                skipRedirect: !!localStorage.code,
                skipTokenSetToUrl: true,
                isForceNavigate: true,
              })
              if (localStorage.code) {
                navigate(`/${AppRoute.ADD_DEVICE}?code=${localStorage.code}`)
              }
              localStorage.removeItem('signData')
              localStorage.removeItem('signUpData')
            }
          })
          .catch(() => {
            setLoading(false)
            CustomToast('error', 'The 2FA code you entered is invalid. Please try again.')
          })
      } catch {
        setLoading(false)
      }
    }
  }, [code])
  return (
    <>
      <AuthCard
        className={styles.card}
        footerLink={{
          title: 'Cancel signing in',
          link: `${AppRoute.DASHBOARD}/${AppMode.PRO}?token=${DEFAULT_TOKEN}`,
        }}
      >
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <div className={styles.icon} onClick={() => navigate(-1)}>
              <Icon name={IconName.ARROW_RIGHT} />
            </div>
            <Typography variant="h3" textColor="light-grey" align="center">
              Enter the 6-digit code from <br /> your authenticator app
            </Typography>
            <div />
          </div>
          {loading && <Spinner size={SpinnerSize.BIG} centered />}
          {!loading && <SixDigitForm onCodeChange={handleCodeChange} />}
        </div>
      </AuthCard>
    </>
  )
}
