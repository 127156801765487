import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'

import { Grid, InputLabel } from '@mui/material'
import cls from 'classnames'

import { SelectWallet } from '@/components/select-wallet'
import {
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  StepInput,
  Typography,
} from '@/libs/common'
import { ControlledAccordion } from '@/libs/common/controlled-accordion'
import { OptionalInput } from '@/libs/common/optional-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { TSelectOption } from '@/libs/types/select-option.type'

import styles from './styles.module.scss'

const defaultValues = {
  walletsToSellOnLoss: [],
  walletsToSellOnProfit: [],
  firstBundleOrFail: '',
  firstBundleBuy: '',
  backupBundle: '',
  backupBundleBuy: '',
  mempoolBackupBuy: '',
  maxTxOrFail: '',
  slippage: 25,
  selectedWallets: [],
  minPercentForFail: '',
  approvePriority: '',
  transferOnBlacklist: '',
  antiBlacklist: '',
  antiRug: false,
  antiRugThreshold: 50,
  sellRugExtraTip: '',
  maxBuyTax: '',
  maxSellTax: '',
  minLiquidity: '',
  maxLiquidity: '',
  maxMarketcap: '',
  autoSell: '',
  sellPriority: '',
  selectedWalletsForProfit: [],
  selectedWalletsForLoss: [],
  stopLoss: '',
  stopLossAmount: '',
  firstStep: '25',
  secondStep: '32',
  thirdStep: '40',
  sellOnProfit: '',
  sellOnProfitAmount: '',
  walletsToTrailingSell: [],
  trailingSell: '',
}

// @TODO remove duplicate data
const ProfitSettingsTabs: TSelectOption<string>[] = [
  { value: 'sellInSteps', label: 'Sell in steps' },
  { value: 'sellOnProfit', label: 'Sell on profit' },
  { value: 'trailingSell', label: 'Trailing sell' },
]

const EditPosition = () => {
  const { position } = useParams()
  const location = useLocation()
  const selectedPositions = location.state?.positionsChecked
  const [positions, setPositions] = useState<number[] | null>(null)
  const [isAutoSell, setIsAutoSell] = useState(false)
  // const [isFirstBundle, setIsFirstBundle] = useState(false)
  const [selectedWallets, setSelectedWallets] = useState<number[]>([])

  useEffect(() => {
    if (!position) {
      setPositions(
        Object.keys(selectedPositions)
          .filter((key) => /^\d+$/.test(key))
          .map(Number)
          .sort((a, b) => a - b),
      )
    }
  }, [selectedPositions, position])

  const [profitTab, setProfitTab] = useState('sellInSteps')
  const [isDisable] = useState(true)
  const { control, handleSubmit } = useForm({
    defaultValues,
  })

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }

  const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
    setProfitTab(newValue)
  }

  const title = position ? `Edit position #${position}` : 'Edit multiple positions'

  return (
    <Grid spacing={3} gap={2}>
      <Grid>
        <Typography variant="h1" align="center">
          {title}
        </Typography>

        {positions && (
          <Typography variant="body2" align="center">
            Positions: {positions.join(', ')}
          </Typography>
        )}
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Grid container direction="column" gap={2}>
          <Grid container rowGap={2}>
            <Controller
              name="autoSell"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <SwitchInline
                  label="Auto Sell"
                  tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                  onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    setIsAutoSell(checked)
                    onChange(checked)
                  }}
                  {...field}
                />
              )}
            />

            <ControlledAccordion expanded={isAutoSell}>
              <Grid container rowGap={2}>
                <Controller
                  name="sellPriority"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      className={styles.optionalInput}
                      placeholder="Enter gwei amount"
                      isNumeric
                      label="Sell Priority"
                      onAutoChange={() => {
                        // Hendle auto toggle change
                      }}
                      tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                      // onExpand={setIsFirstBundle}
                      {...field}
                    />
                  )}
                />
                <Grid container alignItems="center">
                  <Typography variant="body2" textColor="grey">
                    Profit
                  </Typography>

                  <div className={styles.divider}></div>
                </Grid>

                <Controller
                  name="walletsToSellOnProfit"
                  control={control}
                  render={({ field: { ref, value, ...field } }) => {
                    setSelectedWallets(value)

                    return (
                      <SelectWallet
                        isMulti
                        tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                        wallet={value}
                        {...field}
                        label="Wallets to sell on Profit"
                      />
                    )
                  }}
                />

                <ButtonGroupRadio
                  onChange={handleProfitTabChange}
                  value={profitTab}
                  exclusive
                  className={styles.group}
                >
                  {ProfitSettingsTabs.map(({ value, label }) => (
                    <ButtonGroupRadioButton
                      value={value}
                      key={value}
                      className={cls({ [styles.bgGrey]: value === profitTab })}
                    >
                      {label}
                    </ButtonGroupRadioButton>
                  ))}
                </ButtonGroupRadio>

                {profitTab === 'sellInSteps' && selectedWallets.length > 0 && (
                  <div className={styles.stepContainer}>
                    <div className={styles.labelContent}>
                      <InputLabel className={styles.label}>Sell in steps</InputLabel>

                      <TooltipIcon
                        info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%."
                        marginLeft={5}
                      />
                    </div>

                    <div className={styles.stepWrapper}>
                      <Controller
                        name="firstStep"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <StepInput
                            wallet="#1"
                            balance="12.58 ETH"
                            className={styles.stepInput}
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="secondStep"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <StepInput
                            wallet="#2"
                            balance="12.58 ETH"
                            {...field}
                            className={styles.stepInput}
                          />
                        )}
                      />

                      <Controller
                        name="thirdStep"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <StepInput
                            wallet="#3"
                            balance="12.58 ETH"
                            className={cls(styles.stepInput, styles.lastStepInput)}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}

                {profitTab === 'sellInSteps' && !selectedWallets.length && (
                  <Typography variant="body1" className={styles.walletsDescr}>
                    No wallets selected
                  </Typography>
                )}

                {profitTab === 'sellOnProfit' && (
                  <Grid container flexWrap="nowrap" columnGap={3}>
                    <Controller
                      name="sellOnProfit"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder="Enter sell on profit"
                          isNumeric
                          label="Sell on Profit"
                          adornmentText="%"
                          tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="sellOnProfitAmount"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder="Enter sell profit amount"
                          isNumeric
                          label="Sell Profit Amount"
                          adornmentText="%"
                          tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}

                {profitTab === 'trailingSell' ? (
                  <>
                    <Controller
                      name="walletsToTrailingSell"
                      control={control}
                      render={({ field: { ref, value, ...field } }) => (
                        <SelectWallet
                          isMulti
                          wallet={value}
                          label="Wallets to trailing sell"
                          tooltipInfo="tooltipInfo"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="trailingSell"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder="Enter trailing sell"
                          isNumeric
                          label="Trailing Sell"
                          adornmentText="%"
                          tooltipInfo="tooltipInfo"
                          {...field}
                        />
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Grid container alignItems="center">
                      <Typography variant="body2" textColor="grey">
                        Loss
                      </Typography>

                      <div className={styles.divider}></div>
                    </Grid>

                    <Controller
                      name="selectedWalletsForLoss"
                      control={control}
                      render={({ field: { ref, value, ...field } }) => (
                        <SelectWallet
                          isMulti
                          wallet={value}
                          label="Wallets to sell on Stop Loss"
                          tooltipInfo="Select how many wallets will sell when the loss threshold is reached. For example, 1st wallet will sell at 20% loss, 2nd wallet will sell at 30% loss."
                          {...field}
                        />
                      )}
                    />

                    <Grid container flexWrap="nowrap" columnGap={3}>
                      <Controller
                        name="stopLoss"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter sell on loss %"
                            isNumeric
                            label="Stop Loss"
                            adornmentText="%"
                            tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="stopLossAmount"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter sell on loss amount"
                            isNumeric
                            label="Stop Loss Amount"
                            adornmentText="%"
                            tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </ControlledAccordion>
          </Grid>
        </Grid>

        <Button type="submit" disabled={isDisable} className={styles.button}>
          Save
        </Button>
      </form>
    </Grid>
  )
}

export { EditPosition }
