import { createProfitPanel } from '@/api/profit-loss'
import { CustomToast } from '@/components/custom-toast'
import { TOrderHistory } from '@/libs/types'
import { store } from '@/store'

export const handleOrdersStatusesChanging = (updatedOrders: TOrderHistory[]) => {
  const profitSell = store.getState().profitSell.profitSell
  for (const order of updatedOrders) {
    switch (order.hash[0].status?.status) {
      case 'successful': {
        if (order.type === 'buy') {
          const isExistPanel = profitSell?.some(
            (el) => el.panel.token_address === order.token && !el.panel.isDeleted,
          )
          if (!isExistPanel) {
            createProfitPanel({
              token_address: order.token,
              userId: order.userId,
              isActive: true,
              isDeleted: false,
            })
          }
        }
        CustomToast(
          'success',
          `The transaction of ${order.type === 'buy' ? 'buying' : 'selling'} ${order.tokenName} was successfully completed`,
        )
        break
      }
      case 'failed': {
        CustomToast(
          'error',
          `The transaction of ${order.type === 'buy' ? 'buying' : 'selling'} ${order.tokenName} was failed`,
        )
      }
    }
  }
}
