import { FC, useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import cls from 'classnames'

import { ButtonGroupRadio, ButtonGroupRadioButton, Icon, Input, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { IconName } from '@/libs/enums'
import { TFees } from '@/libs/types'

import styles from './styles.module.scss'

type TProperty = {
  fees?: TFees
  onChange: (value: TFees) => void
}

const SelectFees: FC<TProperty> = ({ fees, onChange }) => {
  const [customMinerTip, setCustomMinerTip] = useState<string>('')

  useEffect(() => {
    if (customMinerTip !== '') onChange({ type: 'advanced', minerTip: +customMinerTip })
  }, [customMinerTip])

  const handleChange = (_: React.BaseSyntheticEvent, value: TFees) => {
    onChange(value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.fees}>
        <ButtonGroupRadio
          exclusive
          label={
            <Typography variant="body2" textColor="light-grey">
              Fees
            </Typography>
          }
          className={styles.radioGroup}
          onChange={handleChange}
          value={fees}
        >
          <Grid container gap={2} flexDirection="row" flexWrap="nowrap">
            <ButtonGroupRadioButton
              value={{ type: 'normal', minerTip: 1 }}
              name="fees"
              className={cls(styles.customButton, { [styles.active]: fees?.type === 'normal' })}
            >
              <Typography variant="body2" className={styles.lightBold}>
                Normal Tx Speed
              </Typography>
              {/* <div className={styles.cost}>
                <Typography variant="body2" className={styles.eth}>
                  0,000032 ETH
                </Typography>
                <Typography variant="body2" className={styles.money}>
                  (~$1.32)
                </Typography>
              </div> */}
            </ButtonGroupRadioButton>
            <ButtonGroupRadioButton
              value={{ type: 'fast', minerTip: 7 }}
              name="fees"
              className={cls(styles.customButton, { [styles.active]: fees?.type === 'fast' })}
            >
              <Typography variant="body2" className={styles.lightBold}>
                Fast Tx Speed
              </Typography>
              {/* <div className={styles.cost}>
                <Typography variant="body2">0,000032 ETH</Typography>
                <Typography variant="body2">(~$1.32)</Typography>
              </div> */}
            </ButtonGroupRadioButton>
            <ButtonGroupRadioButton
              value={{ type: 'advanced', minerTip: +customMinerTip }}
              name="fees"
              className={cls(styles.customButton, styles.advanced, {
                [styles.active]: fees?.type === 'advanced',
                [styles.activeLabel]: fees?.type === 'advanced',
              })}
            >
              <Icon name={IconName.ADD_CIRCLE} />
              <Typography
                variant="body2"
                className={cls(styles.text, { [styles.activeLabel]: fees?.type === 'advanced' })}
              >
                Advanced
              </Typography>
            </ButtonGroupRadioButton>
          </Grid>
        </ButtonGroupRadio>
      </div>
      {fees?.type === 'advanced' && (
        <div className={styles.advanced}>
          <Input
            className={styles.input}
            label="Miner Tip"
            placeholder="Enter gwei amount"
            isNumeric
            endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
            onChange={(e) => setCustomMinerTip(e.target.value)}
            value={customMinerTip}
          />
          {/* <Input
            className={styles.input}
            label="Max fee"
            placeholder="Enter gwei amount"
            isNumeric
            endAdornment={<EndAdornment label="ETH" icon={IconName.ETHEREUM} />}
            onChange={(e) => setCustomMaxFee(e.target.value)}
            value={customMaxFee}
          /> */}
          {/* <div className={styles.advancedInfo}>
            <Typography variant="body2" className={styles.light}>
              Estimated fees
            </Typography>
            <div className={styles.cost}>
              <Typography variant="body2" className={styles.light}>
                0,000032 ETH
              </Typography>
              <Typography variant="body2" className={styles.light}>
                (~$1.32)
              </Typography>
            </div>
          </div> */}
        </div>
      )}
    </div>
  )
}

export { SelectFees }
