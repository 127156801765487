import { FC, useEffect } from 'react'

import { CardActionAreaProps } from '@mui/material'

// import styles from './styles.module.scss'

type TProperty = CardActionAreaProps & {
  isOpen: boolean
  anchor?: string | null
}
const SmoothScrollOverlay: FC<TProperty> = ({ isOpen, anchor }) => {
  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflow = 'auto'
      return
    }

    if (anchor) {
      const anchorEl = document.querySelector(`#${anchor}`)
      if (anchorEl) {
        anchorEl.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
      }
    }
    document.body.style.overflow = 'hidden'
  }, [anchor, isOpen])
  return null
  // return isOpen
  //   ? createPortal(
  //       <CardActionArea disableRipple className={styles.overlay} {...props}></CardActionArea>,
  //       document.body,
  //     )
  //   : null
}

export { SmoothScrollOverlay }
