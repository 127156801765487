import { FC } from 'react'

import { Grid, Stack } from '@mui/material'

import { Button, Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

const LimitReached: FC = () => {
  return (
    <Stack spacing={3}>
      <Stack alignItems="center" spacing={2}>
        <Typography variant="h1" align="center">
          Limit reached, upgrade plan
        </Typography>
        <Icon name={IconName.UPDATE_PLAN} />
        <Grid>
          <Typography variant="body2" align="center">
            You&apos;ve hit the maximum number of wallets allowed in the essential plan.
          </Typography>
          <Typography variant="body2" align="center">
            To continue, upgrade your account.
          </Typography>
        </Grid>
      </Stack>
      <Button>View plans</Button>
    </Stack>
  )
}

export { LimitReached }
