import { FC } from 'react'

import { ListButton, Typography } from '@/libs/common'

import styles from './styles.module.scss'

interface ITokenData {
  token: string
  users: number
  tokenAddress: string
}

type TProps = {
  tokenInfo: ITokenData
}

const PrelaunchSnipesItem: FC<TProps> = ({ tokenInfo }) => {
  return (
    <ListButton>
      <Typography variant="body1" className={styles.btnTitle}>
        <span className={styles.span}>{tokenInfo.token}</span>

        {' - ' + `${tokenInfo.users} Users`}
      </Typography>

      <Typography variant="body1" className={styles.address}>
        {tokenInfo.tokenAddress}
      </Typography>
    </ListButton>
  )
}

export { PrelaunchSnipesItem }
