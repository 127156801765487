import { FC } from 'react'

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import styles from './styles.module.scss'

type TProperty = {
  children: React.ReactNode
  trigger?: React.ReactNode
  expanded?: boolean
}
const HiddenInputs: FC<TProperty> = ({ children, trigger = <></>, expanded }) => {
  return (
    <Accordion expanded={expanded} className={styles.accordion}>
      <AccordionSummary className={styles.accordionHeader}>{trigger}</AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  )
}

export { HiddenInputs }
