import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import cls from 'classnames'
import * as yup from 'yup'

import styles from '@/components/dashboard-component-lite/libs/buy-sell-block/styles.module.scss'
import {
  Accordion,
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  Icon,
  Input,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { createSellPayload, formatNumber, handleSell } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { TSelectOption } from '@/libs/types/select-option.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useAppSelector } from '@/store'

type TProperty = {
  onExpand?: (value: boolean) => void
}

const options: TSelectOption<number>[] = [
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  },
]

const SellTab: FC<TProperty> = ({ onExpand }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)
  const mainUserWallet = useAppSelector((state) => state.user.mainWallet)
  const sellPanel = useAppSelector((state) => state.profitSell.profitSell?.sp)

  const [variant, setVariant] = useState('eth')
  const [available, setAvailable] = useState(0)

  const mainWallet = useMemo(
    () => sellPanel?.find((panel) => panel.wa === mainUserWallet?.address),
    [sellPanel, mainUserWallet],
  )
  const defaultUserPriorities = useAppSelector((state) => state.user.defaultPriorities)

  const [isLoading, setIsLoading] = useState(false)
  const [sellPercentage, setSellPercentage] = useState<number>(0)
  const defaultValues = {
    amount: '',
    slippage: 50,
    sellPriority: defaultUserPriorities.sell_priority ?? '',
    privateTransaction: true,
  }
  const schema = yup.object({
    amount: stringOfNumbersValidation
      .required()
      .test((value) => (variant === '%' ? +value <= 100 && +value >= 0 : +value <= available)),
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  })

  useEffect(() => {
    const coeff =
      currentChain.nativeTokenPriceInUsd && simulation
        ? +simulation.p.ou / currentChain.nativeTokenPriceInUsd
        : 0
    const newValue = convertScientificNotationNumber(
      +(mainWallet?.ba || 0) * coeff,
      MAX_TRX_DECIMALS,
    )
    setAvailable(+newValue)
  }, [mainWallet, currentChain, simulation])

  useEffect(() => {
    setValue('amount', '')
    setSellPercentage(0)
  }, [variant])

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!mainWallet) return

    setIsLoading(true)

    const payload = await createSellPayload({
      wallets: [{ name: mainWallet.wn, public_key: mainWallet.wa }],
      sellPercentage,
      data: {
        receive: data.amount,
        sell: '0',
        sellPriority: data.sellPriority,
        slippage: data.slippage,
        privateTransaction: data.privateTransaction,
      },
      mode: sellPercentage ? 0 : 1,
      dex: mainWallet.sd,
    })
    if (payload) {
      await handleSell(payload)
    }

    if (sellPercentage) setSellPercentage(0)
    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Accordion
        className={styles.accordion}
        titleClassName={styles.accordionTitle}
        title="Advanced Settings"
        withDivider
        mainColorArrow
        onChange={onExpand}
      >
        <Grid container rowGap={2}>
          <Controller
            name="privateTransaction"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label="Anti-Mev"
                tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                {...field}
              />
            )}
          />
          <Controller
            name="slippage"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <PercentsInput
                label="Slippage"
                onOptionSelect={(value) => field.onChange(value)}
                tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                placeholder="X"
                error={!!errors.slippage?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="sellPriority"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                className={styles.input}
                label="Sell Priority"
                placeholder="Enter gwei amount"
                isNumeric
                tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                error={!!errors.sellPriority?.message}
                {...field}
              />
            )}
          />
        </Grid>
      </Accordion>

      <div className={styles.worthBlock}>
        <Typography variant="body2">Worth in USD: </Typography>
        {/* <Icon name={IconName.ETH_CURRENCY} /> */}
        <Typography variant="body2" textColor="light-grey">
          ${mainWallet ? formatNumber(mainWallet.w.u).formatted : 0}
        </Typography>
      </div>
      <Controller
        name="amount"
        control={control}
        render={({ field: { ref, value, onChange, ...field } }) => {
          return (
            <ButtonGroupRadio
              solid
              exclusive
              className={styles.radioGroup}
              groupClassName={styles.radioGroupContent}
              value={value}
              onChange={(_, value) => {
                if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                if (variant === '%') {
                  setSellPercentage(+value)
                  onChange(value)
                  return
                }
                const newValue = convertScientificNotationNumber(
                  available * (+value / 100),
                  MAX_TRX_DECIMALS,
                )
                setSellPercentage(+value)
                onChange(newValue)
              }}
              {...field}
            >
              {options?.map((el, index) => (
                <ButtonGroupRadioButton
                  value={el.value}
                  name="wallet"
                  className={styles.customButton}
                  key={index}
                >
                  <Icon name={IconName.ETH_CURRENCY} />
                  <Typography variant="body2" textColor="light-grey">
                    {el.label}
                  </Typography>
                </ButtonGroupRadioButton>
              ))}
            </ButtonGroupRadio>
          )
        }}
      />
      <Link
        to={`${AppRoute.DASHBOARD}/${AppMode.LITE}/${AppRoute.MODAL}/${AppRoute.SETUP_PRIORITIES}`}
        className={styles.link}
      >
        <Typography variant="body2">
          Setup Default Priorities <Icon name={IconName.SETTING_2} />
        </Typography>
      </Link>
      <div className={styles.divider}>
        <Typography variant="body2" className={styles.text}>
          OR
        </Typography>
      </div>
      <Controller
        name="amount"
        control={control}
        render={({ field: { ref, onChange, ...field } }) => {
          return (
            <Input
              label="Amount"
              placeholder="Enter amount to sell"
              isNumeric
              className={styles.input}
              onChange={(e) => {
                if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                if (variant === '%') {
                  setSellPercentage(+e.target.value)
                  onChange(e.target.value)
                  return
                }
                if (!e.target.value) {
                  onChange(e.target.value)
                  return
                } else {
                  const newValue = convertScientificNotationNumber(
                    e.target.value,
                    MAX_TRX_DECIMALS,
                    true,
                  )
                  onChange(newValue)
                }

                if (sellPercentage) setSellPercentage(0)
              }}
              error={!!errors.amount?.message}
              {...field}
              endAdornment={
                <ButtonGroupRadio
                  exclusive
                  className={styles.switch}
                  value={variant}
                  onChange={(_, newValue) => setVariant(newValue)}
                >
                  <ButtonGroupRadioButton
                    value="eth"
                    className={cls(styles.switchButton, variant === 'eth' && styles.active)}
                  >
                    <Typography variant="body2" textColor="color-grey-3">
                      ETH
                    </Typography>
                  </ButtonGroupRadioButton>
                  <ButtonGroupRadioButton
                    value="%"
                    className={cls(styles.switchButton, variant === '%' && styles.active)}
                  >
                    <Typography variant="body2" textColor="color-grey-3">
                      %
                    </Typography>
                  </ButtonGroupRadioButton>
                </ButtonGroupRadio>
              }
            />
          )
        }}
      />

      <div className={styles.available}>
        <Typography variant="body2" className={styles.param}>
          Available:
        </Typography>
        <Typography variant="body2" className={styles.value}>
          {(+available || 0).toLocaleString('en-US', {
            maximumFractionDigits: 5,
          })}{' '}
          ETH
        </Typography>
      </div>

      <Button type="submit" isLoading={isLoading} checkOnAccountLock>
        Sell
      </Button>
    </form>
  )
}

export { SellTab }
