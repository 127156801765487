import { FC } from 'react'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import cn from 'classnames'

import { AuthCard, TAuthCardProps } from '@/components/auth-card'
import { Typography } from '@/libs/common/typography'
import { AppRoute, IconName, VerificationStatus } from '@/libs/enums'

import { SetupWalletsButtonLink as ButtonLink } from './libs/components'
import styles from './styles.module.scss'

const SetupWallets: FC<TAuthCardProps> = ({ className, ...props }) => {
  return (
    <AuthCard
      className={cn(styles.setupWalletsAuthCard, className)}
      verificationStatus={VerificationStatus.SETUP_WALLETS}
      isHeaderButtons
      {...props}
    >
      <Grid
        container
        alignItems="center"
        direction="column"
        gap={1}
        className={styles.titleContainer}
      >
        <Typography variant="h1" align="center" className={styles.title}>
          Setup Wallets
        </Typography>
        <Typography variant="body1">Choose method and setup your trading wallets</Typography>
      </Grid>

      <Stack spacing={2} useFlexGap>
        <ButtonLink
          to={AppRoute.GENERATE_WALLETS}
          iconName={IconName.MANUFACTURING}
          title="Generate new wallet(s)"
          description="New wallets will be generated inside the bot. You will need to save their private keys in order to keep them safe."
        />

        <ButtonLink
          to={AppRoute.IMPORT_KEYS}
          iconName={IconName.KEY}
          title="Import wallets"
          description="You can connect existing wallets by importing their private keys. They will be stored encrypted and only you will have access to them."
        />

        {/* TODO: Uncomment when those features are ready */}
        {/* <ButtonLink
          to={AppRoute.IMPORT_WALLET_FILE}
          iconName={IconName.LIST_ALT_ADD}
          title="Import wallet file"
          description="If you have installed wallets on your computer, you can import them by file."
        /> */}
      </Stack>
    </AuthCard>
  )
}

export { SetupWallets }
