import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import useCheckUser from '@/hooks/useCheckUser'
import { ButtonIcon, Tooltip } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = {
  iconName: IconName
  label: string
  redirectTo?: string
}

const CollapsedIcon: FC<TProps> = ({ iconName, label, redirectTo }) => {
  const navigate = useNavigate()
  const checkUserAndExecute = useCheckUser()

  return (
    <div className={styles.wrapper}>
      <Tooltip title={label} withIcon={false} placement="right" className={styles.tooltip}>
        <ButtonIcon
          icon={iconName}
          onClick={() => checkUserAndExecute(() => redirectTo && navigate(redirectTo))}
        />
      </Tooltip>
    </div>
  )
}

export { CollapsedIcon }
