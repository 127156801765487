import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/store'
import { setNavigateTo } from '@/store/slices/app.slice'

const NavigationWatcher = () => {
  const navigateTo = useAppSelector((state) => state.app.navigateTo)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!navigateTo) return

    navigate(navigateTo)
    dispatch(setNavigateTo(null))
  }, [navigateTo])

  return <></>
}

export { NavigationWatcher }
