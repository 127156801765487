import { FC } from 'react'

import { Grid } from '@mui/material'
import { InputProps as MUIInputProps } from '@mui/material/Input'
import cls from 'classnames'

import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { Input } from '@/libs/common/input/input'
import { Typography } from '@/libs/common/typography'

import styles from './styles.module.scss'

type TProperty = MUIInputProps & {
  wallet: string
  balance: string
}

const StepInput: FC<TProperty> = ({ wallet, balance, className, ...props }) => {
  return (
    <Input
      className={cls(styles.input, className)}
      placeholder="Enter amount"
      isNumeric
      startAdornment={
        <Grid marginRight={1}>
          <Typography variant="body1" textColor="color-grey-9">
            X
          </Typography>
        </Grid>
      }
      endAdornment={
        <EndAdornment>
          <div className={styles.adornment}>
            <Typography variant="caption" textColor="light-grey">
              Wallet {wallet}
            </Typography>
            <Typography variant="caption">Balance: {balance}</Typography>
          </div>
        </EndAdornment>
      }
      {...props}
    />
  )
}

export { StepInput }
