import { useEffect } from 'react'

import { Grid } from '@mui/material'

import { BuyTab } from '@/components/buy-tab'
import { useModal } from '@/pages/modal-page/modal-page'

const BuyModal = () => {
  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: 'Buy' })
  }, [])

  return (
    <Grid>
      <BuyTab usedInModal />
    </Grid>
  )
}

export { BuyModal }
