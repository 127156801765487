import { FC, useState } from 'react'

import cls from 'classnames'

import { setSetup } from '@/api/user'
import { ButtonGroupRadio, ButtonGroupRadioButton } from '@/libs/common/button-group-radio'
import { ButtonIcon } from '@/libs/common/button-icon'
import { Icon } from '@/libs/common/icon'
import { Input, TInputProps } from '@/libs/common/input/input'
import { Typography } from '@/libs/common/typography'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProperty = TInputProps & {
  defaultOptions: (number | string)[]
  radioGroupName: string
  name: string
  onOptionSelect: (newValue: number | string) => void
}

const InputWithBuyAmount: FC<TProperty> = ({
  defaultOptions,
  disabled,
  radioGroupName,
  onOptionSelect,
  className,
  value,
  name,
  ...props
}) => {
  const [options, setOptions] = useState(defaultOptions)
  const [customOptions, setCustomOptions] = useState(defaultOptions)
  const [isCustomizeMode, setIsCustomizeMode] = useState(false)
  const userData = useAppSelector((state) => state.user.userData)

  const handleSubmitSetting = () => {
    if (!validateGroup()) return
    setOptions(customOptions)

    const keys = ['pro_buy_one', 'pro_buy_two', 'pro_buy_three', 'pro_buy_four']

    const result = keys.reduce((acc, key, index) => {
      acc[key] = customOptions[index]
      return acc
    }, {})
    setSetup(userData.user_id, { ...result, blockchain: 1 })
    //localStorage.setItem(name, JSON.stringify(customOptions))
    setIsCustomizeMode(false)
  }

  const validateGroup = () => {
    let isValidValue = true
    customOptions.forEach((el) => {
      if (el === '' || el === '0') isValidValue = false
    })
    const isUnique = new Set(customOptions).size === customOptions.length
    return isUnique && isValidValue
  }

  return (
    <div className={styles.container}>
      <div className={styles.controlsWrapper}>
        {isCustomizeMode ? (
          <div className={styles.controls}>
            <ButtonIcon icon={IconName.CHECK_SMALL} type="button" onClick={handleSubmitSetting} />
            <ButtonIcon
              icon={IconName.CLOSE_MIDDLE}
              type="button"
              onClick={() => {
                setCustomOptions(options)
                setIsCustomizeMode(false)
              }}
            />
          </div>
        ) : (
          <ButtonIcon
            className={styles.control}
            icon={IconName.SETTING_2}
            type="button"
            onClick={() => setIsCustomizeMode(true)}
          />
        )}
      </div>
      <Input
        isNumeric
        className={cls(styles.input, className)}
        value={value}
        disabled={disabled}
        name={name}
        {...props}
      />

      <ButtonGroupRadio
        exclusive
        value={value}
        onChange={(_, value) => {
          onOptionSelect(value)
        }}
      >
        {options?.map((el, index) => {
          return isCustomizeMode ? (
            <div>
              <Input
                isNumeric
                placeholder=""
                value={customOptions[index]}
                className={cls(styles.radioButton, styles.radioBtnInput, {
                  [styles.firstInput]: index === 0,
                  [styles.lastInput]: index === options.length - 1,
                })}
                onChange={(e) => {
                  const newValue = e.target.value
                  setCustomOptions((state) => {
                    const updatedState = [...state]
                    updatedState[index] = newValue
                    return updatedState
                  })
                }}
              />
            </div>
          ) : (
            <ButtonGroupRadioButton
              value={el}
              name="wallet"
              className={cls(styles.radioButton, {
                [styles.firstInput]: index === 0,
                [styles.lastInput]: index === options.length - 1,
              })}
              key={index}
            >
              <Icon name={IconName.ETH_CURRENCY} />
              <Typography variant="body2" textColor="light-grey">
                {+el < 0.0001 || +el >= 1000000 ? formatNumber(el).formatted : el}
              </Typography>
            </ButtonGroupRadioButton>
          )
        })}
      </ButtonGroupRadio>
    </div>
  )
}

export { InputWithBuyAmount }
