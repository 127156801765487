import { ComponentProps, FC } from 'react'

import cn from 'classnames'

import text from '@/assets/img/logo/logo-text.svg'
import logo from '@/assets/img/logo/logo.svg'

import styles from './styles.module.scss'

type TProperty = {
  size: 'big' | 'medium'
  hideText?: boolean
}

const Logo: FC<ComponentProps<'div'> & TProperty> = ({ className, size, hideText, ...props }) => {
  return (
    <div className={cn(styles.logoContainer, styles[size], className)} {...props}>
      <img src={logo} alt="Logo" className={styles.logo} />
      {!hideText && <img src={text} alt="Logo text" className={styles.text} />}
    </div>
  )
}

export { Logo }
