import { FC } from 'react'

import Stack from '@mui/material/Stack'
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup'
import cn from 'classnames'

import { Typography } from '@/libs/common/typography/index'

import stylesSeparated from './styles-separated.module.scss'
import stylesSolid from './styles-solid.module.scss'
import styles from './styles.module.scss'

type TButtonGroupRadioProps = ToggleButtonGroupProps & {
  label?: string | React.ReactNode
  separated?: boolean
  groupClassName?: string
  solid?: boolean
}

const ButtonGroupRadio: FC<TButtonGroupRadioProps> = ({
  children,
  onChange,
  label,
  className,
  groupClassName,
  solid,
  separated = false,
  ...props
}) => {
  const onChangeDecorator = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string | number | null,
  ) => {
    if (value !== null) {
      if (onChange) onChange(event, value)
    }
  }

  return (
    <Stack useFlexGap gap={1} className={cn(styles.container, className)}>
      {label && (
        <div>
          {typeof label === 'string' ? (
            <Typography variant="body1" textColor="light-grey" className={styles.label}>
              {label}
            </Typography>
          ) : (
            label
          )}
        </div>
      )}
      <ToggleButtonGroup
        classes={solid ? stylesSolid : separated ? stylesSeparated : styles}
        className={groupClassName}
        onChange={onChangeDecorator}
        {...props}
      >
        {children}
      </ToggleButtonGroup>
    </Stack>
  )
}

export { ButtonGroupRadio }
