const enum LocalStorageItem {
  TOKEN_ADDRESS = 'initTokenAddress',
  HOLDINGS_SETTINGS = 'holdingsSettings',
  LITE_BUY_AMOUNT = 'liteBuyAmount',
  PRIORITIES = 'priorities',
  MODE = 'mode',
  PANEL_TAB_INDEX = 'panelTabIndex',
}

export { LocalStorageItem }
