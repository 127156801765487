import { TypeOptions } from 'react-toastify'

import { Icon } from '@/libs/common/icon'
import { IconName } from '@/libs/enums'

const getToastIcon = (toastType: TypeOptions) => {
  switch (toastType) {
    case 'info':
      return <Icon name={IconName.TOAST_INFO} />
    case 'error':
      return <Icon name={IconName.TOAST_ERROR} />
    case 'success':
      return <Icon name={IconName.TOAST_SUCCESS} />
    case 'warning':
      return <Icon name={IconName.TOAST_WARNING} />
    default:
      return
  }
}

export { getToastIcon }
