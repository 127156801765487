import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { SelectWallet } from '@/components/select-wallet'
import { BackButton, Button, HiddenInputs, Input, Tabs, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { IconName, ManualSettingsSection } from '@/libs/enums'

import styles from './styles.module.scss'

const defaultValues = {
  privateTransaction: false,
  degenChadMode: true,
  frontrunBuys: false,
  backrunBuys: true,
  frontrunBuyPriority: '',
  frontrunSells: false,
  frontrunSellPriority: '',
  copySells: false,
  slippage: 0,
  selectedWallets: [],
  buyPriority: '',
  approvePriority: '',
  maxTxOrFail: true,
  transferOnBlacklist: false,
  antiBlacklist: true,
  antiRug: false,
  antiRugThreshold: 0,
  sellRugExtraTip: '12',
  maxBuyTax: '',
  maxSellTax: '',
  minLiquidity: '',
  maxLiquidity: '',
  maxMarketcap: '',
  autoSell: false,
  sellPriority: '',
  walletsToSellOnProfit: [],
  walletsToSellOnLoss: [],
  stopLoss: '',
  stopLossAmount: '',
  firstStep: '25',
  secondStep: '32',
  thirdStep: '40',
  amount: '',
  firstBundleOrFail: '',
  firstBundleBuy: '',
  backupBundle: '',
  backupBundleBuy: '',
  mempoolBackupBuy: '',
  minPercentForFail: '',
  selectedWalletsForProfit: [],
  selectedWalletsForLoss: [],
  sellOnProfit: '',
  sellOnProfitAmount: '',
  walletsToTrailingSell: [],
  trailingSell: '',
}

// const ProfitSettingsTabs: TSelectOption<string>[] = [
//   { value: 'sellInSteps', label: 'Sell in steps' },
//   { value: 'sellOnProfit', label: 'Sell on profit' },
//   { value: 'trailingSell', label: 'Trailing sell' },
// ]

const CopyTradeTemplate = () => {
  const [mainTab, setMainTab] = useState(0)
  // const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)
  // const [isAutoSellSettings, setIsAutoSellSetting] = useState(false)
  // const [selectedWallets, setSelectedWallets] = useState<number[]>([])
  const [frontrunBuys, setFrontrunBuys] = useState(defaultValues.frontrunBuys)
  // const [frontrunSells, setFrontrunSells] = useState(defaultValues.frontrunSells)
  const [backrunBuys, setBackrunBuys] = useState(defaultValues.backrunBuys)

  const { handleSubmit, control, setValue } = useForm({ defaultValues })

  const isLastMainTab = mainTab === Object.values(ManualSettingsSection).length - 1

  // const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
  //   setProfitTab(newValue)
  // }

  const handleChangeMainTab = () => {
    setMainTab((value) => (value < Object.values(ManualSettingsSection).length ? value + 1 : value))
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }

  return (
    <div>
      <BackButton className={styles.backButton} />

      <Typography variant="h1" align="center">
        Add new copy trade template
      </Typography>

      <Grid className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowGap={3}>
            <Grid container>
              <Input
                label="Template Name"
                placeholder="Enter template name"
                className={styles.input}
                maxLength={15}
              />

              <Tabs
                tabControl
                value={mainTab}
                tabs={[
                  {
                    label: ManualSettingsSection.BUY,
                    content: (
                      <Grid container rowGap={2}>
                        <Controller
                          name="amount"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              className={cn(styles.input, styles.inputSize)}
                              label="Amount"
                              placeholder="Enter gwei amount"
                              isNumeric
                              endAdornment={<EndAdornment label="ETH" icon={IconName.ETHEREUM} />}
                              {...field}
                            />
                          )}
                        />

                        <Grid container columnGap={3} flexWrap="nowrap">
                          <Controller
                            name="privateTransaction"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <SwitchInline
                                label="Anti-Mev"
                                tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="degenChadMode"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <SwitchInline
                                label="Degen Chad Mode"
                                tooltipInfo="For advanced traders. If enabled, the safety rules from the simulation will be ignored."
                                {...field}
                              />
                            )}
                          />
                        </Grid>

                        <Grid container columnGap={3} flexWrap="nowrap">
                          <Controller
                            name="frontrunBuys"
                            control={control}
                            render={({ field: { ref, onChange, ...field } }) => (
                              <SwitchInline
                                label="Frontrun Buys"
                                tooltipInfo="Will try to buy faster than other wallet. If it detects transaction initiated by other wallet, it will spend more gas fee (gwei) to prioritize your transaction in network."
                                onChange={(
                                  _: React.ChangeEvent<HTMLInputElement>,
                                  checked: boolean,
                                ) => {
                                  if (checked && backrunBuys) {
                                    setValue('backrunBuys', false)
                                    setBackrunBuys(false)
                                  }
                                  setFrontrunBuys(checked)
                                  onChange(checked)
                                }}
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            name="backrunBuys"
                            control={control}
                            render={({ field: { ref, onChange, ...field } }) => (
                              <SwitchInline
                                label="Backrun Buys"
                                tooltipInfo="This will make sure that the tracked transaction is prioritized over the your backrun transaction."
                                onChange={(
                                  _: React.ChangeEvent<HTMLInputElement>,
                                  checked: boolean,
                                ) => {
                                  if (checked && frontrunBuys) {
                                    setValue('frontrunBuys', false)
                                    setFrontrunBuys(false)
                                  }
                                  setBackrunBuys(checked)
                                  onChange(checked)
                                }}
                                {...field}
                              />
                            )}
                          />
                        </Grid>

                        {frontrunBuys && (
                          <Controller
                            name="frontrunBuyPriority"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                className={styles.input}
                                label="Frontrun Priority"
                                placeholder="Enter gwei amount"
                                isNumeric
                                tooltipInfo="It will add this much priority gwei to the dev transaction gwei, if developer does 80 and you enter 5 here it will do 85."
                                endAdornment={
                                  <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                                }
                                {...field}
                              />
                            )}
                          />
                        )}

                        <Controller
                          name="slippage"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <PercentsInput
                              label="Slippage"
                              onOptionSelect={(value) => field.onChange(value)}
                              tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                              placeholder="X"
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="selectedWallets"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <SelectWallet
                              isMulti
                              wallet={value}
                              {...field}
                              tooltipInfo="tooltipInfo"
                            />
                          )}
                        />

                        <Grid container display="flex" flexWrap="nowrap" gap={3}>
                          <Controller
                            name="buyPriority"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                className={styles.input}
                                label="Buy Priority"
                                placeholder="Enter gwei amount"
                                isNumeric
                                tooltipInfo="An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ETH."
                                onAutoChange={() => {
                                  //TO DO: added auto switch
                                }}
                                endAdornment={
                                  <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                                }
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            name="approvePriority"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                className={cn(styles.input, styles.inputSize)}
                                label="Approve priority"
                                placeholder="Enter gwei amount"
                                isNumeric
                                tooltipInfo="An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ETH."
                                endAdornment={
                                  <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                                }
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    ),
                  },
                  {
                    label: ManualSettingsSection.SAFETY,
                    content: (
                      <Grid container rowGap={2} flexDirection="column">
                        <Grid container columnGap={3} flexWrap="nowrap">
                          <Controller
                            name="transferOnBlacklist"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <SwitchInline
                                label="Transfer On Blacklist"
                                tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="antiBlacklist"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <SwitchInline
                                label="Anti-Blacklist"
                                tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                                {...field}
                              />
                            )}
                          />
                        </Grid>

                        <HiddenInputs
                          expanded={isAntiRugSettings}
                          trigger={
                            <Controller
                              name="antiRug"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Anti-Rug"
                                  tooltipInfo="tooltipInfo"
                                  onChange={(
                                    _: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean,
                                  ) => {
                                    setIsAntiRugSettings(checked)
                                    onChange(checked)
                                  }}
                                  {...field}
                                />
                              )}
                            />
                          }
                        >
                          <Grid container rowGap={2}>
                            <Controller
                              name="antiRugThreshold"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <PercentsInput
                                  label="Anti-Rug Threshold"
                                  onOptionSelect={(value) => field.onChange(value)}
                                  tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="sellRugExtraTip"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder="Amount"
                                  isNumeric
                                  label="Sell Rug Extra Tip"
                                  tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </HiddenInputs>

                        <Grid container flexWrap="nowrap" columnGap={3}>
                          <Controller
                            name="maxBuyTax"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter percentage"
                                isNumeric
                                label="Max Buy Tax"
                                adornmentText="%"
                                tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="maxSellTax"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter percentage"
                                isNumeric
                                label="Max Sell Tax"
                                adornmentText="%"
                                tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid container flexWrap="nowrap" columnGap={3}>
                          <Controller
                            name="minLiquidity"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter min liquidity amount"
                                isNumeric
                                label="Min Liquidity"
                                adornmentText="$"
                                tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="maxLiquidity"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder="Enter max liquidity amount"
                                isNumeric
                                label="Max Liquidity"
                                adornmentText="$"
                                tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Controller
                          name="maxMarketcap"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Enter max marketcap"
                              isNumeric
                              label="Max Marketcap"
                              adornmentText="$"
                              tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    ),
                  },
                  // {
                  //   label: ManualSettingsSection.SELL,
                  //   content: (
                  //     <Grid gap={3} container flexDirection="column">
                  //       <Grid gap={2} container flexDirection="column">
                  //         <Controller
                  //           name="copySells"
                  //           control={control}
                  //           render={({ field: { ref, ...field } }) => (
                  //             <SwitchInline
                  //               label="Copy sells"
                  //               tooltipInfo="Enable to copy sales transactions of a tracked wallet."
                  //               {...field}
                  //             />
                  //           )}
                  //         />

                  //         <Controller
                  //           name="frontrunSells"
                  //           control={control}
                  //           render={({ field: { ref, onChange, ...field } }) => (
                  //             <SwitchInline
                  //               label="Frontrun Sells"
                  //               tooltipInfo="Used to sell faster than the tracked wallet. When the bot notices that the tracked wallet is trying to sell a token, it will launch a transaction with a higher priority to beat it."
                  //               onChange={(
                  //                 _: React.ChangeEvent<HTMLInputElement>,
                  //                 checked: boolean,
                  //               ) => {
                  //                 setFrontrunSells(checked)
                  //                 onChange(checked)
                  //               }}
                  //               {...field}
                  //             />
                  //           )}
                  //         />
                  //         {frontrunSells && (
                  //           <Controller
                  //             name="frontrunSellPriority"
                  //             control={control}
                  //             render={({ field: { ref, ...field } }) => (
                  //               <OptionalInput
                  //                 className={styles.input}
                  //                 label="Frontrun Priority"
                  //                 placeholder="Enter gwei amount"
                  //                 isNumeric
                  //                 tooltipInfo="It will add this much priority gwei to the dev transaction gwei, if developer does 80 and you enter 5 here it will do 85."
                  //                 endAdornment={
                  //                   <EndAdornment label="GWEI" icon={IconName.ETHEREUM} />
                  //                 }
                  //                 {...field}
                  //               />
                  //             )}
                  //           />
                  //         )}
                  //       </Grid>

                  //       <HiddenInputs
                  //         expanded={isAutoSellSettings}
                  //         trigger={
                  //           <Controller
                  //             name="autoSell"
                  //             control={control}
                  //             render={({ field: { ref, onChange, ...field } }) => (
                  //               <SwitchInline
                  //                 label="Auto Sell"
                  //                 tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                  //                 {...field}
                  //                 onChange={(
                  //                   _: React.ChangeEvent<HTMLInputElement>,
                  //                   checked: boolean,
                  //                 ) => {
                  //                   setIsAutoSellSetting(checked)
                  //                   onChange(checked)
                  //                 }}
                  //               />
                  //             )}
                  //           />
                  //         }
                  //       >
                  //         <Grid container rowGap={2} flexDirection="column">
                  //           <div className={styles.dividerContainer}>
                  //             <Typography variant="body2" textColor="grey">
                  //               Profit
                  //             </Typography>

                  //             <div className={styles.divider}></div>
                  //           </div>

                  //           <Controller
                  //             name="walletsToSellOnProfit"
                  //             control={control}
                  //             render={({ field: { ref, value, ...field } }) => {
                  //               setSelectedWallets(value)

                  //               return (
                  //                 <SelectWallet
                  //                   isMulti
                  //                   tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                  //                   wallet={value}
                  //                   {...field}
                  //                   label="Wallets to sell on Profit"
                  //                 />
                  //               )
                  //             }}
                  //           />

                  //           <ButtonGroupRadio
                  //             onChange={handleProfitTabChange}
                  //             value={profitTab}
                  //             exclusive
                  //             className={styles.group}
                  //           >
                  //             {ProfitSettingsTabs.map((tab) => (
                  //               <ButtonGroupRadioButton
                  //                 value={tab.value}
                  //                 key={tab.value}
                  //                 className={cn({ [styles.bgGrey]: tab.value === profitTab })}
                  //               >
                  //                 {tab.label}
                  //               </ButtonGroupRadioButton>
                  //             ))}
                  //           </ButtonGroupRadio>

                  //           {profitTab === ProfitSettingsTabs[0].value &&
                  //             selectedWallets.length > 0 && (
                  //               <div>
                  //                 <div className={styles.labelContent}>
                  //                   <InputLabel className={styles.label}>
                  //                     <Typography variant="body2" textColor="light-grey">
                  //                       Sell in steps
                  //                     </Typography>
                  //                   </InputLabel>
                  //                   <TooltipIcon info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%." marginLeft={5} />
                  //                 </div>
                  //                 <div className={styles.stepWrapper}>
                  //                   <Controller
                  //                     name="firstStep"
                  //                     control={control}
                  //                     render={({ field: { ref, ...field } }) => (
                  //                       <StepInput
                  //                         wallet="#1"
                  //                         balance="12.58 ETH"
                  //                         className={styles.firstInput}
                  //                         {...field}
                  //                       />
                  //                     )}
                  //                   />
                  //                   <Controller
                  //                     name="secondStep"
                  //                     control={control}
                  //                     render={({ field: { ref, ...field } }) => (
                  //                       <StepInput wallet="#2" balance="12.58 ETH" {...field} />
                  //                     )}
                  //                   />
                  //                   <Controller
                  //                     name="thirdStep"
                  //                     control={control}
                  //                     render={({ field: { ref, ...field } }) => (
                  //                       <StepInput
                  //                         wallet="#3"
                  //                         balance="12.58 ETH"
                  //                         className={styles.lastInput}
                  //                         {...field}
                  //                       />
                  //                     )}
                  //                   />
                  //                 </div>
                  //               </div>
                  //             )}

                  //           {profitTab === 'sellInSteps' && !selectedWallets.length && (
                  //             <Typography variant="body1" className={styles.walletsDescr}>
                  //               No wallets selected
                  //             </Typography>
                  //           )}

                  //           {profitTab === 'sellOnProfit' && (
                  //             <Grid container flexWrap="nowrap" columnGap={3}>
                  //               <Controller
                  //                 name="sellOnProfit"
                  //                 control={control}
                  //                 render={({ field: { ref, ...field } }) => (
                  //                   <OptionalInput
                  //                     placeholder="Enter sell on profit"
                  //                     isNumeric
                  //                     label="Sell on Profit"
                  //                     adornmentText="%"
                  //                     tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                  //                     {...field}
                  //                   />
                  //                 )}
                  //               />

                  //               <Controller
                  //                 name="sellOnProfitAmount"
                  //                 control={control}
                  //                 render={({ field: { ref, ...field } }) => (
                  //                   <OptionalInput
                  //                     placeholder="Enter sell profit amount"
                  //                     isNumeric
                  //                     label="Sell Profit Amount"
                  //                     adornmentText="%"
                  //                     tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                  //                     {...field}
                  //                   />
                  //                 )}
                  //               />
                  //             </Grid>
                  //           )}

                  //           {profitTab === 'trailingSell' && (
                  //             <>
                  //               <Controller
                  //                 name="walletsToTrailingSell"
                  //                 control={control}
                  //                 render={({ field: { ref, value, ...field } }) => (
                  //                   <SelectWallet
                  //                     isMulti
                  //                     wallet={value}
                  //                     label="Wallets to trailing sell"
                  //                     tooltipInfo="tooltipInfo"
                  //                     {...field}
                  //                   />
                  //                 )}
                  //               />
                  //               <Controller
                  //                 name="trailingSell"
                  //                 control={control}
                  //                 render={({ field: { ref, ...field } }) => (
                  //                   <OptionalInput
                  //                     placeholder="Enter trailing sell"
                  //                     isNumeric
                  //                     label="Trailing Sell"
                  //                     adornmentText="%"
                  //                     tooltipInfo="tooltipInfo"
                  //                     {...field}
                  //                   />
                  //                 )}
                  //               />
                  //             </>
                  //           )}

                  //           {profitTab === ProfitSettingsTabs[0].value && (
                  //             <>
                  //               <div className={styles.dividerContainer}>
                  //                 <Typography variant="body2" textColor="grey">
                  //                   Loss
                  //                 </Typography>

                  //                 <div className={styles.divider}></div>
                  //               </div>
                  //               <Controller
                  //                 name="walletsToSellOnLoss"
                  //                 control={control}
                  //                 render={({ field: { ref, value, ...field } }) => (
                  //                   <SelectWallet
                  //                     label="Wallets to sell on Loss"
                  //                     tooltipInfo="tooltipInfo"
                  //                     isMulti
                  //                     wallet={value}
                  //                     {...field}
                  //                   />
                  //                 )}
                  //               />

                  //               <Grid container flexWrap="nowrap" columnGap={3}>
                  //                 <Controller
                  //                   name="stopLoss"
                  //                   control={control}
                  //                   render={({ field: { ref, ...field } }) => (
                  //                     <OptionalInput
                  //                       placeholder="Enter amount"
                  //                       label="Stop Loss"
                  //                       tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                  //                       adornmentText="%"
                  //                       {...field}
                  //                     />
                  //                   )}
                  //                 />
                  //                 <Controller
                  //                   name="stopLossAmount"
                  //                   control={control}
                  //                   render={({ field: { ref, ...field } }) => (
                  //                     <OptionalInput
                  //                       placeholder="Enter amount"
                  //                       label="Stop Loss Amount"
                  //                       tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                  //                       adornmentText="%"
                  //                       {...field}
                  //                     />
                  //                   )}
                  //                 />
                  //               </Grid>
                  //             </>
                  //           )}
                  //         </Grid>
                  //       </HiddenInputs>
                  //     </Grid>
                  //   ),
                  // },
                ]}
              />
            </Grid>
            <Grid container columnGap={3} flexWrap="nowrap">
              <Button styleVariant="black" onClick={handleChangeMainTab} disabled={isLastMainTab}>
                Continue
              </Button>
              <Button disabled={!isLastMainTab}>Add</Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  )
}

export { CopyTradeTemplate }
