import { FC, useState } from 'react'

import cn from 'classnames'

import { WalletHoldingsTable } from '@/components/wallet-holdings-table'
import { useHoldingsWithSettings } from '@/hooks/useHoldingsWithSettings.hook'
import { Checkbox, Typography } from '@/libs/common'
import { ButtonSort } from '@/libs/common/button-sort'
import { TUserWallet } from '@/libs/types/user.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  wallet?: TUserWallet
}

const HoldingsTab: FC<TProps> = ({ wallet }) => {
  const honeypotHoldings = useAppSelector((state) => state.user.userHoldingsWebsocket?.s)

  const [isValid, setIsValid] = useState(true)

  const { filteredHoldings, holdingsSettings, handleHideSmallBalances, toggleSort } =
    useHoldingsWithSettings()

  const currentHoldings = isValid ? filteredHoldings?.t : honeypotHoldings

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <div className={styles.header}>
          <Typography
            className={cn(styles.titleTab, { [styles.activeTab]: isValid })}
            onClick={(e) => {
              e.stopPropagation()
              setIsValid(true)
            }}
          >
            Holdings
          </Typography>
          <span className={styles.titleTab}>/</span>
          <Typography
            className={cn(styles.titleTab, { [styles.activeTab]: !isValid })}
            onClick={(e) => {
              e.stopPropagation()
              setIsValid(false)
            }}
          >
            Honeypot
          </Typography>
        </div>
        {/* <div className={styles.header}>
          <Typography variant="body1" className={styles.title}>
            Wallet Holdings
          </Typography>
          <ButtonLink
            to={`${AppRoute.DASHBOARD}/${AppRoute.MODAL}/${AppRoute.HIDDEN_HOLDINGS}/${wallet?.public_key}`}
          >
            <Typography variant="body2" className={styles.link}>
              View hidden
            </Typography>
          </ButtonLink>
          <ButtonSort sorting={holdingsSettings.sorting} handleSorting={toggleSort} />
        </div> */}
        {isValid && (
          <div className={styles.checkboxes}>
            <ButtonSort sorting={holdingsSettings.sorting} handleSorting={toggleSort} />
            <Checkbox
              label="Hide Small Balances"
              value={holdingsSettings.hideSmallBalances}
              checked={holdingsSettings.hideSmallBalances}
              onChange={(_, value) => handleHideSmallBalances(value)}
            />
            {/* <Checkbox label="Hide Honeypot Tokens" /> */}
          </div>
        )}
      </div>
      {!!wallet && currentHoldings?.length ? (
        <div className={styles.tableContainer}>
          <WalletHoldingsTable
            wallet={wallet}
            filteredHoldings={currentHoldings}
            isHoneypot={!isValid}
          />
        </div>
      ) : (
        <Typography textAlign="center" marginBottom={2}>
          No holdings
        </Typography>
      )}
    </div>
  )
}

export { HoldingsTab }
