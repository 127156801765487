import { Dispatch, FC, SetStateAction, useState } from 'react'

import MUITableCell from '@mui/material/TableCell'
import MUITableRow, { TableRowProps as MUITableRowProps } from '@mui/material/TableRow'
import cn from 'classnames'

import { Icon } from '@/libs/common/icon/index'
import { TOpenState } from '@/libs/common/table/index'
import tableStyles from '@/libs/common/table/styles.module.scss'
import { IconName } from '@/libs/enums'
import { TableBodyRow } from '@/libs/types/table.type'

type TBodyRowProps = MUITableRowProps & {
  rowKey?: string
  row: TableBodyRow
  rowsCollapsed?: TableBodyRow[]
  isCollapsible?: boolean
  open?: boolean
  setOpen?: Dispatch<SetStateAction<TOpenState>>
}

const BodyRow: FC<TBodyRowProps> = ({
  className,
  row,
  rowKey,
  rowsCollapsed,
  isCollapsible,
  open,
  setOpen,
  ...props
}) => {
  const isLastRow = (index: number) => rowsCollapsed && index === rowsCollapsed.length - 1

  const [ownOpen, setOwnOpen] = useState(false)

  return (
    <>
      <MUITableRow
        key={'bodyrow-row' + rowKey}
        className={cn(
          tableStyles.bodyRow,
          { [tableStyles.bodyRowCollapsed]: rowsCollapsed },
          className,
        )}
        onClick={() => {
          setOpen && rowKey && open !== undefined
            ? setOpen({ key: rowKey, open: !open })
            : setOwnOpen(!ownOpen)
        }}
        {...props}
      >
        {isCollapsible && (
          <MUITableCell key={'bodyrow-cell-icon'} className={tableStyles.cell}>
            {rowsCollapsed && (
              <div className={cn({ [tableStyles.shrinkIcon]: open })}>
                <Icon name={IconName.EXPAND_MORE} />
              </div>
            )}
          </MUITableCell>
        )}

        {row.map((cellItem, index) => (
          <MUITableCell key={'bodyrow-cell' + index} className={tableStyles.cell}>
            {cellItem}
          </MUITableCell>
        ))}
      </MUITableRow>

      {isCollapsible && (open || ownOpen) && (
        <>
          {rowsCollapsed?.map((row, rowIndex) => (
            <MUITableRow
              key={'collapsed-row' + rowIndex + rowKey}
              className={tableStyles.bodyRowUnderCollapsing}
            >
              {isCollapsible && (
                <MUITableCell
                  className={cn(tableStyles.cellUnderCollapsing, {
                    [tableStyles.cellUnderCollapsingLast]: isLastRow(rowIndex),
                  })}
                ></MUITableCell>
              )}
              {row.map((cellItem, index) => (
                <MUITableCell
                  key={'collapsed-row-cell' + index}
                  className={cn(tableStyles.cellUnderCollapsing, {
                    [tableStyles.cellUnderCollapsingLast]: isLastRow(rowIndex),
                  })}
                >
                  {cellItem}
                </MUITableCell>
              ))}
            </MUITableRow>
          ))}
        </>
      )}
    </>
  )
}

export { BodyRow }
