export function getStartTimeInterval(timestamp: number, timeframe: string) {
  const date = new Date(timestamp)

  switch (timeframe) {
    case '3D': {
      date.setUTCHours(0, 0, 0, 0)
      const dayOfWeek = date.getUTCDay()
      const daysToSubtract = (dayOfWeek + 6) % 3
      date.setUTCDate(date.getUTCDate() - daysToSubtract)
      break
    }
    case '7D': {
      date.setUTCHours(0, 0, 0, 0)
      const dayOfWeek = date.getUTCDay()
      const daysToSubtract = (dayOfWeek + 6) % 7
      date.setUTCDate(date.getUTCDate() - daysToSubtract)
      break
    }
    case '1D':
      date.setUTCHours(0, 0, 0, 0)
      break
    case '240': {
      date.setUTCHours(Math.floor(date.getUTCHours() / 4) * 4, 0, 0, 0)
      break
    }
    case '720': {
      date.setUTCHours(Math.floor(date.getUTCHours() / 12) * 12, 0, 0, 0)
      break
    }
    case '5': {
      date.setUTCMinutes(Math.floor(date.getUTCMinutes() / 5) * 5, 0, 0)
      break
    }
    case '1': {
      date.setUTCMinutes(date.getUTCMinutes(), 0, 0)
      break
    }
    case '60': {
      date.setUTCHours(date.getUTCHours(), 0, 0, 0)
      break
    }
    case '15': {
      date.setUTCMinutes(Math.floor(date.getUTCMinutes() / 15) * 15, 0, 0)
      break
    }
    case '15s':
      date.setUTCSeconds(Math.floor(date.getUTCSeconds() / 15) * 15, 0)
      break
    default:
      return date.getTime()
  }

  return date.getTime()
}
