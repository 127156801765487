import { useEffect } from 'react'

import { selectedTokenData } from '@/components/selected-token-info/selectedTokenData'
import { Spinner } from '@/components/spinner'
import { Typography } from '@/libs/common'
import { TokenDataItem } from '@/libs/common/token-data-item'
import { SpinnerSize } from '@/libs/enums'
import {
  checkSimulationValue,
  formatNumber,
  formatPairSymbol,
  getClogColor,
  getPercentage,
  getTaxesColor,
} from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const TokenInfo = () => {
  const selectedToken = useAppSelector((state) => state.chain.currentToken)
  const simulation = useAppSelector((state) => state.chain.currentTokenSimulationWebsocket)
  const currentToken = useAppSelector((state) => state.chain.currentToken)

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: !currentToken || !simulation ? '' : `${simulation?.t.n} Info` })
  }, [currentToken, simulation])

  return !simulation && !!currentToken ? (
    <div className={styles.spinnerWrapper}>
      <Spinner size={SpinnerSize.MEDIUM} centered />
    </div>
  ) : simulation && selectedToken ? (
    <div className={styles.wrapper}>
      <div className={styles.tokenDataContainer}>
        <TokenDataItem title="Price" value={<>${formatNumber(simulation?.p.ou).formatted}</>} />

        <TokenDataItem
          title="Liquidity"
          tooltipInfo="The volume of ETH in Liquidity Pool. Other services may show doubled volume as they include tokenized half of LP."
          value={
            simulation && (
              <Typography variant="body2" className={styles.defaultColor}>
                <div>${formatTokenPrice(simulation.l.p.vu).formatted}</div>
                <div className={styles.additionalInfo}>
                  {checkSimulationValue(simulation.l.p.r) ? (
                    <>
                      {formatTokenPrice(simulation.l.p.r).formatted}{' '}
                      {formatPairSymbol(simulation.l.p.a, selectedToken.pair)}
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </Typography>
            )
          }
        />

        {/* <TokenDataItem
          title="Token Locked"
          tooltipInfo="The duration of the token's liquidity pool is locked or unlocked."
          value={
            <Typography
              variant="body2"
              className={cls(
                simulation?.additional_data?.is_pair_locked.status
                  ? styles.greenText
                  : styles.grayText,
              )}
            >
              {simulation?.additional_data?.is_pair_locked.status ? 'Yes' : 'No'}{' '}
              <span>
                {!!simulation?.additional_data?.is_pair_locked.status &&
                  simulation?.additional_data?.is_pair_locked.unlock_date &&
                  `(${countDays(simulation?.additional_data?.is_pair_locked.unlock_date)}d)`}
              </span>
              <div>
                <div className={styles.additionalInfo}>
                  {simulation?.additional_data?.is_pair_locked.locked_percentage}
                </div>
              </div>
            </Typography>
          }
        /> */}

        <TokenDataItem
          title="Renounced"
          tooltipInfo="When a smart contract is renounced, the developer can't call functions on it. It's safe when it's renounced."
          value={simulation?.t.r ? 'Yes' : 'No'}
          className={simulation?.t.r ? styles.greenText : styles.redText}
        />

        <TokenDataItem
          title="Owner Supply"
          tooltipInfo="The percentage of tokens held by the token creator."
          value={`${simulation?.t.ow}%`}
        />

        <TokenDataItem
          title="Contract Verified"
          tooltipInfo="If yes, it means that the source code of the contract is published and can be analyzed."
          value={simulation.t.t ? 'Yes' : 'No'}
        />
        <TokenDataItem
          title="Trade Open"
          tooltipInfo="The token is tradeable. Sometimes, token trading may be disabled until the developer calls the appropriate function."
          value={simulation?.t.t ? 'Yes' : 'No'}
          className={simulation?.t.t ? styles.greenText : styles.redText}
        />

        <TokenDataItem
          title="Marketcap"
          value={`$${formatTokenPrice(simulation?.t.mc).raw}`}
          isGreen
        />

        <TokenDataItem
          title="Honeypot"
          tooltipInfo="If the token is a Honeypot, purchased tokens will not be able to be sold."
          value={simulation?.t.h ? 'Yes' : 'No'}
          className={!simulation?.t.h ? styles.greenText : styles.redText}
        />

        <TokenDataItem
          title="Clog"
          tooltipInfo="How many tokens are stored in the smart contract waiting to be sold. The best value is 0%."
          value={`${simulation?.t.c}%`}
          className={styles[getClogColor(+(simulation?.t.c || '0'))]}
        />

        <TokenDataItem
          title="Burn LP"
          tooltipInfo="Indicates how much of the liquidity pool has been burned. The developer has no control over the burned pool. The best value is 100%."
          value={simulation?.l.b === '-' ? simulation?.l.b : `${simulation?.l.b}%`}
          className={simulation?.l.b && +simulation?.l.b > 50 ? styles.greenText : ''}
        />

        {/* <TokenDataItem
          title="Top 10 holders %"
          tooltipInfo="Cumulative percentage of total supply held by the 10 largest holders. The smaller the better."
          value={simulation.additional_data?.holders.top10Holders || '-'}
        /> */}

        <TokenDataItem
          title="Has Blacklist"
          tooltipInfo="Indicates whether the token developer can block holder wallets. Once blacklisted, tokens in those wallets cannot be sold."
          value={selectedTokenData.hasBlacklist}
        />

        <TokenDataItem
          title="Gain"
          tooltipInfo="How much the token has grown from the first candle to the current moment."
          value={`${simulation?.t.g || 0}x`}
          isGreen
        />

        <TokenDataItem
          title="Tax Buy / Sell / Transfer"
          tooltipInfo="The amount of tax set by the developer. Means how many tokens from your transaction will be transferred to the developer when buying / selling / transferring."
          value={
            simulation && (
              <>
                <span className={styles[getTaxesColor(simulation.tx.b)]}>
                  {getPercentage(simulation.tx.b)}
                </span>{' '}
                /{' '}
                <span className={styles[getTaxesColor(simulation.tx.s)]}>
                  {getPercentage(simulation.tx.s)}
                </span>{' '}
                /{' '}
                <span className={styles[getTaxesColor(simulation.tx.t)]}>
                  {getPercentage(simulation.tx.t)}
                </span>
              </>
            )
          }
        />

        <TokenDataItem
          title="Max Buy / Wallet"
          tooltipInfo="How many tokens can be purchased per transaction / how many tokens can be held per wallet."
          value={
            simulation && (
              <Typography variant="body2" className={styles.defaultColor}>
                <div>
                  <div>{`${getPercentage(simulation.lt.b.p)} /
                    ${getPercentage(simulation.lt.w.p)}`}</div>
                  <div>{+simulation.lt.b.p / +simulation.lt.w.p}</div>
                </div>
                <div className={styles.additionalInfo}>
                  {checkSimulationValue(simulation.lt.b.ccc)
                    ? formatTokenPrice(simulation.lt.b.ccc).formatted
                    : '-'}{' '}
                  /{' '}
                  {checkSimulationValue(simulation.lt.w.ccc)
                    ? formatTokenPrice(simulation.lt.w.ccc).formatted
                    : '-'}
                </div>
              </Typography>
            )
          }
        />

        {/*<TokenDataItem*/}
        {/*  title="Vol 24h"*/}
        {/*  tooltipInfo="Trading volume (buy volume + sale volume) for the previous 24 hours."*/}
        {/*  value={<>${formatNumber(+(simulation?.additional_data?.volume || '0')).formatted}</>}*/}
        {/*/>*/}

        <TokenDataItem
          title="Holder Count"
          tooltipInfo="How many unique wallets hold the token."
          value={simulation?.t.ho || 0}
        />

        <TokenDataItem
          title="Total Supply"
          tooltipInfo="The total number of tokens that have been issued."
          value={
            <>
              {formatNumber(simulation?.t.ts).formatted} {simulation.t.s}
            </>
          }
        />
      </div>
    </div>
  ) : (
    <div>
      <Typography variant="body1" align="center">
        Current token simulation is not available
      </Typography>
      <Typography variant="body1" align="center">
        Please, try it later
      </Typography>
    </div>
  )
}

export { TokenInfo }
