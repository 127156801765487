import { FC } from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { useAppSelector } from '@/store'

import { Row } from './libs/row/row'
import styles from './styles.module.scss'

const FavoritesTable: FC = () => {
  const favorites = useAppSelector((state) => state.favoritesTokens.userFavorites)

  return (
    <Paper className={styles.root}>
      <TableContainer className={cn(styles.container, styles.containerSmall)} id="holdings-table">
        <Table stickyHeader aria-label="sticky table" classes={styles}>
          <TableHead>
            <TableRow className={styles.headRow}>
              <TableCell className={styles.tableHeadCell}></TableCell>
              <TableCell className={styles.tableHeadCell}>Price USD</TableCell>
              <TableCell className={styles.tableHeadCell}>Volume</TableCell>
              <TableCell className={cn(styles.tableHeadCell, styles.changesColumn)}>24H</TableCell>
              <TableCell className={styles.tableHeadCell}>Liquidity</TableCell>
              <TableCell className={styles.tableHeadCell}>Mcap</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {favorites && favorites.map((row) => <Row row={row} key={row.t} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export { FavoritesTable }
