import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { ComingSoonOverlay } from '@/components/coming-soon-overlay'
import { ListButton } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { chainsConfig } from '@/libs/configs/chains.config'
import { IconName } from '@/libs/enums'
import { TChainConfig } from '@/libs/types/chain.type'
import { useAppDispatch } from '@/store'
import { setCurrentChain } from '@/store/slices/chain.slice'

import styles from './styles.module.scss'

type TProperty = { test?: string }

const SwitchChain: FC<TProperty> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onChange = (newChain: TChainConfig) => {
    dispatch(setCurrentChain(newChain))
    navigate(-1)
  }

  return (
    <div className={styles.switchChainContainer}>
      <ComingSoonOverlay />
      <div className={styles.titleWrapper}>
        <Typography variant="h1" className={styles.title}>
          Switch Chain
        </Typography>
      </div>

      <div className={styles.chainsList}>
        {chainsConfig.map((chain) => (
          <ListButton
            iconName={(IconName as any)[chain.iconName]}
            title={`${chain.label} Chain`}
            description={chain.description}
            onClick={() => {
              onChange(chain)
            }}
            key={chain.iconName}
          />
        ))}
      </div>
    </div>
  )
}

export { SwitchChain }
