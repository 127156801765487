import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'

import { reset2FA } from '@/api/user'
import { UserFormItem } from '@/components/user-settings/libs/components'
import { ChangeMasterPassword } from '@/components/user-settings/libs/components/user-form-item/change-master-password.tsx'
import { Typography } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription.ts'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserAuthenticatorData } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const Security: FC = () => {
  const userData = useAppSelector((state) => state.user.userData)

  const defaultValues = {
    master_password: '',
  }
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { control, getValues } = useForm({
    defaultValues,
  })

  const saveData = async (name: string) => {
    if (!userData) return
    try {
      const value = ((getValues() as any)[name] || '').trim()
      if (!value) return

      const { data } = await reset2FA({ master: value })
      if (data.data.s2fa) {
        dispatch(
          setUserAuthenticatorData({
            s2fa: data.data.s2fa,
          }),
        )
      }
      navigate(`${AppRoute.MODAL}/${AppRoute.ENABLE_2FA}`)
    } catch (err) {
      extractErrorDescription(err)
    }
  }

  return (
    <Grid container flexDirection="column" gap={3}>
      <Grid container flexDirection="column" gap={1.5}>
        <Typography variant="body2" textColor="white" className={styles.title}>
          General
        </Typography>

        <form className={styles.form}>
          <ChangeMasterPassword />

          <UserFormItem
            title="Change 2FA"
            control={control}
            name="master_password"
            btnText={'Change 2FA'}
            placeholder="Enter master  password"
            onSubmit={(value) => {
              saveData(value)
            }}
          />
        </form>
      </Grid>

      {/*<Grid container flexDirection="column" gap={1.5}>*/}
      {/*  <div>*/}
      {/*    <Typography variant="body2" textColor="white" className={styles.title}>*/}
      {/*      Active sessions*/}
      {/*    </Typography>*/}

      {/*    <Typography variant="body2" className={styles.description}>*/}
      {/*      These sessions are currently signed in to your account.{' '}*/}
      {/*      <ButtonLink className={styles.btn}>Sign out all other sessions</ButtonLink>*/}
      {/*    </Typography>*/}
      {/*  </div>*/}

      {/*  <ActiveSessionTable />*/}
      {/*</Grid>*/}

      {/*<Grid container flexDirection="column" gap={1.5}>*/}
      {/*  <div>*/}
      {/*    <Typography variant="body2" textColor="white" className={styles.title}>*/}
      {/*      Account Activity*/}
      {/*    </Typography>*/}

      {/*    <Typography variant="body2" className={styles.description}>*/}
      {/*      Recent activity on your account.*/}
      {/*    </Typography>*/}
      {/*  </div>*/}

      {/*  <AccountActivityTable />*/}
      {/*</Grid>*/}
    </Grid>
  )
}

export { Security }
