function formatDecimalString(value: number): string {
  const strValue = value.toString()
  const e = parseInt(strValue.split('e-')[1])

  if (e) {
    value *= Math.pow(10, e - 1)
    const formatted = '0.' + new Array(e).join('0') + value.toString().substring(2)
    return formatted
  }
  return strValue
}

export { formatDecimalString }
