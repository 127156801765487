import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { EBackendResponseStatus } from '@/libs/enums'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TSocketResponse } from '@/libs/types/backend-response.type'
import { TSniperHoldingsResponse } from '@/libs/types/sniper-holdings-socket-response.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserHoldingsWebsocket } from '@/store/slices/user.slice'

const holdingsSniperSocket = new SniperSockerService(import.meta.env.VITE_SNIPER_INDEXER_SOCKET_URL)

const useWatchHoldings = () => {
  const userData = useAppSelector((state) => state.user.userData)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const dispatch = useAppDispatch()

  useSniperWebsocket({
    socket: holdingsSniperSocket,
    connectionProps: {
      endpoint: `indexer/stream/${userData?.user_id}/holdings`,
      query: createQueryString({ l: 'en', b: currentChain.indexerChainId }),
    },
    verifyExistanceBeforeConnect: [!!userWallets],
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSocketResponse<TSniperHoldingsResponse | string>
      if (typeof data.data === 'string' || data.status !== EBackendResponseStatus.SUCCESS) {
        return
      }
      dispatch(setUserHoldingsWebsocket(data.data))
    },
  })
}

export { useWatchHoldings }
